const consignmentFormSchema = {
  type: 'object',
  required: ['siteId', 'supplierId'],
  properties: {
    shipmentNumber: { type: 'string', minLength: 1, maxLength: 255 },
    containerReference: { type: 'string', minLength: 6, maxLength: 255 },
    containerNumber: { type: 'string', minLength: 6, maxLength: 255 },
    containerSealNumber: { type: 'string', minLength: 6, maxLength: 255 },
  },
};

export { consignmentFormSchema };
