import { Form } from 'react-bootstrap';
import { isInvalid } from './helpers';

const HelpBlock = ({ meta, helpText }) => {
  if (helpText && !isInvalid(meta)) {
    return (
      <Form.Text className="d-inline-block" muted>
        {helpText}
      </Form.Text>
    );
  }
  return null;
};

export default HelpBlock;
