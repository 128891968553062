import {
  siteLocationQuery,
  siteLocationListQuery,
} from '../graphql/site_location_queries';
import cleanCache from './clean_cache';

export const siteLocationList = (client, listData) => {
  listData.map((siteLocation) => {
    const data = { siteLocation };
    return client.writeQuery({
      query: siteLocationQuery,
      variables: { id: siteLocation.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const siteLocationCreate = (
  cache,
  { data: { siteLocationCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: siteLocationListQuery });
  } catch {
    cacheData = { siteLocationList: [] };
  }
  cache.writeQuery({
    query: siteLocationListQuery,
    data: { siteLocationList: [...cacheData.siteLocationList, createData] },
  });
  const data = { siteLocation: createData };
  cache.writeQuery({
    query: siteLocationQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const siteLocationUpdate = (
  cache,
  { data: { siteLocationUpdate: updateData } }
) => {
  const data = { siteLocation: updateData };
  cache.writeQuery({
    query: siteLocationQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const siteLocationDelete = (
  cache,
  { data: { siteLocationDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: siteLocationListQuery });
  } catch {
    cacheData = { siteLocationList: [] };
  }
  const data = {
    siteLocationList: cacheData.siteLocationList.filter(
      (siteLocation) => deleteData.id !== siteLocation.id
    ),
  };
  cache.writeQuery({ query: siteLocationListQuery, data });
  cache.evict({ fieldName: 'siteLocation', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
