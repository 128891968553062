import { siteQuery, siteListQuery } from '../graphql/site_queries';
import cleanCache from './clean_cache';

export const siteList = (client, listData) => {
  listData.map((site) => {
    const data = { site };
    return client.writeQuery({
      query: siteQuery,
      variables: { id: site.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const siteCreate = (cache, { data: { siteCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: siteListQuery });
  } catch {
    cacheData = { siteList: [] };
  }
  cache.writeQuery({
    query: siteListQuery,
    data: { siteList: [...cacheData.siteList, createData] },
  });
  const data = { site: createData };
  cache.writeQuery({
    query: siteQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const siteUpdate = (cache, { data: { siteUpdate: updateData } }) => {
  const data = { site: updateData };
  cache.writeQuery({
    query: siteQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const siteDelete = (cache, { data: { siteDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: siteListQuery });
  } catch {
    cacheData = { siteList: [] };
  }
  const data = {
    siteList: cacheData.siteList.filter((site) => deleteData.id !== site.id),
  };
  cache.writeQuery({ query: siteListQuery, data });
  cache.evict({ fieldName: 'site', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
