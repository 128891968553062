import { useMutation } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';

import get from 'lodash.get';

import { renderDateString } from '../../components/render_helpers';
import ReactTable from '../../components/react_table/react_table';

import { consignmentItemDelete as consignmentItemDeleteMutation } from '../../graphql/consignment_item_queries';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import * as updateFunctions from '../../update_functions';

const ConsignmentItemList = (props) => {
  const { currentUser, consignmentItems, settingsTenant, settingsOnline, onDelete } =
    props;

  const dispatch = useDispatch();
  const [consignmentItemDelete] = useMutation(consignmentItemDeleteMutation);

  const consignmentItemDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const consignmentItemId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: consignmentItemId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentItemType',
        recordId: consignmentItemId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.consignmentItemDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'consignmentItemDelete',
      mutationData,
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await consignmentItemDelete(mutationData);
        toastSuccess('Consignment Item delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Consignment Item delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onDelete();
      }
    } else {
      consignmentItemDelete(mutationData);
      toastWarning(`Consignment Item delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onDelete();
    }
  };

  const sortIsoString = (rowA, rowB) => {
    const isoStringA = get(rowA, ['original', 'receipt', 'createdAt']);
    const isoStringB = get(rowB, ['original', 'receipt', 'createdAt']);
    if (!isoStringA || !isoStringB) {
      if (isoStringA && !isoStringB) {
        return 1;
      }
      if (!isoStringA && isoStringB) {
        return -1;
      }
      return 0;
    }
    // eslint-disable-next-line no-nested-ternary
    return isoStringA < isoStringB ? -1 : isoStringA > isoStringB ? 1 : 0;
  };

  const getDeliveredAt = (row) => {
    const { receipt } = row;
    const { createdAt: deliveredAt } = receipt || {};
    return renderDateString(deliveredAt);
  };

  const parentColumns = [
    {
      header: 'Item Ref',
      accessorKey: 'consignmentItemReference',
      enableColumnFilter: true,
      filterFn: 'includesString',
      sortingFn: 'alphanumeric',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Qty',
      accessorKey: 'quantity',
      enableColumnFilter: false,
    },
    {
      header: 'Kg',
      accessorKey: 'weightKg',
      enableColumnFilter: false,
    },
    {
      header: 'Delivered At',
      id: 'deliveredAt',
      enableColumnFilter: false,
      accessorFn: (row) => getDeliveredAt(row),
      sortingFn: sortIsoString,
    },
  ];

  return (
    <ReactTable
      rootName="consignmentShowConsignmentItem"
      parentColumns={parentColumns}
      data={consignmentItems}
      doEdit={false}
      doShow={false}
      doDelete={currentUser?.perms?.administrator}
      handleDelete={consignmentItemDeleteClicked}
    />
  );
};

export default ConsignmentItemList;
