export const picklistProductFormSchema = {
  type: 'object',
  required: ['picklistId', 'productId', 'quantity'],
  properties: {
    quantity: { type: 'number', exclusiveMinimum: 0 },
  },
};

export const picklistProductNestedFormSchema = {
  type: 'object',
  required: ['productId', 'quantity'],
  properties: {
    quantity: { type: 'number', exclusiveMinimum: 0 },
  },
};
