import { Alert, Button } from 'react-bootstrap';

const VersionOutdatedComponent = () => (
  <Alert key="offline-danger" variant="danger" className="mt-3 text-center">
    Your current software is outdated.
    <br />
    Click to reload
    <div className="mt-2 d-flex justify-content-center">
      <Button onClick={() => window.location.reload(true)} variant="primary">
        Reload
      </Button>
    </div>
  </Alert>
);

export default VersionOutdatedComponent;
