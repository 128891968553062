import { pileQuery, pileListQuery } from '../graphql/pile_queries';
import cleanCache from './clean_cache';

export const pileList = (client, listData) => {
  listData.map((pile) => {
    const data = { pile };
    return client.writeQuery({
      query: pileQuery,
      variables: { id: pile.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const pileCreate = (cache, { data: { pileCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: pileListQuery });
  } catch {
    cacheData = { pileList: [] };
  }
  if (!cacheData) {
    cacheData = { pileList: [] };
  }
  cache.writeQuery({
    query: pileListQuery,
    data: { pileList: [...cacheData.pileList, createData] },
  });
  const data = { pile: createData };
  cache.writeQuery({
    query: pileQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const pileUpdate = (cache, { data: { pileUpdate: updateData } }) => {
  const data = { pile: updateData };
  cache.writeQuery({
    query: pileQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const pileDelete = (cache, { data: { pileDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: pileListQuery });
  } catch {
    cacheData = { pileList: [] };
  }
  if (!cacheData) {
    cacheData = { pileList: [] };
  }
  const data = {
    pileList: cacheData.pileList.filter((pile) => deleteData.id !== pile.id),
  };
  cache.writeQuery({ query: pileListQuery, data });
  cache.evict({ fieldName: 'pile', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
