import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import get from 'lodash.get';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import {
  pileListPageQuery,
  pileDelete as pileDeleteMutation,
} from '../graphql/pile_queries';
import { settingsSet } from '../store/settings_slice';
import * as updateFunctions from '../update_functions';

const PileList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [pileDelete] = useMutation(pileDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(pileListPageQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ pileList }) => {
      // write an individual query for every pile in the list
      updateFunctions.pileList(pageClient, pileList);
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const pileDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const pileId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: pileId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'PileType',
        recordId: pileId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.pileDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'pileDelete',
      mutationData
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await pileDelete(mutationData);
        toastSuccess('Pile delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Pile delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      pileDelete(mutationData);
      toastWarning(`Pile delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const renderPortalLink = ({ row }) => {
    const naskuProjectRefOriginal = get(row, ['original', 'naskuProjectRef']);
    if (naskuProjectRefOriginal) {
      return (
        <a
          href={`https://service.nasku.net/project/${naskuProjectRefOriginal}`}
          target="_blank"
          rel="noreferrer"
        >
          Portal
        </a>
      );
    }
    return undefined;
  };

  const parentColumns = [
    {
      header: 'Name',
      accessorKey: 'name',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Nasku Ref',
      accessorKey: 'naskuRef',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Nasku Project Ref',
      accessorKey: 'naskuProjectRef',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Portal Link',
      id: 'portal',
      enableColumnFilter: false,
      cell: renderPortalLink,
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">Piles</h1>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
              <LinkContainer to="/piles/new">
                <Button variant="primary">New Pile</Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName="pile"
                parentColumns={parentColumns}
                data={pageData.pileList}
                doShow={false}
                doDelete={currentUser?.perms?.administrator}
                handleDelete={pileDeleteClicked}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PileList;
