export const goodsReceiptFormSchema = {
  type: 'object',
  required: ['name', 'siteLocationId'],
  properties: {
    name: { type: 'string', minLength: 6, maxLength: 255 },
    description: { type: 'string', minLength: 4, maxLength: 255 },
    receiptNotes: { type: 'string', minLength: 4, maxLength: 1000 },
  },
};

export const receiptFormSchema = {
  type: 'object',
  required: ['name', 'siteLocationId'],
  properties: {
    name: { type: 'string', minLength: 4, maxLength: 255 },
    description: { type: 'string', minLength: 4, maxLength: 255 },
    receiptNotes: { type: 'string', minLength: 4, maxLength: 1000 },
  },
};
