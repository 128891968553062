export const srackConsignmentUpdateSchema = {
  'Shipment N.': {
    prop: 'shipmentNumber',
    type: String,
    required: true,
  },
  'Container Type': {
    prop: 'containerReference',
    type: String,
    required: true,
  },
  'Packing Item Number': {
    prop: 'consignmentItemReference',
    type: String,
    required: true,
  },
  'Container No.': {
    prop: 'containerNumber',
    type: String,
    required: true,
  },
  'Container seal No.': {
    prop: 'containerSealNumber',
    type: String,
    required: true,
  },
  // Need to keep this in to force new line for shared consignmentItemRefs
  'Type of Packing Item': {
    prop: 'consignmentItemType',
    type: String,
    required: true,
  },
};
