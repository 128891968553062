const getTenantConfig = (tenant) => {
  const tenantConfig = {
    in: {
      tenantLongName: 'Infratec New Zealand Limited',
      tenantShortName: 'INFRATEC',
    },
  }[tenant];
  return (
    { tenant, ...tenantConfig } || { tenant: '', tenantLongName: '', tenantShortName: '' }
  );
};

export default getTenantConfig;
