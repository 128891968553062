export const releaseNotes = [
  {
    releasedOn: '2027-12-05',
    releaseNote: `\
Add visitor logs list
`,
  },
  {
    releasedOn: '2027-12-03',
    releaseNote: `\
Add filtering and table search to site map
Save current zoom state across reload
Add label to table at zoom 20
`,
  },
  {
    releasedOn: '2027-12-02',
    releaseNote: `\
Improve performance of site map
Improve creation of geojson feature collections
Add initial coloring of tables on map based on build state
Improve hover labelling on piles and tables
Add open street maps base layer (google rendering poorly)
Update table build status enums
`,
  },
  {
    releasedOn: '2027-12-01',
    releaseNote: `\
Add manage table
Add status and piles tabs to manage table popup
Add location map tab to manage table popup
`,
  },
  {
    releasedOn: '2027-11-30',
    releaseNote: `\
Add consignment item summary and exception reports
`,
  },
  {
    releasedOn: '2027-11-29',
    releaseNote: `\
Add pile summary report
Update menus
Move picklists to consignments menu
`,
  },
  {
    releasedOn: '2027-11-28',
    releaseNote: `\
Add table layer to map
Add tables importer
Add tables list and form
`,
  },
  {
    releasedOn: '2024-11-24',
    releaseNote: `\
Add piles by date by machine report
`,
  },
  {
    releasedOn: '2024-11-22',
    releaseNote: `\
Add piling machine and pile resources
Add piles importer
Add piles leaflet map
`,
  },
  {
    releasedOn: '2024-11-12',
    releaseNote: `\
Add wbscode management and importer
`,
  },
  {
    releasedOn: '2024-11-06',
    releaseNote: `\
Populate deliveredAt for consignment items based on their receipt record
Update consignment items and consignment list to use new table filter/sort/paginate
Improve natural sort of dropdown table entries
`,
  },
  {
    releasedOn: '2024-11-05',
    releaseNote: `\
Fix First Scanned sorting on receipts list
Add Last Seen column to receipts list
Add Man (manual) column to receipts list to allow filtering of receipts
Update supplier catalog item unit of measurement enums to include pallets
Record current user id when updating picked/unpicked/picked checked state changes on pick lists
Show unit of measurement on picklist show page (e.g. 400/bag)
Add this release notes modal
Add a 1 minute poll (auto refetch) to receipts list
`,
  },
];
