import {
  picklistProductQuery,
  picklistProductListQuery,
} from '../graphql/picklist_product_queries';
import cleanCache from './clean_cache';

export const picklistProductList = (client, listData) => {
  listData.map((picklistProduct) => {
    const data = { picklistProduct };
    return client.writeQuery({
      query: picklistProductQuery,
      variables: { id: picklistProduct.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const picklistProductCreate = (
  cache,
  { data: { picklistProductCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistProductListQuery });
  } catch {
    cacheData = { picklistProductList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistProductList: [] };
  }
  cache.writeQuery({
    query: picklistProductListQuery,
    data: {
      picklistProductList: [...cacheData.picklistProductList, createData],
    },
  });
  const data = { picklistProduct: createData };
  cache.writeQuery({
    query: picklistProductQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistProductUpdate = (
  cache,
  { data: { picklistProductUpdate: updateData } }
) => {
  const data = { picklistProduct: updateData };
  cache.writeQuery({
    query: picklistProductQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistProductDelete = (
  cache,
  { data: { picklistProductDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistProductListQuery });
  } catch {
    cacheData = { picklistProductList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistProductList: [] };
  }
  const data = {
    picklistProductList: cacheData.picklistProductList.filter(
      (picklistProduct) => deleteData.id !== picklistProduct.id
    ),
  };
  cache.writeQuery({ query: picklistProductListQuery, data });
  cache.evict({
    fieldName: 'picklistProduct',
    args: { id: deleteData.id.toString() },
  });
  cleanCache(cache);
};
