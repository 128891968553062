/* eslint-disable react/jsx-props-no-spreading, react/no-children-prop */
import { Form } from 'react-bootstrap';

import InputField from './input_field';
import { isInvalid } from './helpers';
import HelpBlock from './help_block';
import InvalidBlock from './invalid_block';
import LabelBlock from './label_block';

const defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 9,
  inlineLabel: true,
  className: '',
  controlOnly: false,
  innerContentOnly: false,
};

const CheckboxInputField = (props) => {
  const {
    input,
    meta,
    children,
    size,
    labelWidth,
    inputWidth,
    inlineLabel,
    helpText,
    className,
    controlOnly,
    innerContentOnly,
    inputStyle,
    disabled = false,
  } = { ...defaultProps, ...props };
  const invalid = isInvalid(meta);
  return (
    <InputField
      {...(!inlineLabel && { children })}
      size={size}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      controlOnly={controlOnly}
      innerContentOnly={innerContentOnly}
      innerContent={
        <>
          {labelWidth === 0 && children && (
            <LabelBlock size={size}>{children}</LabelBlock>
          )}
          <Form.Check
            {...((controlOnly || innerContentOnly) && { id: input.name })}
            {...(className && { className })}
            {...(inputStyle && { style: inputStyle })}
          >
            <Form.Check.Input
              type="checkbox"
              isInvalid={invalid}
              {...input}
              // checked={input.value}
              disabled={disabled}
            />
            {inlineLabel && children && (
              <Form.Check.Label className={`w-100 py-0 col-form-label-${size}`}>
                {children}
              </Form.Check.Label>
            )}
            <InvalidBlock meta={meta} helpText={helpText} />
            <HelpBlock meta={meta} helpText={helpText} />
          </Form.Check>
        </>
      }
    />
  );
};

export default CheckboxInputField;
