import { useMemo } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import Field from '../../components/form/rff_field';
import InputField from '../../components/form/input_field';
import Confirm from '../../components/confirm';

const defaultProps = {
  labelWidth: 4,
  inputWidth: 8,
  helpText: '',
  size: 'sm',
};

const PicklistProductFields = (props) => {
  const {
    picklistProduct,
    products,
    arrayName,
    arrayIndex,
    remove,
    labelWidth,
    inputWidth,
    size,
  } = { ...defaultProps, ...props };

  const { id: picklistProductId } = picklistProduct;

  const confirmBodyText = useMemo(
    () => (
      <>
        <p>
          Are you sure you want to delete this product assembly link from the picklist?
        </p>
        {picklistProductId && (
          <p>
            Note that while the product assembly link will be removed from the list, it
            will not be permanently deleted until you save this record.
          </p>
        )}
      </>
    ),
    [picklistProductId]
  );

  return (
    <Col className="mb-2" sm={{ span: inputWidth, offset: labelWidth }}>
      <Row>
        <Field
          name={`${arrayName}.productId`}
          component={InputField}
          asElement="select"
          selectOptions={products}
          defaultSelectOptionText="select product assembly link..."
          labelWidth={0}
          inputWidth={6}
          size={size}
        >
          Product Assembly
        </Field>
        <Field
          type="text"
          name={`${arrayName}.quantity`}
          labelWidth={0}
          inputWidth={2}
          size={size}
          component={InputField}
        >
          Quantity
        </Field>
        <Col sm={2}>
          <div style={{ paddingTop: '1.8rem' }}>
            <Confirm
              onConfirm={() => remove(arrayIndex)}
              title="Delete Product Assembly Link"
              body={confirmBodyText}
              confirmText="Confirm"
            >
              <Button size={size} type="button" variant="dark">
                Delete
              </Button>
            </Confirm>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default PicklistProductFields;
