import { purchaserQuery, purchaserListQuery } from '../graphql/purchaser_queries';
import cleanCache from './clean_cache';

export const purchaserList = (client, listData) => {
  listData.map((purchaser) => {
    const data = { purchaser };
    return client.writeQuery({
      query: purchaserQuery,
      variables: { id: purchaser.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const purchaserCreate = (cache, { data: { purchaserCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: purchaserListQuery });
  } catch {
    cacheData = { purchaserList: [] };
  }
  if (!cacheData) {
    cacheData = { purchaserList: [] };
  }
  cache.writeQuery({
    query: purchaserListQuery,
    data: { purchaserList: [...cacheData.purchaserList, createData] },
  });
  const data = { purchaser: createData };
  cache.writeQuery({
    query: purchaserQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const purchaserUpdate = (cache, { data: { purchaserUpdate: updateData } }) => {
  const data = { purchaser: updateData };
  cache.writeQuery({
    query: purchaserQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const purchaserDelete = (cache, { data: { purchaserDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: purchaserListQuery });
  } catch {
    cacheData = { purchaserList: [] };
  }
  if (!cacheData) {
    cacheData = { purchaserList: [] };
  }
  const data = {
    purchaserList: cacheData.purchaserList.filter(
      (purchaser) => deleteData.id !== purchaser.id
    ),
  };
  cache.writeQuery({ query: purchaserListQuery, data });
  cache.evict({ fieldName: 'purchaser', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
