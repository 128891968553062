import { Alert } from 'react-bootstrap';

const OfflineComponent = () => (
  <Alert key="offline-danger" variant="danger" className="mt-3 text-center">
    We cannot reach the server and might be missing data needed for this page. Please go
    online and retry
  </Alert>
);

export default OfflineComponent;
