import { Form } from 'react-bootstrap';

const defaultProps = {
  size: 'sm',
};

const LabelBlock = (props) => {
  const { size, children } = { ...defaultProps, ...props };
  return (
    <Form.Label className={`col-form-label col-form-label-${size}`}>
      {children}
    </Form.Label>
  );
};

export default LabelBlock;
