import { useMemo, useCallback, useRef, useState } from 'react';
import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';
import Webcam from 'react-webcam';
import { DateTime } from 'luxon';

import {
  XCircle as XCircleIcon,
  Camera as CameraIcon,
  Repeat as RepeatIcon,
  RefreshCw as RefreshCwIcon,
  Save as SaveIcon,
} from 'react-feather';

import cloneDeep from 'lodash.clonedeep';

import SaveExitIcon from './save_exit_icon';
// import SubmitButtonSpinner from '../../components/submit_button_spinner';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import {
  consignmentImageCreate as consignmentImageCreateMutation,
  consignmentImageUpdate as consignmentImageUpdateMutation,
} from '../../graphql/consignment_image_queries';
import * as updateFunctions from '../../update_functions';
import { coerceInput, pickValues, handleSubmitError } from '../../lib/utils';
import { consignmentImageDefaultValues } from '../../defaults';
import { consignmentImageWhiteList } from '../../white_lists';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraintsEnvironment = {
  facingMode: FACING_MODE_ENVIRONMENT,
  width: 1280,
  height: 720,
};

const videoConstraintsUser = {
  facingMode: FACING_MODE_USER,
};

function ConsignmentImageCaptureModal(props) {
  let closeOnSubmit = true;
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const webcamRef = useRef(null);
  const [videoConstraints, setVideoConstraints] = useState(videoConstraintsEnvironment);
  const [imageOriginalBase64, setImageOriginalBase64] = useState(null);
  const [imageDisplayBase64, setImageDisplayBase64] = useState(null);
  const [consignmentImageCreate] = useMutation(consignmentImageCreateMutation);
  const [consignmentImageUpdate] = useMutation(consignmentImageUpdateMutation);
  const {
    settingsTenant,
    settingsOnline,
    show,
    consignmentId,
    receiptId,
    consignmentItemId,
    consignmentImage,
    onCancel,
    onComplete,
  } = props;

  const initialValues = useMemo(() => {
    if (consignmentImage) {
      const values = pickValues(consignmentImage, consignmentImageWhiteList);
      return {
        ...values,
        ...(consignmentId && { consignmentId }),
        ...(consignmentItemId && { consignmentItemId }),
        ...(receiptId && { receiptId }),
      };
    }
    return {
      ...consignmentImageDefaultValues,
      ...(consignmentId && { consignmentId }),
      ...(consignmentItemId && { consignmentItemId }),
      ...(receiptId && { receiptId }),
    };
  }, [consignmentImage, consignmentId, consignmentItemId, receiptId]);

  const handleChangeCamera = useCallback(() => {
    setVideoConstraints((prevState) =>
      prevState.facingMode === FACING_MODE_USER
        ? videoConstraintsEnvironment
        : videoConstraintsUser
    );
  }, []);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const capture = useCallback(() => {
    /* eslint-disable prefer-destructuring */
    const videoHeight = webcamRef.current.video.videoHeight;
    const videoWidth = webcamRef.current.video.videoWidth;
    const clientWidth = divRef.current.clientWidth;
    const resizeRatio = clientWidth / videoWidth;
    const clientHeight = Math.round(videoHeight * resizeRatio);
    // console.log({ videoWidth, videoHeight, clientWidth, clientHeight, resizeRatio });

    const newImageOriginalBase64 = webcamRef.current.getScreenshot({
      width: videoWidth,
      height: videoHeight,
    });
    setImageOriginalBase64(newImageOriginalBase64);

    const newImageDisplayBase64 = webcamRef.current.getScreenshot({
      width: clientWidth,
      height: clientHeight,
    });
    setImageDisplayBase64(newImageDisplayBase64);
  }, [divRef, webcamRef]);

  const retake = () => {
    setImageOriginalBase64(null);
    setImageDisplayBase64(null);
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const fileName = `consignment-image-${DateTime.now().toUnixInteger()}.jpeg`;
    input.file = dataURLtoFile(imageOriginalBase64, fileName);
    const mutationData = {
      variables: { input },
      context: {
        hasUpload: true,
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentImageType',
        recordId: consignmentImage ? consignmentImage.id : uuid,
      },
    };
    if (consignmentImage?.id) {
      mutation = consignmentImageUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = consignmentImage.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.consignmentImageUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'consignmentImageUpdate',
        mutationData,
      });
    } else {
      mutation = consignmentImageCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.consignmentImageCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'consignmentImageCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        // toastSuccess(`Consignment Image ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        retake();
        if (closeOnSubmit) {
          onComplete();
        }
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Consignment Image ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      retake();
      if (closeOnSubmit) {
        onComplete();
      }
    }
    return undefined;
  };

  return (
    <Modal
      id={`consignment-${consignmentId}-consignment-image-form`}
      show={show}
      onHide={() => {
        retake();
        onCancel();
      }}
      fullscreen
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Body
              style={{
                height: 'calc(100vh - 76px)', // footer
              }}
            >
              {imageDisplayBase64 && <img src={imageDisplayBase64} alt="webcam" />}
              <div
                ref={divRef}
                style={{
                  height: '100%',
                  display: imageDisplayBase64 ? 'none' : 'inherit',
                }}
              >
                <Webcam
                  style={{
                    // height: 'calc(100vh - 96px)',
                    // width: 'auto',
                    width: 'calc(100vw - 32px)',
                    // objectFit: 'fill',
                    // objectFit: 'cover', // use "cover" to avoid distortion
                    // position: 'absolute',
                  }}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={0.92}
                  forceScreenshotSourceSize
                  videoConstraints={videoConstraints}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-between w-100">
                <ButtonGroup size="lg" className="me-1">
                  <Button
                    variant="danger"
                    onClick={() => {
                      retake();
                      onCancel();
                    }}
                    disabled={submitting}
                  >
                    <XCircleIcon />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleChangeCamera}
                    disabled={submitting}
                  >
                    <RefreshCwIcon />
                  </Button>
                </ButtonGroup>
                <ButtonGroup size="lg" className="me-1">
                  <Button
                    type="button"
                    variant="primary"
                    disabled={submitting || !imageDisplayBase64}
                    onClick={(e) => {
                      closeOnSubmit = true;
                      handleSubmit(e);
                    }}
                  >
                    <SaveExitIcon />
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={submitting || !imageDisplayBase64}
                    onClick={(e) => {
                      closeOnSubmit = false;
                      handleSubmit(e);
                    }}
                  >
                    <SaveIcon />
                  </Button>
                </ButtonGroup>
                <ButtonGroup size="lg">
                  {imageDisplayBase64 ? (
                    <Button variant="danger" onClick={retake}>
                      <RepeatIcon />
                    </Button>
                  ) : (
                    <Button variant="danger" onClick={capture}>
                      <CameraIcon />
                    </Button>
                  )}
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default ConsignmentImageCaptureModal;
