export const canadianSolarSchema = {
  // 'Shipment Number': {
  //   prop: 'shipmentNumber',
  //   type: String,
  //   required: true,
  // },
  'Pallet Group ID': {
    prop: 'containerReference',
    type: String,
    required: true,
  },
  'Carton No.': {
    prop: 'consignmentItemReference',
    type: String,
    required: true,
  },
  'Pallet No.': {
    prop: 'consignmentItemType',
    type: () => 'Pallet',
    required: true,
  },
  'Serial No.': {
    prop: 'consignmentItemMemberReference',
    type: String,
    required: true,
  },
  Connector: {
    prop: 'consignmentItemNumber',
    type: String,
    required: true,
  },
  'CEC Module Naming Convention': {
    prop: 'description',
    type: String,
    required: true,
  },
  // // SUM UP WHEN GROUPED
  // QTY: {
  //   prop: 'quantity',
  //   type: Number,
  //   required: true,
  // },
  // // DON'T HAVE
  // Weight: {
  //   prop: 'weightKg',
  //   type: Number,
  //   required: true,
  // },
  'Watt Labeling': {
    prop: 'siteLocationName',
    type: (value) => {
      if (value === 700) {
        return '700LD00';
      }
      if (value === 705) {
        return '705LD00';
      }
      return 'MAINLD00';
    },
    required: true,
  },
};
