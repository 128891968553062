import localForage from 'localforage';

const dateSuffix = '20241105';
const idDateSuffix = '20240906';

const reduxPersistPrefix = `${window.$CLIENT_TENANT}:rp`;
export const localStorageReduxPersistKey = `${reduxPersistPrefix}:${dateSuffix}`;
// export const localStorageFinalFormPersistPrefix = `${window.$CLIENT_TENANT}:ff`;

const apolloPrefix = `${window.$CLIENT_TENANT}:ap`;
const apolloCachePrefix = `${apolloPrefix}:apollo-cache-persist`;

export const apolloSchemaVersionKey = `${apolloPrefix}:apollo-schema-version`;
export const apolloTrackedMutationsKey = `${apolloPrefix}:apollo-tracked-mutations`;
export const apolloTrackedUuidsKey = `${apolloPrefix}:apollo-tracked-uuids`;
export const apolloCachePersistKey = `${apolloCachePrefix}:${dateSuffix}`;

const localStorageIdTokenPrefix = `${window.$CLIENT_TENANT}:id`;
const localStorageIdTokenKey = `${localStorageIdTokenPrefix}:${idDateSuffix}`;

export function setIdToken(idToken) {
  localStorage.setItem(localStorageIdTokenKey, idToken);
}

export function getIdToken() {
  return localStorage.getItem(localStorageIdTokenKey);
}

export function removeIdToken() {
  localStorage.removeItem(localStorageIdTokenKey);
}

export const clearLocalSettings = () => {
  localStorage.removeItem(localStorageReduxPersistKey);
};

export const clearLocalStorage = async () => {
  await localForage.removeItem(apolloCachePersistKey);
  localStorage.removeItem(apolloSchemaVersionKey);
  localStorage.removeItem(apolloTrackedMutationsKey);
  localStorage.removeItem(apolloTrackedUuidsKey);
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('persist')) {
      // old scoped key
      localStorage.removeItem(key);
    } else if (key === 'id_token') {
      // old scoped key
      localStorage.removeItem(key);
    } else if (key === 'apollo-schema-version') {
      // old scoped key
      localStorage.removeItem(key);
    } else if (
      key.startsWith(reduxPersistPrefix) &&
      key !== localStorageReduxPersistKey
    ) {
      // Outdated redux persist key
      localStorage.removeItem(key);
    } else if (
      key.startsWith(localStorageIdTokenPrefix) &&
      key !== localStorageIdTokenKey
    ) {
      // Outdated id key
      localStorage.removeItem(key);
    } else if (window.$NODE_ENV === 'development') {
      console.log(`RemainingLocalStorageKey: ${key}`);
    }
  });
  const localForageKeys = await localForage.keys();
  localForageKeys.forEach(async (key) => {
    if (key.startsWith('persist')) {
      // old scoped key
      await localForage.removeItem(key);
    } else if (key === 'apollo-cache-persist') {
      // old scoped key
      await localForage.removeItem(key);
    } else if (key.startsWith(apolloCachePrefix) && key !== apolloCachePersistKey) {
      // Outdated apollo persist key
      await localForage.removeItem(key);
    } else if (window.$NODE_ENV === 'development') {
      console.log(`RemainingLocalForageKey: ${key}`);
    }
  });
};
