import { useMemo } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useQuery, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import { picklistShowPageQuery } from '../graphql/picklist_queries';

// import DlHorizontal from '../components/dl_horizontal';
import PicklistSupplierCatalogItemList from './picklist_show/picklist_supplier_catalog_item_list';
// import ConsignmentImageFormModal from './consignment_show/consignment_image_form_modal';
// import ConsignmentImageCaptureModal from './consignment_show/consignment_image_capture_modal';

import { renderMultlilineText } from '../lib/utils';

const PicklistShow = () => {
  const params = useParams();
  const navigate = useNavigate();
  // const [showConsignmentImageFormModal, setShowConsignmentImageFormModal] =
  //   useState(false);
  // const [showConsignmentImageCaptureModal, setShowConsignmentImageCaptureModal] =
  //   useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(picklistShowPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { picklistId: params.id },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  // const handleConsignmentImageFormModalClick = () => {
  //   setShowConsignmentImageFormModal(true);
  // };

  // const handleConsignmentImageFormModalCancel = () => {
  //   setShowConsignmentImageFormModal(false);
  // };

  // const handleConsignmentImageFormModalComplete = () => {
  //   pageRefetch();
  //   setShowConsignmentImageFormModal(false);
  // };

  // const handleConsignmentImageCaptureModalClick = () => {
  //   setShowConsignmentImageCaptureModal(true);
  // };

  // const handleConsignmentImageCaptureModalCancel = () => {
  //   setShowConsignmentImageCaptureModal(false);
  // };

  // const handleConsignmentImageCaptureModalComplete = () => {
  //   pageRefetch();
  //   setShowConsignmentImageCaptureModal(false);
  // };

  const renderContent = () => {
    const {
      picklist: {
        // id: picklistId,
        // name: picklistName,
        // description,
        picklistNotes,
        // auditNotes,
        // scannedData,
        // name,
        // deliveryState,
        // deliveryStatus,
        // consignmentItem,
        fromSiteLocation: { name: fromSiteLocationName },
        toSiteLocation: { name: toSiteLocationName },
        picklistTemplate: { name: picklistTemplateName },
        picklistSupplierCatalogItems,
      },
    } = pageData;
    return (
      <>
        {/* <ConsignmentImageFormModal
          show={showConsignmentImageFormModal}
          consignmentId={consignmentId}
          consignmentItemId={consignmentItemId}
          picklistName={picklistName}
          picklistId={picklistId}
          onCancel={handleConsignmentImageFormModalCancel}
          onComplete={handleConsignmentImageFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
        /> */}
        {/* <ConsignmentImageCaptureModal
          show={showConsignmentImageCaptureModal}
          consignmentId={consignmentId}
          consignmentItemId={consignmentItemId}
          picklistId={picklistId}
          onCancel={handleConsignmentImageCaptureModalCancel}
          onComplete={handleConsignmentImageCaptureModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
        /> */}
        <Row className="mt-4 mb-3">
          <Col sm={12}>
            <div className="float-none">
              <div className="float-start">
                <h1 className="h3 mb-3">{`Picklist Details for ${picklistTemplateName} from ${fromSiteLocationName} to ${toSiteLocationName}`}</h1>
              </div>
              <div className="float-end">
                <Button className="me-2" variant="primary" onClick={() => pageRefetch()}>
                  Refresh
                </Button>
                <Button className="me-2" variant="primary" onClick={() => navigate(-1)}>
                  Picklists
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <hr />
        </Row>
        <Row>
          {/* <Col sm={3}>
            <Card>
              <Card.Header>Details</Card.Header>
              <Card.Body>
                <DlHorizontal dt="ID" dd={picklistId} />
                <DlHorizontal dt="Pick From Location" dd={fromSiteLocationName} />
                <DlHorizontal dt="Pick To Location" dd={toSiteLocationName} />
              </Card.Body>
            </Card>
          </Col> */}
          <Col sm={12}>
            {/* <Row>
              <Col sm={12}>
                <Card>
                  <Card.Header>Picklist Actions</Card.Header>
                  <Card.Body>
                    <Button
                      className="me-2"
                      type="button"
                      variant="primary"
                      onClick={handleConsignmentImageFormModalClick}
                    >
                      Upload Image
                    </Button>
                    <Button
                      className="me-2"
                      type="button"
                      variant="primary"
                      onClick={handleConsignmentImageCaptureModalClick}
                    >
                      Capture Image
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row> */}
            <Row className="mt-0">
              <Col sm={12}>
                <Card>
                  <Card.Header>Items</Card.Header>
                  <Card.Body>
                    <PicklistSupplierCatalogItemList
                      picklistSupplierCatalogItems={picklistSupplierCatalogItems}
                      currentUser={currentUser}
                      settingsTenant={settingsTenant}
                      settingsOnline={settingsOnline}
                      onUpdate={() => pageRefetch()}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>Picklist Notes</Card.Header>
                  <Card.Body>{renderMultlilineText(picklistNotes)}</Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PicklistShow;
