import { cloneElement } from 'react';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthWrapper = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const authIsAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authIsAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  return authIsAuthenticated && !authIsAuthenticating ? (
    cloneElement(children, { navigate, location, params })
  ) : (
    <Navigate to="/auth/logout" />
  );
};

export default AuthWrapper;
