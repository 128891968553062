import { useMutation } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';

import ReactTable from '../../components/react_table/react_table';

import { productProductDelete as productProductDeleteMutation } from '../../graphql/product_product_queries';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import * as updateFunctions from '../../update_functions';

const ProductProductList = (props) => {
  const {
    currentUser,
    productProducts,
    settingsTenant,
    settingsOnline,
    onDelete,
    onEdit,
  } = props;

  const dispatch = useDispatch();
  const [productProductDelete] = useMutation(productProductDeleteMutation);

  const productProductEditClicked = (productProductId) => {
    onEdit(productProductId);
  };

  const productProductDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const productProductId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: productProductId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ProductProductType',
        recordId: productProductId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.productProductDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'productProductDelete',
      mutationData,
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await productProductDelete(mutationData);
        toastSuccess('Product Subassembly Link delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Product Subassembly Link delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onDelete();
      }
    } else {
      productProductDelete(mutationData);
      toastWarning(
        `Product Subassembly Link delete ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onDelete();
    }
  };

  // const getProductAssemblyName = (row) => {
  //   const {
  //     productAssembly: { name: productAssemblyName },
  //   } = row;
  //   return productAssemblyName;
  // };

  const getProductSubassemblyName = (row) => {
    const {
      productSubassembly: { name: productSubassemblyName },
    } = row;
    return productSubassemblyName;
  };

  const parentColumns = [
    // {
    //   header: 'Product Assembly',
    //   id: 'productAssembly',
    //   accessorFn: (row) => getProductAssemblyName(row),
    //   filterType: 'dropdown',
    // },
    {
      header: 'Product Subassembly',
      id: 'productSubassembly',
      accessorFn: (row) => getProductSubassemblyName(row),
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      enableColumnFilter: false,
    },
  ];

  return (
    <ReactTable
      rootName="productProduct"
      parentColumns={parentColumns}
      data={productProducts}
      doShow={false}
      handleEdit={productProductEditClicked}
      doDelete={currentUser?.perms?.administrator}
      handleDelete={productProductDeleteClicked}
    />
  );
};

export default ProductProductList;
