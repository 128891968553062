import {
  productCategoryQuery,
  productCategoryListQuery,
} from '../graphql/product_category_queries';
import cleanCache from './clean_cache';

export const productCategoryList = (client, listData) => {
  listData.map((productCategory) => {
    const data = { productCategory };
    return client.writeQuery({
      query: productCategoryQuery,
      variables: { id: productCategory.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const productCategoryCreate = (
  cache,
  { data: { productCategoryCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: productCategoryListQuery });
  } catch {
    cacheData = { productCategoryList: [] };
  }
  if (!cacheData) {
    cacheData = { productCategoryList: [] };
  }
  cache.writeQuery({
    query: productCategoryListQuery,
    data: { productCategoryList: [...cacheData.productCategoryList, createData] },
  });
  const data = { productCategory: createData };
  cache.writeQuery({
    query: productCategoryQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const productCategoryUpdate = (
  cache,
  { data: { productCategoryUpdate: updateData } }
) => {
  const data = { productCategory: updateData };
  cache.writeQuery({
    query: productCategoryQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const productCategoryDelete = (
  cache,
  { data: { productCategoryDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: productCategoryListQuery });
  } catch {
    cacheData = { productCategoryList: [] };
  }
  if (!cacheData) {
    cacheData = { productCategoryList: [] };
  }
  const data = {
    productCategoryList: cacheData.productCategoryList.filter(
      (productCategory) => deleteData.id !== productCategory.id
    ),
  };
  cache.writeQuery({ query: productCategoryListQuery, data });
  cache.evict({ fieldName: 'productCategory', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
