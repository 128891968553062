/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';

import InputField from './input_field';
import { isInvalid } from './helpers';
import HelpBlock from './help_block';
import InvalidBlock from './invalid_block';
import LabelBlock from './label_block';

moment.updateLocale('en-nz');

const defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 3,
  helpText: '',
  dateFormat: false,
  timeFormat: false,
  closeOnSelect: false,
  timeConstraints: null,
  initialViewMode: 'days',
  disabled: false,
};

const ReactDateTimeField = (props) => {
  const {
    input,
    meta,
    children,
    size,
    labelWidth,
    inputWidth,
    helpText,
    dateFormat,
    timeFormat,
    closeOnSelect,
    initialViewMode,
    timeConstraints,
    inputStyle,
    groupClassName,
    disabled,
  } = { ...defaultProps, ...props };

  const [fieldFormat, setFieldFormat] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const [fieldMeta, setFieldMeta] = useState({});

  useEffect(() => {
    const newFieldFormat = [dateFormat, timeFormat].filter((format) => format).join(' ');
    setFieldFormat(newFieldFormat);
  }, [dateFormat, timeFormat]);

  useEffect(() => {
    const newFieldValue =
      fieldFormat && input.value ? moment(input.value).format(fieldFormat) : '';
    // const newFieldValue =
    //   fieldFormat && input.value
    //     ? DateTime.fromISO(input.value).toFormat(fieldFormat)
    //     : '';
    setFieldValue(newFieldValue);
    if (!meta.valid) {
      setFieldMeta({
        touched: true,
        invalid: true,
        error: meta.error,
      });
    } else {
      setFieldMeta({});
    }
  }, [fieldFormat, input.value, meta.valid, meta.error]);

  const handleInputFieldClose = () => {
    if (input.onClose) {
      input.onClose();
    }
  };

  const handleInputFieldChange = (date) => {
    if (date === '') {
      input.onChange(date);
    } else if (moment.isMoment(date)) {
      setFieldMeta({});
      let newDate;
      if (timeFormat) {
        newDate = date.clone();
      } else {
        newDate = date.clone().startOf('day');
      }
      input.onChange(newDate.format());
    } else {
      setFieldMeta({
        touched: true,
        invalid: true,
        error: fieldFormat,
      });
      setFieldValue(date);
    }
  };

  const invalid = isInvalid(fieldMeta);

  return (
    <InputField
      size={size}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      groupClassName={groupClassName}
      innerContent={
        <>
          {labelWidth === 0 && children && (
            <LabelBlock size={size}>{children}</LabelBlock>
          )}
          <DateTime
            value={fieldValue}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            onChange={handleInputFieldChange}
            onClose={handleInputFieldClose}
            closeOnSelect={closeOnSelect}
            initialViewMode={initialViewMode}
            {...(timeConstraints && { timeConstraints })}
            inputProps={{
              className: classNames('form-control', `form-control-${size}`, {
                'is-invalid': invalid,
              }),
              disabled,
            }}
            {...(invalid && { className: 'is-invalid' })}
            {...(inputStyle && { style: inputStyle })}
          />
          <InvalidBlock meta={fieldMeta} helpText={helpText} force />
          <HelpBlock meta={fieldMeta} helpText={helpText} />
        </>
      }
    >
      {children}
    </InputField>
  );
};

export default ReactDateTimeField;
