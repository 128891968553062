import { useMemo, useState } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useQuery, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import { receiptShowPageQuery } from '../graphql/receipt_queries';

import DlHorizontal from '../components/dl_horizontal';
import ConsignmentImageList from './consignment_show/consignment_image_list';
import ConsignmentImageFormModal from './consignment_show/consignment_image_form_modal';
import ConsignmentImageCaptureModal from './consignment_show/consignment_image_capture_modal';

import { renderMultlilineText } from '../lib/utils';

const ReceiptShow = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showConsignmentImageFormModal, setShowConsignmentImageFormModal] =
    useState(false);
  const [showConsignmentImageCaptureModal, setShowConsignmentImageCaptureModal] =
    useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(receiptShowPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { receiptId: params.id },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const handleConsignmentImageFormModalClick = () => {
    setShowConsignmentImageFormModal(true);
  };

  const handleConsignmentImageFormModalCancel = () => {
    setShowConsignmentImageFormModal(false);
  };

  const handleConsignmentImageFormModalComplete = () => {
    pageRefetch();
    setShowConsignmentImageFormModal(false);
  };

  const handleConsignmentImageCaptureModalClick = () => {
    setShowConsignmentImageCaptureModal(true);
  };

  const handleConsignmentImageCaptureModalCancel = () => {
    setShowConsignmentImageCaptureModal(false);
  };

  const handleConsignmentImageCaptureModalComplete = () => {
    pageRefetch();
    setShowConsignmentImageCaptureModal(false);
  };

  const renderContent = () => {
    const {
      receipt: {
        id: receiptId,
        name: receiptName,
        description,
        receiptNotes,
        auditNotes,
        scannedData,
        name,
        deliveryState,
        deliveryStatus,
        consignmentItem,
        siteLocation: { name: siteLocationName },
        consignmentImages,
      },
    } = pageData;
    const {
      id: consignmentItemId,
      consignmentId,
      consignmentItemReference,
    } = consignmentItem || {};
    return (
      <>
        <ConsignmentImageFormModal
          show={showConsignmentImageFormModal}
          consignmentId={consignmentId}
          consignmentItemId={consignmentItemId}
          receiptName={receiptName}
          receiptId={receiptId}
          onCancel={handleConsignmentImageFormModalCancel}
          onComplete={handleConsignmentImageFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
        />
        <ConsignmentImageCaptureModal
          show={showConsignmentImageCaptureModal}
          consignmentId={consignmentId}
          consignmentItemId={consignmentItemId}
          receiptId={receiptId}
          onCancel={handleConsignmentImageCaptureModalCancel}
          onComplete={handleConsignmentImageCaptureModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
        />
        <Row className="mt-4 mb-3">
          <Col sm={12}>
            <div className="float-none">
              <div className="float-start">
                <h1 className="h3 mb-3">{`Receipt Details for ${receiptName}`}</h1>
              </div>
              <div className="float-end">
                <Button className="me-2" variant="primary" onClick={() => pageRefetch()}>
                  Refresh
                </Button>
                <Button className="me-2" variant="primary" onClick={() => navigate(-1)}>
                  Receipts
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <hr />
        </Row>
        <Row>
          <Col sm={4}>
            <Card>
              <Card.Header>Details</Card.Header>
              <Card.Body>
                <DlHorizontal dt="ID" dd={receiptId} />
                <DlHorizontal dt="Name" dd={name} />
                <DlHorizontal dt="Description" dd={description} />
                <DlHorizontal dt="Delivery State" dd={deliveryState} />
                <DlHorizontal dt="Delivery Status" dd={deliveryStatus} />
                <DlHorizontal dt="Site Location" dd={siteLocationName} />
                <DlHorizontal
                  dt="Consignment Item"
                  dd={consignmentItemReference || '-'}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={8}>
            <Row>
              <Col sm={12}>
                <Card>
                  <Card.Header>Receipt Actions</Card.Header>
                  <Card.Body>
                    <Button
                      className="me-2"
                      type="button"
                      variant="primary"
                      onClick={handleConsignmentImageFormModalClick}
                    >
                      Upload Image
                    </Button>
                    <Button
                      className="me-2"
                      type="button"
                      variant="primary"
                      onClick={handleConsignmentImageCaptureModalClick}
                    >
                      Capture Image
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>Images</Card.Header>
                  <Card.Body>
                    <ConsignmentImageList
                      consignmentImages={consignmentImages}
                      currentUser={currentUser}
                      settingsTenant={settingsTenant}
                      settingsOnline={settingsOnline}
                      onDelete={() => pageRefetch()}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>Scanned Data</Card.Header>
                  <Card.Body>{renderMultlilineText(scannedData)}</Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>Receipt Notes</Card.Header>
                  <Card.Body>{renderMultlilineText(receiptNotes)}</Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>Receipt Audit Trail</Card.Header>
                  <Card.Body>{renderMultlilineText(auditNotes)}</Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ReceiptShow;
