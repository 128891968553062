import { useState } from 'react';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { Scanner } from '@yudiel/react-qr-scanner';

function ScanReceiptModal(props) {
  const { show, onCancel, onScan } = props;
  const [paused, setPaused] = useState(false);

  return (
    <Modal id="goods-receipt-new-scan-form" show={show} onHide={onCancel}>
      <Modal.Body style={{ minHeight: 'calc(100vw)' }}>
        <Scanner
          allowMultiple
          onScan={onScan}
          paused={paused}
          formats={[
            'qr_code',
            'micro_qr_code',
            'rm_qr_code',
            'maxi_code',
            'pdf417',
            'aztec',
            'data_matrix',
            'matrix_codes',
            'dx_film_edge',
            'databar',
            'databar_expanded',
            'codabar',
            'code_39',
            'code_93',
            'code_128',
            'ean_8',
            'ean_13',
            'itf',
            'linear_codes',
            'upc_a',
            'upc_e',
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="secondary" onClick={() => setPaused(!paused)}>
            {paused ? 'Unpause Scanner' : 'Pause Scanner'}
          </Button>
          <Button variant="danger" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default ScanReceiptModal;
