import { inverterQuery, inverterListQuery } from '../graphql/inverter_queries';
import cleanCache from './clean_cache';

export const inverterList = (client, listData) => {
  listData.map((inverter) => {
    const data = { inverter };
    return client.writeQuery({
      query: inverterQuery,
      variables: { id: inverter.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const inverterCreate = (cache, { data: { inverterCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: inverterListQuery });
  } catch {
    cacheData = { inverterList: [] };
  }
  if (!cacheData) {
    cacheData = { inverterList: [] };
  }
  cache.writeQuery({
    query: inverterListQuery,
    data: { inverterList: [...cacheData.inverterList, createData] },
  });
  const data = { inverter: createData };
  cache.writeQuery({
    query: inverterQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const inverterUpdate = (cache, { data: { inverterUpdate: updateData } }) => {
  const data = { inverter: updateData };
  cache.writeQuery({
    query: inverterQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const inverterDelete = (cache, { data: { inverterDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: inverterListQuery });
  } catch {
    cacheData = { inverterList: [] };
  }
  if (!cacheData) {
    cacheData = { inverterList: [] };
  }
  const data = {
    inverterList: cacheData.inverterList.filter(
      (inverter) => deleteData.id !== inverter.id
    ),
  };
  cache.writeQuery({ query: inverterListQuery, data });
  cache.evict({ fieldName: 'inverter', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
