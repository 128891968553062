export const picklistSupplierCatalogItemFormSchema = {
  type: 'object',
  required: [
    'quantity',
    'picklistId',
    'supplierCatalogItemId',
    'modelPartNumber',
    'description',
    'catalogItemUnitOfMeasure',
    'catalogItemQuantityPerUnitOfMeasure',
  ],
  properties: {
    modelPartNumber: { type: 'string', minLength: 6, maxLength: 255 },
    description: { type: 'string', minLength: 6, maxLength: 255 },
    catalogItemQuantityPerUnitOfMeasure: { type: 'number', exclusiveMinimum: 0 },
    quantity: { type: 'number', exclusiveMinimum: 0 },
    quantityUnitOfMeasure: { type: 'number', exclusiveMinimum: 0 },
    quantityRemainder: { type: 'number', exclusiveMinimum: 0 },
    picklistItemNotes: { type: 'string', minLength: 6, maxLength: 1000 },
  },
};
