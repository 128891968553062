export const productProductIntegers = [];
export const productProductFloats = [];
export const productProductIsoStrings = [];
export const productProductWhiteList = [
  'id',
  'quantity',
  'productSubassemblyId',
  'productAssemblyId',
];
export const productProductOptimisticList = [
  ...productProductWhiteList,
  'productSubassembly',
  'productAssembly',
];
