import { useMemo } from 'react';
import { Col, Row, Tabs, Tab, Button, Table } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';

import get from 'lodash.get';
import defaultTo from 'lodash.defaultto';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { consignmentReportPageQuery } from '../graphql/report_queries';

const ConsignmentReport = () => {
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(consignmentReportPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const getConsignmentName = (row) => {
    const { consignmentId } = row;
    const consignments = get(pageData, 'consignmentList', []);
    const consignment = consignments.find((c) => c.id === consignmentId);
    const { name } = consignment || {};
    return name || '-';
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">Consignment Reports</h1>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="consignmentItemSummaryReport"
            id="reports"
            className="mb-3"
          >
            <Tab eventKey="consignmentItemSummaryReport" title="Consignment Item Summary">
              <Table style={{ width: '50%' }}>
                <tbody>
                  <tr>
                    <td>Total Consignment Items</td>
                    <td className="text-end">
                      {get(pageData, [
                        'consignmentItemSummaryReport',
                        'report',
                        'data',
                        'total',
                      ])}
                    </td>
                  </tr>
                  <tr>
                    <td>Unreceipted Consignment Items</td>
                    <td className="text-end">
                      {get(pageData, [
                        'consignmentItemSummaryReport',
                        'report',
                        'data',
                        'unreceipted',
                      ])}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <ReactTable
                rootName="consignmentItemSummaryReport"
                parentColumns={[
                  {
                    header: 'Supplier',
                    accessorKey: 'supplierName',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Total Items',
                    accessorKey: 'total',
                    enableColumnFilter: false,
                    headerClassName: 'text-end',
                    cellClassName: 'text-end',
                  },
                  {
                    header: 'Unreceipted Items',
                    accessorKey: 'unreceipted',
                    enableColumnFilter: false,
                    headerClassName: 'text-end',
                    cellClassName: 'text-end',
                  },
                ]}
                data={defaultTo(
                  get(
                    pageData,
                    ['consignmentItemSummaryReport', 'report', 'data', 'suppliers'],
                    []
                  ),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
            <Tab
              eventKey="consignmentItemsDuplicateReceiptsReport"
              title="Consignment Items with Multiple Receipts"
            >
              <ReactTable
                rootName="consignmentItemsDuplicateReceiptsReport"
                parentColumns={[
                  {
                    header: 'Id',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Reference',
                    accessorKey: 'consignmentItemReference',
                    enableColumnFilter: true,
                  },
                  {
                    header: 'Consignment',
                    accessorKey: 'consignmentId',
                    accessorFn: (row) => getConsignmentName(row),
                    filterType: 'dropdown',
                  },
                  {
                    header: 'Receipt Count',
                    accessorKey: 'receiptCount',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                ]}
                data={defaultTo(
                  get(
                    pageData,
                    ['consignmentItemsDuplicateReceiptsReport', 'report', 'data'],
                    []
                  ),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
            <Tab
              eventKey="consignmentItemsZeroReceiptsReport"
              title="Consignment Items with No Receipts"
            >
              <ReactTable
                rootName="consignmentItemsZeroReceiptsReport"
                parentColumns={[
                  {
                    header: 'Id',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Reference',
                    accessorKey: 'consignmentItemReference',
                    enableColumnFilter: true,
                  },
                  {
                    header: 'Consignment',
                    accessorKey: 'consignmentId',
                    accessorFn: (row) => getConsignmentName(row),
                    filterType: 'dropdown',
                  },
                ]}
                data={defaultTo(
                  get(
                    pageData,
                    ['consignmentItemsZeroReceiptsReport', 'report', 'data'],
                    []
                  ),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ConsignmentReport;
