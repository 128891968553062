import { useMemo, useState } from 'react';

import {
  Stack,
  Alert,
  ButtonGroup,
  Button,
  Col,
  Row,
  Card,
  Dropdown,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus, useLazyQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { DateTime } from 'luxon';

import cloneDeep from 'lodash.clonedeep';
import compact from 'lodash.compact';
import get from 'lodash.get';

import ConsignmentImageFormModal from './consignment_show/consignment_image_form_modal';
import ConsignmentImageCaptureModal from './consignment_show/consignment_image_capture_modal';
import ManualReceiptModal from './goods_receipt_show/manual_receipt_form_modal';
import ScanReceiptModal from './goods_receipt_show/scan_receipt_modal';
import ReceiptNotesModal from './goods_receipt_show/receipt_notes_form_modal';
import SiteLocationModal from './goods_receipt_show/site_location_form_modal';
import DlHorizontal from '../components/dl_horizontal';
import {
  renderOverlay,
  renderError,
  renderOffline,
  renderVersionOutdated,
} from '../components/render_helpers';
import {
  coerceInput,
  handleSubmitError,
  renderMultlilineText,
  pickValues,
} from '../lib/utils';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import receiptFragment from '../fragments/receipt_type_base_fragment';
import {
  goodsReceiptShowPageQuery,
  receiptByNameQuery,
  receiptCreate as receiptCreateMutation,
  receiptUpdate as receiptUpdateMutation,
} from '../graphql/receipt_queries';
import * as updateFunctions from '../update_functions';
import { receiptWhiteList } from '../white_lists';

const GoodsReceiptShow = () => {
  const defaultLaydownArea = 'MAINLD00';
  const dispatch = useDispatch();
  const [showManualReceiptModal, setShowManualReceiptModal] = useState(false);
  const [showScanReceiptModal, setShowScanReceiptModal] = useState(false);
  const [showReceiptNotesModal, setShowReceiptNotesModal] = useState(false);
  const [showSiteLocationModal, setShowSiteLocationModal] = useState(false);
  const [showConsignmentImageFormModal, setShowConsignmentImageFormModal] =
    useState(false);
  const [showConsignmentImageCaptureModal, setShowConsignmentImageCaptureModal] =
    useState(false);
  const [lastScanned, setLastScanned] = useState({});
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsVersionCurrent = useSelector((state) => state.settings.versionCurrent);

  const [receiptCreate] = useMutation(receiptCreateMutation);
  const [receiptUpdate] = useMutation(receiptUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(goodsReceiptShowPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const [receiptByName] = useLazyQuery(receiptByNameQuery);

  const makeAuditNote = (note, previousAuditNotes) =>
    compact([
      `${note} on ${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)} by ${currentUser.email}`,
      previousAuditNotes || lastScanned.auditNotes,
    ]).join('\n');

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const enums = useMemo(() => get(pageData, 'enums.enums'), [pageData]);

  const [deliveryStateDamaged, deliveryStateUndamaged] = useMemo(
    () => [get(enums, 'DeliveryStates.DAMAGED'), get(enums, 'DeliveryStates.UNDAMAGED')],
    [enums]
  );

  const [deliveryStatusAccepted, deliveryStatusRejected] = useMemo(
    () => [
      get(enums, 'DeliveryStatuses.ACCEPTED'),
      get(enums, 'DeliveryStatuses.REJECTED'),
    ],
    [enums]
  );

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const lastScannedPayload = {
      ...lastScanned,
      ...data,
      id: data.id || uuid,
    };
    const siteLocation = get(pageData, 'siteLocationList', []).find(
      (sl) => sl.id === parseInt(lastScannedPayload.siteLocationId, 10)
    );
    lastScannedPayload.laydownRef = siteLocation.name;
    if (lastScannedPayload.consignmentItemId) {
      const consignmentItem = get(pageData, 'consignmentItemList', []).find(
        (ci) => ci.id === parseInt(lastScannedPayload.consignmentItemId, 10)
      );
      lastScannedPayload.consignmentId = consignmentItem.consignmentId;
    } else {
      delete lastScannedPayload.consignmentId;
    }
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ReceiptType',
        recordId: data.id || uuid,
      },
    };
    if (data.id) {
      const {
        context: { recordType, recordId },
      } = mutationData;
      const currentData = pageClient.readFragment({
        id: `${recordType}:${recordId}`,
        fragment: receiptFragment,
        fragmentName: 'ReceiptTypeBase',
      });
      mutation = receiptUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = data.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.receiptUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'receiptUpdate',
        mutationData,
        currentData,
      });
    } else {
      mutation = receiptCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.receiptCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'receiptCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        const resp = await mutation(mutationData);
        const newId = get(resp, 'data.receiptCreate.id');
        if (newId) {
          lastScannedPayload.id = newId;
        }
        setLastScanned(lastScannedPayload);
        toastSuccess(`Receipt ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      setLastScanned(lastScannedPayload);
      toastWarning(
        `Receipt ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
    return undefined;
  };

  const handleReceiptMarkDamagedClick = () => {
    onFormSubmit({
      id: lastScanned.id,
      deliveryState: deliveryStateDamaged,
      auditNotes: makeAuditNote(`Delivery marked damaged`),
    });
    return true;
  };

  const handleReceiptMarkUndamagedClick = () => {
    onFormSubmit({
      id: lastScanned.id,
      deliveryState: deliveryStateUndamaged,
      auditNotes: makeAuditNote(`Delivery marked undamaged`),
    });
    return true;
  };

  const handleReceiptMarkRejectedClick = () => {
    onFormSubmit({
      id: lastScanned.id,
      deliveryStatus: deliveryStatusRejected,
      auditNotes: makeAuditNote(`Delivery rejected`),
    });
    return true;
  };

  const handleReceiptMarkAcceptedClick = () => {
    onFormSubmit({
      id: lastScanned.id,
      deliveryStatus: deliveryStatusAccepted,
      auditNotes: makeAuditNote(`Delivery accepted`),
    });
    return true;
  };

  const handleReceiptMoveToMainld00 = () => {
    const currentSiteLocation = get(pageData, 'siteLocationList', []).find(
      (sl) => sl.id === parseInt(lastScanned.siteLocationId, 10)
    );
    const mainSiteLocation = get(pageData, 'siteLocationList', []).find(
      (sl) => sl.name === defaultLaydownArea
    );
    onFormSubmit({
      id: lastScanned.id,
      siteLocationId: mainSiteLocation.id,
      auditNotes: makeAuditNote(
        `Moved from ${currentSiteLocation.name} to ${mainSiteLocation.name}`
      ),
    });
    return true;
  };

  const handleReceiptNotesModalClick = () => {
    setShowReceiptNotesModal(true);
  };

  const handleReceiptNotesModalCancel = () => {
    setShowReceiptNotesModal(false);
  };

  const handleReceiptNotesModalComplete = (data) => {
    setShowReceiptNotesModal(false);
    onFormSubmit({
      id: lastScanned.id,
      receiptNotes: data.receiptNotes,
      auditNotes: makeAuditNote('Delivery notes updated'),
    });
    return true;
  };

  const handleSiteLocationModalClick = () => {
    setShowSiteLocationModal(true);
  };

  const handleSiteLocationModalCancel = () => {
    setShowSiteLocationModal(false);
  };

  const handleSiteLocationModalComplete = (data) => {
    const currentSiteLocation = get(pageData, 'siteLocationList', []).find(
      (sl) => sl.id === parseInt(lastScanned.siteLocationId, 10)
    );
    const newSiteLocation = get(pageData, 'siteLocationList', []).find(
      (sl) => sl.id === parseInt(data.siteLocationId, 10)
    );
    setShowSiteLocationModal(false);
    const submitData = {
      id: lastScanned.id,
      siteLocationId: data.siteLocationId,
      auditNotes: makeAuditNote(
        `Moved from ${currentSiteLocation.name} to ${newSiteLocation.name}`
      ),
    };
    onFormSubmit(submitData);
    return true;
  };

  const handleManualReceiptModalClick = () => {
    setLastScanned({});
    setShowManualReceiptModal(true);
  };

  const handleManualReceiptModalCancel = () => {
    setShowManualReceiptModal(false);
  };

  const handleManualReceiptModalComplete = async (data) => {
    setShowManualReceiptModal(false);
    let currentReceipt = {};
    if (data.name) {
      const currentReceiptResp = await receiptByName({
        variables: {
          name: data.name,
        },
        fetchPolicy: 'no-cache',
      });
      currentReceipt = get(currentReceiptResp, 'data.receiptByName');
      if (currentReceipt) {
        currentReceipt = pickValues(currentReceipt, receiptWhiteList);
        if (currentReceipt.siteLocationId !== parseInt(data.siteLocationId, 10)) {
          currentReceipt.siteLocationId = parseInt(data.siteLocationId, 10);
        }
        if (data.description && currentReceipt.description !== data.description) {
          currentReceipt.description = data.description;
        }
      }
    }
    const submitData = {
      ...data,
      deliveryState: deliveryStateUndamaged,
      deliveryStatus: deliveryStatusAccepted,
      scannedData: 'manual',
      ...currentReceipt,
    };
    const siteLocation = get(pageData, 'siteLocationList', []).find(
      (sl) => sl.id === parseInt(submitData.siteLocationId, 10)
    );
    submitData.auditNotes = makeAuditNote(
      `Manual goods receipt at ${siteLocation.name}`,
      submitData.auditNotes
    );
    if (!submitData.consignmentItemId) {
      const consignmentItem = get(pageData, 'consignmentItemList', []).find(
        (ci) => ci.consignmentItemReference === submitData.name
      );
      if (consignmentItem) {
        submitData.consignmentItemId = consignmentItem.id;
        submitData.description = consignmentItem.description;
      }
    }
    onFormSubmit(submitData);
    return true;
  };

  const handleScanReceiptModalClick = () => {
    setLastScanned({});
    setShowScanReceiptModal(true);
  };

  const handleScanReceiptModalCancel = () => {
    setShowScanReceiptModal(false);
  };

  const handleScanReceiptModalComplete = async (results) => {
    setShowScanReceiptModal(false);
    const rawValue = get(results, [0, 'rawValue'], '').trim();
    if (!rawValue) {
      toastError('Missing scan result');
      return false;
    }
    let name;
    let description;
    let laydownRef;
    if (rawValue.split(',').length === 1) {
      name = rawValue;
    } else {
      [, name, description, laydownRef] = rawValue.split(',');
    }
    if (!name) {
      toastError('Missing consignment reference');
      return false;
    }

    let scannedSiteLocation;
    if (laydownRef) {
      scannedSiteLocation = get(pageData, 'siteLocationList', []).find(
        (sl) => sl.name === laydownRef
      );
      if (!scannedSiteLocation) {
        toastError(`Missing laydown area ${laydownRef} not set up`);
        return false;
      }
    }

    const data = {
      name,
      description,
      scannedData: rawValue,
      deliveryState: deliveryStateUndamaged,
      deliveryStatus: deliveryStatusAccepted,
      ...(scannedSiteLocation && { siteLocationId: scannedSiteLocation.id }),
    };

    // add information from consignmentItem
    const consignmentItem = get(pageData, 'consignmentItemList', []).find(
      (ci) =>
        ci.consignmentItemReference === data.name ||
        (ci.consignmentItemMemberReferences.length > 0 &&
          ci.consignmentItemMemberReferences.includes(data.name))
    );
    if (consignmentItem) {
      data.consignmentItemId = consignmentItem.id;
      data.description = consignmentItem.description;
      if (!data.siteLocationId) {
        data.siteLocationId = consignmentItem.siteLocationId;
      }
      if (data.name !== consignmentItem.consignmentItemReference) {
        // was found by consignmentItemMemberReference
        // change to the consignmentItem name (not the module on the pallet)
        data.name = consignmentItem.consignmentItemReference;
      }
    }

    // add information from current receipt (particularly id to make an update mutation)
    let currentReceipt = {};
    const currentReceiptResp = await receiptByName({
      variables: {
        name: data.name,
      },
      fetchPolicy: 'no-cache',
    });
    currentReceipt = get(currentReceiptResp, 'data.receiptByName');
    if (currentReceipt) {
      currentReceipt = pickValues(currentReceipt, receiptWhiteList);
      // unlike manual form, the stored receipt always is canonical for site and description
    }

    const submitData = {
      ...data,
      ...currentReceipt,
    };

    if (!submitData.siteLocationId) {
      // can't find a consignment item or receipt so give it a default location
      const defaultSiteLocation = get(pageData, 'siteLocationList', []).find(
        (sl) => sl.name === defaultLaydownArea
      );
      submitData.siteLocationId = defaultSiteLocation.id;
    }

    const currentSiteLocation = get(pageData, 'siteLocationList', []).find(
      (sl) => sl.id === parseInt(submitData.siteLocationId, 10)
    );
    submitData.auditNotes = makeAuditNote(
      `Scanned goods receipt at ${currentSiteLocation.name}`,
      submitData.auditNotes
    );
    onFormSubmit(submitData);
    return true;
  };

  const handleConsignmentImageFormModalClick = () => {
    setShowConsignmentImageFormModal(true);
  };

  const handleConsignmentImageFormModalCancel = () => {
    setShowConsignmentImageFormModal(false);
  };

  const handleConsignmentImageFormModalComplete = () => {
    setShowConsignmentImageFormModal(false);
  };

  const handleConsignmentImageCaptureModalClick = () => {
    setShowConsignmentImageCaptureModal(true);
  };

  const handleConsignmentImageCaptureModalCancel = () => {
    setShowConsignmentImageCaptureModal(false);
  };

  const handleConsignmentImageCaptureModalComplete = () => {
    setShowConsignmentImageCaptureModal(false);
  };

  const renderContent = () => (
    <>
      <ScanReceiptModal
        show={showScanReceiptModal}
        onCancel={handleScanReceiptModalCancel}
        onScan={handleScanReceiptModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
      />
      <ManualReceiptModal
        show={showManualReceiptModal}
        onCancel={handleManualReceiptModalCancel}
        onComplete={handleManualReceiptModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
        siteLocations={get(pageData, 'siteLocationList', [])}
        consignmentItems={get(pageData, 'consignmentItemList', [])}
      />
      <SiteLocationModal
        show={showSiteLocationModal}
        onCancel={handleSiteLocationModalCancel}
        onComplete={handleSiteLocationModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
        siteLocations={get(pageData, 'siteLocationList', [])}
        lastScannedName={lastScanned.name}
        lastScannedSiteLocationId={lastScanned.siteLocationId}
      />
      <ReceiptNotesModal
        show={showReceiptNotesModal}
        onCancel={handleReceiptNotesModalCancel}
        onComplete={handleReceiptNotesModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
        lastScannedReceiptNotes={lastScanned.receiptNotes}
      />
      <ConsignmentImageFormModal
        show={showConsignmentImageFormModal}
        consignmentId={get(lastScanned, 'consignmentId')}
        consignmentItemId={get(lastScanned, 'consignmentItemId')}
        receiptName={get(lastScanned, 'name')}
        receiptId={get(lastScanned, 'id')}
        onCancel={handleConsignmentImageFormModalCancel}
        onComplete={handleConsignmentImageFormModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
      />
      <ConsignmentImageCaptureModal
        show={showConsignmentImageCaptureModal}
        consignmentId={get(lastScanned, 'consignmentId')}
        consignmentItemId={get(lastScanned, 'consignmentItemId')}
        receiptId={get(lastScanned, 'id')}
        onCancel={handleConsignmentImageCaptureModalCancel}
        onComplete={handleConsignmentImageCaptureModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
      />
      <Row className="mt-4 mb-4">
        <Stack gap={3} className="col-md-5 mx-auto">
          {lastScanned.laydownRef && (
            <Alert variant="warning" className="text-center mb-0 p-4 lead">
              Offload&nbsp;to&nbsp;
              <strong>{lastScanned.laydownRef}</strong>
            </Alert>
          )}
          <Dropdown as={ButtonGroup}>
            <Button
              style={{ flexGrow: 1 }}
              className="p-1"
              size="lg"
              variant="secondary"
              onClick={handleManualReceiptModalClick}
            >
              <PlusCircleIcon size="24" />
            </Button>
            <Button
              style={{ flexGrow: 3 }}
              size="lg"
              variant="primary"
              className="p-4"
              onClick={handleScanReceiptModalClick}
            >
              New Scan
            </Button>
            <Dropdown.Toggle
              split
              size="lg"
              variant="danger"
              className="p-3"
              id="dropdown-split-basic"
              style={{ flexGrow: 1 }}
              disabled={!lastScanned.id}
            />
            <Dropdown.Menu>
              {lastScanned.deliveryState &&
                lastScanned.deliveryState === deliveryStateUndamaged && (
                  <Dropdown.Item
                    className="fs-5 lh-base"
                    onClick={handleReceiptMarkDamagedClick}
                  >
                    Mark as Damaged
                  </Dropdown.Item>
                )}
              {lastScanned.deliveryState &&
                lastScanned.deliveryState === deliveryStateDamaged && (
                  <Dropdown.Item
                    className="fs-5 lh-base"
                    onClick={handleReceiptMarkUndamagedClick}
                  >
                    Mark as Undamaged
                  </Dropdown.Item>
                )}
              {lastScanned.deliveryStatus &&
                lastScanned.deliveryStatus === deliveryStatusAccepted && (
                  <Dropdown.Item
                    className="fs-5 lh-base"
                    onClick={handleReceiptMarkRejectedClick}
                  >
                    Mark as Rejected
                  </Dropdown.Item>
                )}
              {lastScanned.deliveryStatus &&
                lastScanned.deliveryStatus === deliveryStatusRejected && (
                  <Dropdown.Item
                    className="fs-5 lh-base"
                    onClick={handleReceiptMarkAcceptedClick}
                  >
                    Mark as Accepted
                  </Dropdown.Item>
                )}
              <Dropdown.Item
                className="fs-5 lh-base"
                onClick={handleReceiptMoveToMainld00}
              >
                {`Move to ${defaultLaydownArea}`}
              </Dropdown.Item>
              <Dropdown.Item
                className="fs-5 lh-base"
                onClick={handleSiteLocationModalClick}
              >
                Move to Location...
              </Dropdown.Item>
              <Dropdown.Item
                className="fs-5 lh-base"
                onClick={handleReceiptNotesModalClick}
              >
                {lastScanned.receiptNotes ? 'Edit note' : 'Add a note'}
              </Dropdown.Item>

              <Dropdown.Item
                className="fs-5 lh-base"
                onClick={handleConsignmentImageFormModalClick}
              >
                Upload an image
              </Dropdown.Item>
              <Dropdown.Item
                className="fs-5 lh-base"
                onClick={handleConsignmentImageCaptureModalClick}
              >
                Take a picture
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="fs-5 lh-base" onClick={() => setLastScanned({})}>
                Clear
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header>Current Receipt</Card.Header>
            <Card.Body>
              {lastScanned.id && (
                <>
                  <DlHorizontal
                    dtWidth={6}
                    ddWidth={6}
                    size="xs"
                    dt="Laydown Ref"
                    dd={get(lastScanned, 'laydownRef', '-')}
                  />
                  <DlHorizontal
                    dtWidth={6}
                    ddWidth={6}
                    size="xs"
                    dt="Consignment Ref"
                    dd={get(lastScanned, 'name', '-')}
                  />
                  <DlHorizontal
                    dtWidth={6}
                    ddWidth={6}
                    size="xs"
                    dt="Description"
                    dd={get(lastScanned, 'description', '-')}
                  />
                  <DlHorizontal
                    dtWidth={6}
                    ddWidth={6}
                    size="xs"
                    dt="Delivery State"
                    dd={get(lastScanned, 'deliveryState', '-')}
                  />
                  <DlHorizontal
                    dtWidth={6}
                    ddWidth={6}
                    size="xs"
                    dt="Delivery Status"
                    dd={get(lastScanned, 'deliveryStatus', '-')}
                  />
                  <DlHorizontal
                    dtWidth={6}
                    ddWidth={6}
                    size="xs"
                    dt="Delivery Notes"
                    dd={renderMultlilineText(get(lastScanned, 'receiptNotes', '-'))}
                  />
                </>
              )}
            </Card.Body>
          </Card>
          {lastScanned.id && (
            <Card>
              <Card.Header>Audit Log</Card.Header>
              <Card.Body>
                {renderMultlilineText(get(lastScanned, 'auditNotes', '-'))}
              </Card.Body>
            </Card>
          )}
          {currentUser.perms.developer && (
            <Card>
              <Card.Body>
                <pre>{JSON.stringify(lastScanned, undefined, 2)}</pre>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderVersionOutdated(settingsVersionCurrent)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default GoodsReceiptShow;
