import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import compact from 'lodash.compact';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import {
  wbsCodeListQuery,
  wbsCodeDelete as wbsCodeDeleteMutation,
} from '../graphql/wbs_code_queries';
import { settingsSet } from '../store/settings_slice';
import * as updateFunctions from '../update_functions';

const WbsCodeList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [wbsCodeDelete] = useMutation(wbsCodeDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(wbsCodeListQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ wbsCodeList }) => {
      updateFunctions.wbsCodeList(pageClient, wbsCodeList);
    },
  });

  const wbsCodeDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const wbsCodeId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: wbsCodeId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'WbsCodeType',
        recordId: wbsCodeId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.wbsCodeDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'wbsCodeDelete',
      mutationData
    );
    dispatch(settingsSet({ mutating: true }));

    if (settingsOnline) {
      try {
        await wbsCodeDelete(mutationData);
        toastSuccess('WbsCode delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('WbsCode delete failed');
      } finally {
        dispatch(settingsSet({ mutating: false }));
        pageRefetch();
      }
    } else {
      wbsCodeDelete(mutationData);
      toastWarning(`WbsCode delete ok locally. Go online to make permanent`);
      dispatch(settingsSet({ mutating: false }));
    }
  };

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const getCategoryCode = (row) => {
    const { code, parent } = row;
    if (!parent) {
      // A category
      return code;
    }
    if (parent && !parent.parent) {
      // A task
      return parent.code;
    }
    // An activity
    return parent.parent.code;
    // return '-';
  };

  const getTaskCode = (row) => {
    const { code, parent } = row;
    if (!parent) {
      // A category
      return '-';
    }
    if (parent && !parent.parent) {
      // A task
      return code;
    }
    // An activity
    return parent.code;
  };

  const getActivityCode = (row) => {
    const { code, parent } = row;
    if (!parent) {
      // A category
      return '-';
    }
    if (parent && !parent.parent) {
      // A task
      return '-';
    }
    // An activity
    return code;
  };

  // const getParentName = (row) => {
  //   if (row.parent) {
  //     const {
  //       parent: { name: parentName },
  //     } = row;
  //     return parentName;
  //   }
  //   return '-';
  // };

  const getCodeName = (row) => {
    const { prefix, code, parent } = row;
    const { code: parentCode, parent: grandParent } = parent || {};
    const { code: grandParentCode } = grandParent || {};
    return compact([prefix, grandParentCode, parentCode, code]).join(' ');
  };

  const getLeafStatus = (row) => {
    if (row.children.length === 0) {
      return 'Yes';
    }
    return 'No';
  };

  const parentColumns = [
    {
      header: 'Code',
      id: 'code',
      accessorFn: (row) => getCodeName(row),
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Prefix',
      accessorKey: 'prefix',
      enableColumnFilter: true,
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Category Code',
      id: 'categoryCode',
      accessorFn: (row) => getCategoryCode(row),
      enableColumnFilter: true,
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Task Code',
      id: 'taskCode',
      accessorFn: (row) => getTaskCode(row),
      enableColumnFilter: true,
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Activity Code',
      id: 'activityCode',
      accessorFn: (row) => getActivityCode(row),
      enableColumnFilter: true,
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    // {
    //   header: 'Parent',
    //   id: 'parent',
    //   accessorFn: (row) => getParentName(row),
    //   filterType: 'dropdown',
    // },
    {
      header: 'Leaf',
      id: 'leaf',
      accessorFn: (row) => getLeafStatus(row),
      filterType: 'dropdown',
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">WBS Codes</h1>
            </div>
            <div className="float-end">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
              <LinkContainer to="/wbs_codes/new">
                <Button variant="primary">New WBS Code</Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              {pageData && pageData.wbsCodeList ? (
                <ReactTable
                  rootName="wbsCode"
                  parentColumns={parentColumns}
                  data={pageData.wbsCodeList}
                  doShow={false}
                  doDelete={currentUser?.perms?.administrator}
                  handleDelete={wbsCodeDeleteClicked}
                />
              ) : (
                <div>Loading...</div> // Handle loading state
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default WbsCodeList;
