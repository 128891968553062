import { useState, useEffect } from 'react';
import { FormControl } from 'react-bootstrap';

const BlurOnlyFormControl = (props) => {
  const { value } = props;

  const [fieldValue, setFieldValue] = useState('');

  useEffect(() => {
    setFieldValue(value || '');
  }, [value]);

  return (
    <FormControl
      {...props}
      value={fieldValue}
      onChange={(e) => setFieldValue(e.target.value)}
    />
  );
};

export default BlurOnlyFormControl;
