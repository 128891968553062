import { Row, Col, Button } from 'react-bootstrap';
import { FieldArray } from 'react-final-form-arrays';

import get from 'lodash.get';

import PicklistProductFields from './picklist_product_fields';
// import Glyphicon from '../components/glyphicon';
import { picklistProductDefaultValues } from '../../defaults';

const defaultProps = {
  labelWidth: 4,
  inputWidth: 8,
  size: 'sm',
};

const PicklistProductFieldArray = (props) => {
  const { picklist, products, labelWidth, inputWidth, size } = {
    ...defaultProps,
    ...props,
  };
  return (
    <FieldArray name="picklistProducts">
      {({ fields: arrayFields }) => {
        const { push, remove } = arrayFields;
        return (
          <Row>
            {arrayFields.map((arrayName, arrayIndex) => {
              const picklistProduct = get(picklist, arrayName, {});
              return (
                <PicklistProductFields
                  key={arrayName}
                  arrayName={arrayName}
                  arrayIndex={arrayIndex}
                  remove={remove}
                  picklistProduct={picklistProduct}
                  products={products}
                  labelWidth={labelWidth}
                  inputWidth={inputWidth}
                  size={size}
                />
              );
            })}
            <Col sm={{ span: inputWidth, offset: labelWidth }} className="mt-2">
              <Button
                size={size}
                type="button"
                variant="dark"
                onClick={() => push(picklistProductDefaultValues)}
              >
                <span className="align-middle">Add Product Assembly Link</span>
              </Button>
            </Col>
          </Row>
        );
      }}
    </FieldArray>
  );
};

export default PicklistProductFieldArray;
