const ErrorComponent = ({ error }) => (
  <div>
    <p>Something went wrong</p>
    {process.env.NODE_ENV !== 'production' && (
      <pre>{JSON.stringify(error, undefined, 2)}</pre>
    )}
  </div>
);

export default ErrorComponent;
