export const productSupplierCatalogItemIntegers = [];
export const productSupplierCatalogItemFloats = [];
export const productSupplierCatalogItemIsoStrings = [];
export const productSupplierCatalogItemWhiteList = [
  'id',
  'quantity',
  'productId',
  'supplierCatalogItemId',
];
export const productSupplierCatalogItemOptimisticList = [
  ...productSupplierCatalogItemWhiteList,
  'product',
  'supplierCatalogItem',
];
