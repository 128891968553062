import { useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';
import { Typeahead } from 'react-bootstrap-typeahead';
import arrify from 'arrify';

import curry from 'lodash.curry';
import cloneDeep from 'lodash.clonedeep';
import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import OnChangeField from '../components/form/rff_on_change_field';
import InputField from '../components/form/input_field';
import InvalidBlock from '../components/form/invalid_block';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  receiptFormPageQuery,
  receiptCreate as receiptCreateMutation,
  receiptUpdate as receiptUpdateMutation,
} from '../graphql/receipt_queries';
import * as updateFunctions from '../update_functions';
import { receiptDefaultValues } from '../defaults';
import { receiptFormValidator } from '../validators';
import { receiptWhiteList } from '../white_lists';

const ReceiptForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const currentUser = useSelector((state) => state.auth.user);
  const [receiptCreate] = useMutation(receiptCreateMutation);
  const [receiptUpdate] = useMutation(receiptUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(receiptFormPageQuery, {
    variables: {
      hasReceiptId: !!params.id,
      receiptId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const enums = useMemo(() => get(pageData, 'enums.enums'), [pageData]);

  const initialValues = useMemo(() => {
    if (pageData?.receipt) {
      const values = pickValues(pageData.receipt, receiptWhiteList);
      return values;
    }
    return receiptDefaultValues;
  }, [pageData]);

  const onCancel = () => {
    navigate('/receipts');
  };

  const onTypeaheadChange = curry((form, name, value) => {
    let formValue = value[0] || null;
    if (formValue && formValue.id) {
      formValue = formValue.id;
    }
    form.change(name, formValue);
  });

  const onConsignmentItemIdChange = (form, values) => {
    let siteLocationId = null;
    let name = null;
    const consignmentItemId = defaultTo(parseInt(values.consignmentItemId, 10), null);
    if (consignmentItemId) {
      const consignmentItem = get(pageData, 'consignmentItemList', []).find(
        (ci) => ci.id === consignmentItemId
      );
      if (consignmentItem) {
        siteLocationId = consignmentItem.siteLocationId;
        name = consignmentItem.consignmentItemReference;
      }
    }
    form.change('siteLocationId', siteLocationId);
    form.change('name', name);
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    if (!submitData.scannedData) {
      submitData.scannedData = 'receipt-form';
    }
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ReceiptType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = receiptUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.receiptUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'receiptUpdate',
        mutationData,
        currentData: pageData.receipt,
      });
    } else {
      mutation = receiptCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.receiptCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'receiptCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Receipt ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/receipts');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Receipt ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/receipts');
    }
    return undefined;
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">{params.id ? 'Edit Receipt' : 'New Receipt'}</h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/receipts">
                <Button className="me-2" variant="primary">
                  All Receipts
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={receiptFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting, form, values }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <OnChangeField name="consignmentItemId">
                      {() => onConsignmentItemIdChange(form, values)}
                    </OnChangeField>
                    <Field name="consignmentItemId">
                      {({ input, meta }) => (
                        <InputField
                          size="lg"
                          labelWidth={4}
                          inputWidth={6}
                          input={{
                            name: input.name,
                          }}
                          innerContent={
                            <>
                              <Typeahead
                                id="consignment-item-id-typeahead-single"
                                labelKey="consignmentItemReference"
                                onBlur={input.onBlur}
                                onChange={onTypeaheadChange(form, input.name)}
                                options={get(pageData, 'consignmentItemList', [])}
                                placeholder="select consignment item..."
                                selected={arrify(
                                  get(pageData, 'consignmentItemList', []).find(
                                    (ci) => ci.id === input.value
                                  )
                                )}
                              />
                              <InvalidBlock meta={meta || {}} force />
                            </>
                          }
                        >
                          Consignment Item
                        </InputField>
                      )}
                    </Field>
                    <Field
                      name="name"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Consignment Reference
                    </Field>
                    <Field
                      name="siteLocationId"
                      component={InputField}
                      asElement="select"
                      selectOptions={get(pageData, 'siteLocationList', [])}
                      defaultSelectOptionText="select site location..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Site Location
                    </Field>
                    <Field
                      name="description"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Consignment Description
                    </Field>
                    <Field
                      name="receiptNotes"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                      asElement="textarea"
                      rows={2}
                    >
                      Delivery Notes
                    </Field>
                    <Field
                      name="deliveryState"
                      component={InputField}
                      asElement="select"
                      selectOptions={Object.values(get(enums, 'DeliveryStates', [])).map(
                        (type) => ({
                          id: type,
                          name: type,
                        })
                      )}
                      defaultSelectOptionText="select delivery state..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Delivery State
                    </Field>
                    <Field
                      name="deliveryStatus"
                      component={InputField}
                      asElement="select"
                      selectOptions={Object.values(
                        get(enums, 'DeliveryStatuses', [])
                      ).map((type) => ({
                        id: type,
                        name: type,
                      }))}
                      defaultSelectOptionText="select delivery status..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Delivery Status
                    </Field>
                    {currentUser.perms.developer && (
                      <>
                        <Field
                          name="auditNotes"
                          component={InputField}
                          labelWidth={4}
                          inputWidth={8}
                          size="lg"
                          asElement="textarea"
                          rows={2}
                        >
                          Audit Notes
                        </Field>
                        <Field
                          name="scannedData"
                          component={InputField}
                          labelWidth={4}
                          inputWidth={8}
                          size="lg"
                        >
                          Scanned Data
                        </Field>
                      </>
                    )}
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ReceiptForm;
