// export const consignmentDefaultValues = {};

import { DateTime } from 'luxon';

export function getConsignmentDefaultValues() {
  return {
    shipmentNumber: 0,
    estimatedDeliveryAt: DateTime.now()
      .plus({ days: 1 })
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      .toISO(),
  };
}
