import { useMemo, useCallback, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import proj4 from 'proj4';
// import { DateTime } from 'luxon';
import { Queue } from 'async-await-queue';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import { constantCase } from 'change-case';

import chunk from 'lodash.chunk';
import compact from 'lodash.compact';
import difference from 'lodash.difference';
import get from 'lodash.get';
import intersection from 'lodash.intersection';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import uniq from 'lodash.uniq';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { toastSuccess, toastError } from '../lib/toast_helpers';
import Confirm from '../components/confirm';
import { pickValues, handleSubmitError } from '../lib/utils';
import { tableSchema } from '../lib/importers/table_schema';
import { tableImportFormPageQuery } from '../graphql/import_queries';
import {
  tableBatchCreate as tableBatchCreateMutation,
  tableBatchUpdate as tableBatchUpdateMutation,
  tableBatchBuildStatusUpdate as tableBatchBuildStatusUpdateMutation,
} from '../graphql/table_queries';
import { tableWhiteList } from '../white_lists';

const tableQueue = new Queue(1, 25);
const priority = -1;

const TableImportForm = () => {
  const projectCode = '000466';
  const navigate = useNavigate();
  const params = useParams();
  const [fileError, setFileError] = useState('');
  const [fileRows, setFileRows] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [tableBatchCreate] = useMutation(tableBatchCreateMutation);
  const [tableBatchUpdate] = useMutation(tableBatchUpdateMutation);
  const [tableBatchBuildStatusUpdate] = useMutation(tableBatchBuildStatusUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    refetch: pageRefetch,
  } = useQuery(tableImportFormPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const tableBatchBuildStatusUpdateClicked = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    tableBatchBuildStatusUpdate()
      .then(() => {
        toastSuccess(`All tables build status update requested`);
      })
      .catch((err) => {
        const { errorMessage } = handleSubmitError(err);
        toastError(errorMessage);
      });

  const round9 = (num) => Math.round(num * 1000000000) / 1000000000;

  const handleFileChange = useCallback(
    async (name, onChange, e) => {
      // const site = get(pageData, 'siteList', []).find(
      //   (s) => s.projectNumber === projectCode
      // );
      // const siteId = site.id;
      // const pilingMachines = get(pageData, 'pilingMachineList', []).reduce(
      //   (accum, { id, naskuMachineRef }) => ({ ...accum, [naskuMachineRef]: id }),
      //   {}
      // );
      const file = get(e, 'target.files.0');
      if (file) {
        setFileError('');
        const sheetNames = await readSheetNames(file);
        try {
          if (sheetNames.includes('Summary')) {
            const { rows, errors = [] } = await readXlsxFile(file, {
              schema: tableSchema,
              sheet: 'Summary',
            });
            if (rows && rows.length > 0) {
              const newFileRows = rows.map((row) => {
                // id: ID
                // inverterId: ID
                // name: String!
                // buildStatus: String
                // tableType: String
                // epsg2105Northing: Float
                // epsg2105Easting: Float
                // lat: Float
                // lng: Float
                // geom: JSONObject
                // finishedAt: String
                // finishedOn: String
                const {
                  name: tableName,
                  layer,
                  epsg2105NorthingString,
                  epsg2105EastingString,
                } = row;

                const [, inverterNumber, tableTypeRaw] = layer.split(' ');
                const inverter = get(pageData, 'inverterList', []).find(
                  (i) => i.name === `Inverter ${inverterNumber}`
                );
                const inverterId = inverter.id;
                const tableType = constantCase(tableTypeRaw);
                const buildStatus = get(
                  pageData,
                  'enums.enums.TableBuildStatuses.WAITING_COORDINATES'
                );
                const epsg2105Northing = parseFloat(epsg2105NorthingString);
                const epsg2105Easting = parseFloat(epsg2105EastingString);

                // console.log({
                //   tableName,
                //   epsg2105Northing,
                //   epsg2105Easting,
                //   inverterId,
                //   tableType,
                //   buildStatus,
                // });

                let lat;
                let lng;
                let geom;

                const transformNW = proj4('EPSG:2105', 'EPSG:4326', [
                  epsg2105Easting,
                  epsg2105Northing,
                ]);
                if (transformNW && transformNW.length === 2) {
                  const [lngNW, latNW] = transformNW;
                  lng = round9(lngNW);
                  lat = round9(latNW);

                  const [lngNE, latNE] = proj4('EPSG:2105', 'EPSG:4326', [
                    epsg2105Easting + 18.7425,
                    epsg2105Northing,
                  ]);

                  const [lngSE, latSE] = proj4('EPSG:2105', 'EPSG:4326', [
                    epsg2105Easting + 18.7425,
                    epsg2105Northing - 4.2,
                  ]);

                  const [lngSW, latSW] = proj4('EPSG:2105', 'EPSG:4326', [
                    epsg2105Easting,
                    epsg2105Northing - 4.2,
                  ]);

                  geom = {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [round9(latNW), round9(lngNW)],
                        [round9(latNE), round9(lngNE)],
                        [round9(latSE), round9(lngSE)],
                        [round9(latSW), round9(lngSW)],
                        [round9(latNW), round9(lngNW)],
                      ],
                    ],
                  };
                }

                return {
                  inverterId,
                  name: tableName,
                  buildStatus,
                  tableType,
                  epsg2105Northing,
                  epsg2105Easting,
                  lat,
                  lng,
                  geom,
                };
              });
              setFileError('');
              setFileRows(newFileRows);
              setFileErrors(errors);
              onChange(file);
            } else {
              setFileError('Document has no rows');
              setFileRows([]);
              setFileErrors([]);
            }
          } else {
            setFileError('Spreadsheet must include a sheet titled "Summary"');
            setFileRows([]);
            setFileErrors([]);
          }
        } catch (err) {
          console.log(err);
          setFileError(err.message);
          setFileRows([]);
          setFileErrors([]);
        }
      }
    },
    [pageData]
  );

  const onCancel = () => {
    navigate('/');
  };

  const onFormSubmit = async () => {
    const existingTables = get(pageData, 'tableList');
    const existingTableNames = existingTables.map((s) => s.name);
    const rowTableNames = uniq(fileRows.map((r) => r.name));
    const createableTableNames = difference(rowTableNames, existingTableNames);
    const updateableTableNames = intersection(rowTableNames, existingTableNames);
    const createableTables = fileRows.filter((r) =>
      createableTableNames.includes(r.name)
    );
    const updateableTables = fileRows
      .filter((rowTable) => updateableTableNames.includes(rowTable.name))
      .map((rowTable) => {
        const isEqualRowTable = omit(rowTable, ['buildStatus']);
        // There are a few duplicates in imported data with the same names.
        // These are probably errors
        // If that is the case, also select by geometry
        const existingTablesForName = existingTables.filter(
          (et) => et.name === rowTable.name
        );
        let existingTable;
        if (existingTablesForName.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          existingTable = existingTablesForName[0];
        } else {
          // console.log({ rowTable });
          // console.log({ existingTablesForName });
          existingTable = existingTablesForName.find(
            (et) =>
              et.epsg2105Northing === rowTable.epsg2105Northing &&
              et.epsg2105Easting === rowTable.epsg2105Easting
          );
        }
        if (!existingTable) {
          throw new Error(
            `Existing table not found for ${rowTable.name}. Probably a duplicate with changed geometry`
          );
        }
        const whiteListExistingTable = pickValues(existingTable, tableWhiteList);
        const isEqualExistingTable = omit(whiteListExistingTable, ['id', 'buildStatus']);

        if (!isEqual(isEqualRowTable, isEqualExistingTable)) {
          console.log({ isEqualRowTable, isEqualExistingTable });
          return {
            ...whiteListExistingTable,
            ...isEqualRowTable,
          };
        }
        return undefined;
      });

    let chunkedCreateableTablePromises = [Promise.resolve(true)];
    // console.log({ createableTables });
    if (createableTables.length > 0) {
      const chunkedCreateableTables = chunk(createableTables, 400);
      const chunkedCreateableTablesLength = chunkedCreateableTables.length;
      chunkedCreateableTablePromises = chunkedCreateableTables.map(
        async (chunkData, index) => {
          await tableQueue.wait(index, priority);
          console.log(`Createable Chunk: ${index + 1}/${chunkedCreateableTablesLength}`);
          try {
            return await tableBatchCreate({
              variables: { input: { tables: chunkData } },
            });
          } finally {
            tableQueue.end(index);
          }
        }
      );
    }
    await Promise.all(chunkedCreateableTablePromises);

    const compactedUpdateableTables = compact(updateableTables);
    // console.log({ compactedUpdateableTables });
    let chunkedUpdateableTablePromises = [Promise.resolve(true)];
    if (compactedUpdateableTables.length > 0) {
      const chunkedUpdateableTables = chunk(compactedUpdateableTables, 400);
      const chunkedUpdateableTablesLength = chunkedUpdateableTables.length;
      chunkedUpdateableTablePromises = chunkedUpdateableTables.map(
        async (chunkData, index) => {
          await tableQueue.wait(index, priority);
          console.log(`Updateable Chunk: ${index + 1}/${chunkedUpdateableTablesLength}`);
          try {
            return await tableBatchUpdate({
              variables: { input: { tables: chunkData } },
            });
          } finally {
            tableQueue.end(index);
          }
        }
      );
    }
    await Promise.all(chunkedUpdateableTablePromises);
    pageRefetch();
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">Table Import</h1>
          <p>
            This form is to upsert tables. It expects an excel file exported by John
            Frater
          </p>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Confirm
                onConfirm={tableBatchBuildStatusUpdateClicked}
                title="Update table build status"
                body="Confirm to reprocess build statuses"
                confirmText="Confirm"
              >
                <Button type="button" variant="dark">
                  Update Build Status
                </Button>
              </Confirm>
            </Col>
          </Row>
        </Col>
      </Row>
      {fileError && (
        <Row>
          <Col>
            <Alert variant="danger">{fileError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FinalForm
            onSubmit={(data) => onFormSubmit(data)}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      type="file"
                      name="file"
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                      component={InputField}
                      customOnChange={handleFileChange}
                    />
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="primary"
                              onClick={() => pageRefetch()}
                              disabled={!settingsOnline}
                            >
                              Refresh
                            </Button>
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      {projectCode && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>{`Project Code: ${projectCode}`}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {fileRows.length > 0 && (
        <Row>
          <Col>
            <Tabs defaultActiveKey="rows" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="rows" title="Rows">
                <pre>{JSON.stringify(fileRows, undefined, 2)}</pre>
              </Tab>
              <Tab eventKey="errors" title="Errors">
                <pre>{JSON.stringify(fileErrors, undefined, 2)}</pre>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default TableImportForm;
