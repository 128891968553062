import {
  consignmentItemListQuery,
  consignmentItemQuery,
} from '../graphql/consignment_item_queries';
import cleanCache from './clean_cache';

export const consignmentItemList = (client, listData) => {
  listData.map((consignmentItem) => {
    const data = { consignmentItem };
    return client.writeQuery({
      query: consignmentItemQuery,
      variables: { id: consignmentItem.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const consignmentItemCreate = (
  cache,
  { data: { consignmentItemCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: consignmentItemListQuery });
  } catch {
    cacheData = { consignmentItemList: [] };
  }
  cache.writeQuery({
    query: consignmentItemListQuery,
    data: { consignmentItemList: [...cacheData.consignmentItemList, createData] },
  });
  const data = { consignmentItem: createData };
  cache.writeQuery({
    query: consignmentItemQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const consignmentItemUpdate = (
  cache,
  { data: { consignmentItemUpdate: updateData } }
) => {
  const data = { consignmentItem: updateData };
  cache.writeQuery({
    query: consignmentItemQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const consignmentItemDelete = (
  cache,
  { data: { consignmentItemDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: consignmentItemListQuery });
  } catch {
    cacheData = { consignmentItemList: [] };
  }
  const data = {
    consignmentItemList: cacheData.consignmentItemList.filter(
      (consignmentItem) => deleteData.id !== consignmentItem.id
    ),
  };
  cache.writeQuery({ query: consignmentItemListQuery, data });
  cache.evict({ fieldName: 'consignmentItem', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
