export const consignmentImageIntegers = [];
export const consignmentImageFloats = [];
export const consignmentImageIsoStrings = [];
// images are never really edited so this is for optimitic updates
export const consignmentImageWhiteList = [
  'consignmentId',
  'consignmentItemId',
  'receiptId',
  'fileFilename',
  'fileMimetype',
  'filePathnameOriginal',
  'filePathnameOptimized',
  'signedUrlOriginal',
  'signedUrlOptimized',
];
export const consignmentImageOptimisticList = [...consignmentImageWhiteList];
