import {
  manufacturerQuery,
  manufacturerListQuery,
} from '../graphql/manufacturer_queries';
import cleanCache from './clean_cache';

export const manufacturerList = (client, listData) => {
  listData.map((manufacturer) => {
    const data = { manufacturer };
    return client.writeQuery({
      query: manufacturerQuery,
      variables: { id: manufacturer.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const manufacturerCreate = (
  cache,
  { data: { manufacturerCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: manufacturerListQuery });
  } catch {
    cacheData = { manufacturerList: [] };
  }
  if (!cacheData) {
    cacheData = { manufacturerList: [] };
  }
  cache.writeQuery({
    query: manufacturerListQuery,
    data: { manufacturerList: [...cacheData.manufacturerList, createData] },
  });
  const data = { manufacturer: createData };
  cache.writeQuery({
    query: manufacturerQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const manufacturerUpdate = (
  cache,
  { data: { manufacturerUpdate: updateData } }
) => {
  const data = { manufacturer: updateData };
  cache.writeQuery({
    query: manufacturerQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const manufacturerDelete = (
  cache,
  { data: { manufacturerDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: manufacturerListQuery });
  } catch {
    cacheData = { manufacturerList: [] };
  }
  if (!cacheData) {
    cacheData = { manufacturerList: [] };
  }
  const data = {
    manufacturerList: cacheData.manufacturerList.filter(
      (manufacturer) => deleteData.id !== manufacturer.id
    ),
  };
  cache.writeQuery({ query: manufacturerListQuery, data });
  cache.evict({ fieldName: 'manufacturer', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
