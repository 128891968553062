export const productIntegers = [];
export const productFloats = [];
export const productIsoStrings = [];
export const productWhiteList = ['id', 'name', 'productCategoryId'];
export const productOptimisticList = [
  ...productWhiteList,
  'productSupplierCatalogItems',
  'productProductProductSubassemblies',
  'productProductProductAssemblies',
  'productCategory',
];
