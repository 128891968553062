import { tableQuery, tableListQuery } from '../graphql/table_queries';
import cleanCache from './clean_cache';

export const tableList = (client, listData) => {
  listData.map((table) => {
    const data = { table };
    return client.writeQuery({
      query: tableQuery,
      variables: { id: table.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const tableCreate = (cache, { data: { tableCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: tableListQuery });
  } catch {
    cacheData = { tableList: [] };
  }
  if (!cacheData) {
    cacheData = { tableList: [] };
  }
  cache.writeQuery({
    query: tableListQuery,
    data: { tableList: [...cacheData.tableList, createData] },
  });
  const data = { table: createData };
  cache.writeQuery({
    query: tableQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const tableUpdate = (cache, { data: { tableUpdate: updateData } }) => {
  const data = { table: updateData };
  cache.writeQuery({
    query: tableQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const tableDelete = (cache, { data: { tableDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: tableListQuery });
  } catch {
    cacheData = { tableList: [] };
  }
  if (!cacheData) {
    cacheData = { tableList: [] };
  }
  const data = {
    tableList: cacheData.tableList.filter((table) => deleteData.id !== table.id),
  };
  cache.writeQuery({ query: tableListQuery, data });
  cache.evict({ fieldName: 'table', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
