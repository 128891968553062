// validate
// oneOf

export const bomSchema = {
  // 'MYOB Tenant': {
  //   prop: 'myobTenantName',
  //   type: String,
  //   required: true,
  // },
  Responsible: {
    prop: 'purchaser',
    type: String,
    required: true,
  },
  'WBS Code': {
    prop: 'wbsCode',
    type: String,
    required: true,
  },
  Type: {
    prop: 'productCategory',
    type: String,
    required: true,
  },
  Description: {
    prop: 'description',
    type: String,
    required: true,
  },
  Supplier: {
    prop: 'supplier',
    type: String,
    required: true,
  },
  'Brand/Manufacturer': {
    prop: 'manufacturer',
    type: String,
    required: true,
  },
  'Model/Part No.': {
    prop: 'modelPartNumber',
    type: String,
    required: true,
  },
  Unit: {
    prop: 'catalogItemUnitOfMeasure',
    // this needs to be looked up?
    // “unit”: {
    // “name”: “Metre”,
    // “id”: 3
    // },
    type: String,
    required: true,
  },
  Quantity: {
    prop: 'quantity',
    type: Number,
    required: true,
  },
  'O&M Spares': {
    prop: 'oandmSpares',
    type: Number,
    required: false,
  },
  'Construction Spares': {
    prop: 'constructionSpares',
    type: Number,
    required: false,
  },
  Contingency: {
    prop: 'contingency',
    type: Number,
    required: true,
  },
  'Total Qty': {
    prop: 'totalQty',
    type: Number,
    required: true,
  },
  'Price (Exc GST)': {
    prop: 'catalogItemUnitPrice',
    type: Number,
    required: true,
  },
  // 'Total [NZD]': {
  //   prop: 'totalNzd',
  //   type: Number,
  //   required: true,
  // },
  Currency: {
    prop: 'catalogItemCurrency',
    // this needs to be looked up?
    // “currency”: {
    // “code”: “NZD”,
    // “id”: 109,
    // },
    type: String,
    required: true,
  },
};

// [
//   'Total',
//   'Currency'
// ],

// “data”: {
//   “bom”: 1,
// “totalQty”: 1,
// “description”: “AC & DC Supply Panel”,
// “type”: “Panel -P1”,
// “total”: “23.00”,
// “price”: “15000”,
// “supplier”: “Century Yuasa”,
// “currency”: {
// “code”: “NZD”,
// “id”: 109,
// },
// “wbsCode”: “EPC 08 06”,
// “quantity”: 1,
// “modelPartNo”: “36RU Panel”,
// “unit”: {
// “name”: “Metre”,
// “id”: 3
// },
// “contingency”: “56.00",
// “brandManufacturer”: “Century Yuasa”
// }
