const consignmentItemFormSchema = {
  type: 'object',
  required: ['consignmentId', 'description'],
  properties: {
    description: { type: 'string', minLength: 6, maxLength: 255 },
    consignmentItemReference: { type: 'string', minLength: 6, maxLength: 255 },
    quantity: { type: 'number', minimum: 0 },
    weightKg: { type: 'number', minimum: 0 },
    deliveryNotes: { type: 'string', minLength: 1, maxLength: 2000 },
  },
};

export { consignmentItemFormSchema };
