import { useMemo } from 'react';
import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';
import { Typeahead } from 'react-bootstrap-typeahead';
import arrify from 'arrify';

import cloneDeep from 'lodash.clonedeep';
import curry from 'lodash.curry';

import Field from '../../components/form/rff_field';
import InputField from '../../components/form/input_field';
import InvalidBlock from '../../components/form/invalid_block';
import SubmitButtonSpinner from '../../components/submit_button_spinner';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import {
  picklistProductCreate as picklistProductCreateMutation,
  picklistProductUpdate as picklistProductUpdateMutation,
} from '../../graphql/picklist_product_queries';
import * as updateFunctions from '../../update_functions';
import { coerceInput, pickValues, handleSubmitError } from '../../lib/utils';
import { picklistProductDefaultValues } from '../../defaults';
import { picklistProductFormValidator } from '../../validators';
import { picklistProductWhiteList } from '../../white_lists';

function PicklistProductFormModal(props) {
  const dispatch = useDispatch();
  const [picklistProductCreate] = useMutation(picklistProductCreateMutation);
  const [picklistProductUpdate] = useMutation(picklistProductUpdateMutation);
  const {
    settingsTenant,
    settingsOnline,
    show,
    picklistId,
    picklistName,
    picklistProduct,
    products,
    onCancel,
    onComplete,
  } = props;

  const initialValues = useMemo(() => {
    if (picklistProduct?.id) {
      const values = pickValues(picklistProduct, picklistProductWhiteList);
      return { ...values, picklistId };
    }
    return { ...picklistProductDefaultValues, picklistId };
  }, [picklistProduct, picklistId]);

  const onTypeaheadChange = curry((form, name, value) => {
    let formValue = value[0] || null;
    if (formValue && formValue.id) {
      formValue = formValue.id;
    }
    form.change(name, formValue);
  });

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'PicklistProductType',
        recordId: picklistProduct?.id ? picklistProduct.id : uuid,
      },
    };
    if (picklistProduct?.id) {
      mutation = picklistProductUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = picklistProduct.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.picklistProductUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'picklistProductUpdate',
        mutationData,
        currentData: picklistProduct,
      });
    } else {
      mutation = picklistProductCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.picklistProductCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'picklistProductCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Product Assembly Link ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onComplete();
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Product Assembly Link ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onComplete();
    }
    return undefined;
  };

  return (
    <Modal
      id={`picklist-${picklistId}-picklist-product-form`}
      show={show}
      onHide={onCancel}
      centered
      size="xl"
      animation={false}
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        validate={picklistProductFormValidator}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting, pristine, form }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{`Add Item for ${picklistName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <fieldset className="border rounded-3 p-3">
                <legend className="float-none w-auto px-3 fs-6">Item details</legend>
                <Field name="productId">
                  {({ input, meta }) => (
                    <InputField
                      size="lg"
                      labelWidth={4}
                      inputWidth={6}
                      input={{
                        name: input.name,
                      }}
                      innerContent={
                        <>
                          <Typeahead
                            id="supplier-catalog-item-id-typeahead-single"
                            labelKey="name"
                            onBlur={input.onBlur}
                            onChange={onTypeaheadChange(form, input.name)}
                            options={products}
                            placeholder="select product assembly..."
                            selected={arrify(products.find((p) => p.id === input.value))}
                          />
                          <InvalidBlock meta={meta || {}} force />
                        </>
                      }
                    >
                      Product Assembly
                    </InputField>
                  )}
                </Field>
                <Field
                  name="quantity"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={2}
                  size="lg"
                >
                  Quantity
                </Field>
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup className="me-2">
                  <Button variant="danger" onClick={onCancel} disabled={submitting}>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    {picklistProduct?.id ? 'Update' : 'Create'}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default PicklistProductFormModal;
