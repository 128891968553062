const defaultProps = {
  size: 'sm',
  dtWidth: 5,
  ddWidth: 7,
};

const DlHorizontal = (props) => {
  const { dt, dd, children, size, dtWidth, ddWidth } = {
    ...defaultProps,
    ...props,
  };

  const dtClassName = size === 'xs' ? `col-${dtWidth}` : `col-${size}-${dtWidth}`;
  const ddClassName = size === 'xs' ? `col-${ddWidth}` : `col-${size}-${ddWidth}`;

  return (
    <dl className="row mb-2">
      <dt className={`${dtClassName} text-end`}>{dt}</dt>
      <dd className={`ps-0 mb-0 ${ddClassName}`}>{dd || children}</dd>
    </dl>
  );
};

export default DlHorizontal;
