import {
  productSupplierCatalogItemQuery,
  productSupplierCatalogItemListQuery,
} from '../graphql/product_supplier_catalog_item_queries';
import cleanCache from './clean_cache';

export const productSupplierCatalogItemList = (client, listData) => {
  listData.map((productSupplierCatalogItem) => {
    const data = { productSupplierCatalogItem };
    return client.writeQuery({
      query: productSupplierCatalogItemQuery,
      variables: { id: productSupplierCatalogItem.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const productSupplierCatalogItemCreate = (
  cache,
  { data: { productSupplierCatalogItemCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: productSupplierCatalogItemListQuery });
  } catch {
    cacheData = { productSupplierCatalogItemList: [] };
  }
  if (!cacheData) {
    cacheData = { productSupplierCatalogItemList: [] };
  }
  cache.writeQuery({
    query: productSupplierCatalogItemListQuery,
    data: {
      productSupplierCatalogItemList: [
        ...cacheData.productSupplierCatalogItemList,
        createData,
      ],
    },
  });
  const data = { productSupplierCatalogItem: createData };
  cache.writeQuery({
    query: productSupplierCatalogItemQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const productSupplierCatalogItemUpdate = (
  cache,
  { data: { productSupplierCatalogItemUpdate: updateData } }
) => {
  const data = { productSupplierCatalogItem: updateData };
  cache.writeQuery({
    query: productSupplierCatalogItemQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const productSupplierCatalogItemDelete = (
  cache,
  { data: { productSupplierCatalogItemDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: productSupplierCatalogItemListQuery });
  } catch {
    cacheData = { productSupplierCatalogItemList: [] };
  }
  if (!cacheData) {
    cacheData = { productSupplierCatalogItemList: [] };
  }
  const data = {
    productSupplierCatalogItemList: cacheData.productSupplierCatalogItemList.filter(
      (productSupplierCatalogItem) => deleteData.id !== productSupplierCatalogItem.id
    ),
  };
  cache.writeQuery({ query: productSupplierCatalogItemListQuery, data });
  cache.evict({
    fieldName: 'productSupplierCatalogItem',
    args: { id: deleteData.id.toString() },
  });
  cleanCache(cache);
};
