export const tableSchema = {
  Layer: {
    prop: 'layer',
    type: String,
    required: true,
  },
  'Position X': {
    prop: 'epsg2105EastingString',
    type: String,
    required: true,
  },
  'Position Y': {
    prop: 'epsg2105NorthingString',
    type: String,
    required: true,
  },
  'TABLE-NO': {
    prop: 'name',
    type: String,
    required: true,
  },
};
