export const receiptIntegers = [];
export const receiptFloats = [];
export const receiptIsoStrings = [];
export const receiptWhiteList = [
  'id',
  'consignmentItemId',
  'siteLocationId',
  'name',
  'description',
  'scannedData',
  'receiptNotes',
  'deliveryState',
  'deliveryStatus',
  'auditNotes',
];

export const receiptOptimisticList = [
  'siteLocation',
  'consignmentItem',
  ...receiptWhiteList,
];
