import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash.get';
import set from 'lodash.set';

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  token: '',
  userId: '',
  tenant: '',
  connectionKey: '',
  user: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSet(state, action) {
      const payloadUser = get(action, ['payload', 'user'], {});
      if (payloadUser.id) {
        set(action, ['payload', 'user', 'perms'], {
          administrator: payloadUser.administrator,
          manager: payloadUser.manager,
          receiver: payloadUser.receiver,
          developer:
            payloadUser.email.startsWith('gordon.king') ||
            payloadUser.email.startsWith('developer'),
        });
      }
      return {
        ...initialState,
        ...action.payload,
      };
    },
    authReset() {
      return initialState;
    },
  },
});

export const { authSet, authReset } = authSlice.actions;
export default authSlice.reducer;
