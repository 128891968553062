export const tableIntegers = [];
export const tableFloats = ['epsg2105Northing', 'epsg2105Easting', 'lat', 'lng'];
export const tableIsoStrings = ['finishedAt'];
export const tableWhiteList = [
  'id',
  'inverterId',
  'name',
  'buildStatus',
  'tableType',
  ...tableIntegers,
  ...tableFloats,
  ...tableIsoStrings,
  'geom',
  'finishedOn',
];
export const tableOptimisticList = [...tableWhiteList, 'inverter'];
