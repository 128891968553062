import {
  supplierCatalogQuery,
  supplierCatalogListQuery,
} from '../graphql/supplier_catalog_queries';
import cleanCache from './clean_cache';

export const supplierCatalogList = (client, listData) => {
  listData.map((supplierCatalog) => {
    const data = { supplierCatalog };
    return client.writeQuery({
      query: supplierCatalogQuery,
      variables: { id: supplierCatalog.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const supplierCatalogCreate = (
  cache,
  { data: { supplierCatalogCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: supplierCatalogListQuery });
  } catch {
    cacheData = { supplierCatalogList: [] };
  }
  if (!cacheData) {
    cacheData = { supplierCatalogList: [] };
  }
  cache.writeQuery({
    query: supplierCatalogListQuery,
    data: { supplierCatalogList: [...cacheData.supplierCatalogList, createData] },
  });
  const data = { supplierCatalog: createData };
  cache.writeQuery({
    query: supplierCatalogQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const supplierCatalogUpdate = (
  cache,
  { data: { supplierCatalogUpdate: updateData } }
) => {
  const data = { supplierCatalog: updateData };
  cache.writeQuery({
    query: supplierCatalogQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const supplierCatalogDelete = (
  cache,
  { data: { supplierCatalogDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: supplierCatalogListQuery });
  } catch {
    cacheData = { supplierCatalogList: [] };
  }
  if (!cacheData) {
    cacheData = { supplierCatalogList: [] };
  }
  const data = {
    supplierCatalogList: cacheData.supplierCatalogList.filter(
      (supplierCatalog) => deleteData.id !== supplierCatalog.id
    ),
  };
  cache.writeQuery({ query: supplierCatalogListQuery, data });
  cache.evict({ fieldName: 'supplierCatalog', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
