import { useMutation } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';

import ReactTable from '../../components/react_table/react_table';

import { picklistTemplateProductDelete as picklistTemplateProductDeleteMutation } from '../../graphql/picklist_template_product_queries';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import * as updateFunctions from '../../update_functions';

const PicklistTemplateProductList = (props) => {
  const {
    currentUser,
    picklistTemplateProducts,
    settingsTenant,
    settingsOnline,
    onDelete,
    onEdit,
  } = props;

  const dispatch = useDispatch();
  const [picklistTemplateProductDelete] = useMutation(
    picklistTemplateProductDeleteMutation
  );

  const picklistTemplateProductEditClicked = (picklistTemplateProductId) => {
    onEdit(picklistTemplateProductId);
  };

  const picklistTemplateProductDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const picklistTemplateProductId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: picklistTemplateProductId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'PicklistTemplateProductType',
        recordId: picklistTemplateProductId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.picklistTemplateProductDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'picklistTemplateProductDelete',
      mutationData,
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await picklistTemplateProductDelete(mutationData);
        toastSuccess('Product Assembly Link delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Product Assembly Link delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onDelete();
      }
    } else {
      picklistTemplateProductDelete(mutationData);
      toastWarning(
        `Product Assembly Link delete ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onDelete();
    }
  };

  const getProductName = (row) => {
    const {
      product: { name: productName },
    } = row;
    return productName;
  };

  const parentColumns = [
    {
      header: 'Product Assembly',
      id: 'product',
      accessorFn: (row) => getProductName(row),
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
  ];

  return (
    <ReactTable
      rootName="picklistTemplateProduct"
      parentColumns={parentColumns}
      data={picklistTemplateProducts}
      doShow={false}
      handleEdit={picklistTemplateProductEditClicked}
      doDelete={currentUser?.perms?.administrator}
      handleDelete={picklistTemplateProductDeleteClicked}
    />
  );
};

export default PicklistTemplateProductList;
