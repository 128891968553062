export const wbsCodeSchema = {
  Prefix: {
    prop: 'prefix',
    type: String,
    required: true,
  },
  'Category Code': {
    prop: 'categoryCode',
    type: String,
    required: true,
  },
  'Task Code': {
    prop: 'taskCode',
    type: String,
    required: true,
  },
  'Activity Code': {
    prop: 'activityCode',
    type: String,
    required: true,
  },
  Category: {
    prop: 'categoryName',
    type: String,
    required: true,
  },
  Task: {
    prop: 'taskName',
    type: String,
    required: true,
  },
  Activity: {
    prop: 'activityName',
    type: String,
    required: true,
  },
};
