import { receiptQuery, receiptListQuery } from '../graphql/receipt_queries';
import cleanCache from './clean_cache';

export const receiptList = (client, listData) => {
  listData.map((receipt) => {
    const data = { receipt };
    return client.writeQuery({
      query: receiptQuery,
      variables: { id: receipt.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const receiptCreate = (cache, { data: { receiptCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: receiptListQuery });
  } catch {
    cacheData = { receiptList: [] };
  }
  if (!cacheData) {
    cacheData = { receiptList: [] };
  }
  cache.writeQuery({
    query: receiptListQuery,
    data: { receiptList: [...cacheData.receiptList, createData] },
  });
  const data = { receipt: createData };
  cache.writeQuery({
    query: receiptQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const receiptUpdate = (cache, { data: { receiptUpdate: updateData } }) => {
  const data = { receipt: updateData };
  cache.writeQuery({
    query: receiptQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const receiptDelete = (cache, { data: { receiptDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: receiptListQuery });
  } catch {
    cacheData = { receiptList: [] };
  }
  if (!cacheData) {
    cacheData = { receiptList: [] };
  }
  const data = {
    receiptList: cacheData.receiptList.filter((receipt) => deleteData.id !== receipt.id),
  };
  cache.writeQuery({ query: receiptListQuery, data });
  cache.evict({ fieldName: 'receipt', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
