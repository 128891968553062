const wbsCodeFormSchema = {
  type: 'object',
  required: ['prefix', 'code', 'name'],
  properties: {
    prefix: { type: 'string', minLength: 1, maxLength: 10 },
    code: { type: 'string', minLength: 2, maxLength: 2 },
    name: { type: 'string', minLength: 1, maxLength: 255 },
    parentId: { type: 'integer', nullable: true },
  },
};

export { wbsCodeFormSchema };
