import { useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import OnChangeField from '../components/form/rff_on_change_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  supplierCatalogItemFormPageQuery,
  supplierCatalogItemCreate as supplierCatalogItemCreateMutation,
  supplierCatalogItemUpdate as supplierCatalogItemUpdateMutation,
} from '../graphql/supplier_catalog_item_queries';
import * as updateFunctions from '../update_functions';
import { supplierCatalogItemDefaultValues } from '../defaults';
import { supplierCatalogItemFormValidator } from '../validators';
import { supplierCatalogItemWhiteList } from '../white_lists';

const SupplierCatalogItemForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [supplierCatalogItemCreate] = useMutation(supplierCatalogItemCreateMutation);
  const [supplierCatalogItemUpdate] = useMutation(supplierCatalogItemUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    // refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(supplierCatalogItemFormPageQuery, {
    variables: {
      hasSupplierCatalogItemId: !!params.id,
      supplierCatalogItemId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.supplierCatalogItem) {
      const values = pickValues(
        pageData.supplierCatalogItem,
        supplierCatalogItemWhiteList
      );
      return values;
    }
    return supplierCatalogItemDefaultValues;
  }, [pageData]);

  const onCancel = () => {
    navigate('/supplier_catalog_items');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'SupplierCatalogItemType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = supplierCatalogItemUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.supplierCatalogItemUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'supplierCatalogItemUpdate',
        mutationData,
        currentData: pageData.supplierCatalogItem,
      });
    } else {
      mutation = supplierCatalogItemCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.supplierCatalogItemCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'supplierCatalogItemCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Supplier Catalog Item ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/supplier_catalog_items');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Supplier Catalog Item ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/supplier_catalog_items');
    }
    return undefined;
  };

  const recalculateCatalogItemPricePerUnitOfMeasure = (form, values) => {
    let newCatalogItemPricePerUnitOfMeasure = '0';
    const { catalogItemQuantityPerUnitOfMeasure, catalogItemUnitPrice } = values;
    if (
      parseFloat(catalogItemQuantityPerUnitOfMeasure) &&
      parseFloat(catalogItemUnitPrice)
    ) {
      newCatalogItemPricePerUnitOfMeasure =
        Math.round(
          parseFloat(catalogItemQuantityPerUnitOfMeasure) *
            parseFloat(catalogItemUnitPrice) *
            100
        ) / 100;
    }
    form.change('catalogItemPricePerUnitOfMeasure', newCatalogItemPricePerUnitOfMeasure);
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">
                {params.id ? 'Edit Supplier Catalog Item' : 'New Supplier Catalog Item'}
              </h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/supplier_catalog_items">
                <Button className="me-2" variant="primary">
                  All Supplier Catalog Items
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={supplierCatalogItemFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting, form, values }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <OnChangeField name="catalogItemQuantityPerUnitOfMeasure">
                      {() => recalculateCatalogItemPricePerUnitOfMeasure(form, values)}
                    </OnChangeField>
                    <OnChangeField name="catalogItemUnitPrice">
                      {() => recalculateCatalogItemPricePerUnitOfMeasure(form, values)}
                    </OnChangeField>
                    <Field
                      name="supplierCatalogId"
                      component={InputField}
                      asElement="select"
                      selectOptions={get(pageData, 'supplierCatalogList', [])}
                      defaultSelectOptionText="select supplier catalog..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Supplier Catalog
                    </Field>
                    <Field
                      name="manufacturerId"
                      component={InputField}
                      asElement="select"
                      selectOptions={get(pageData, 'manufacturerList', [])}
                      defaultSelectOptionText="select manufacturer..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Manufacturer
                    </Field>
                    <Field
                      name="modelPartNumber"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Model/Part Number
                    </Field>
                    <Field
                      name="description"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Description
                    </Field>
                    <Field
                      name="catalogItemUnitOfMeasure"
                      component={InputField}
                      asElement="select"
                      selectOptions={Object.values(
                        get(pageData, 'enums.enums.CatalogItemUnitOfMeasures', [])
                      ).map((type) => ({
                        id: type,
                        name: type,
                      }))}
                      defaultSelectOptionText="select unit of measure..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Unit of Measure
                    </Field>
                    <Field
                      name="catalogItemQuantityPerUnitOfMeasure"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={2}
                      size="lg"
                    >
                      Quantity per Unit of Measure
                    </Field>
                    {currentUser.perms.developer && (
                      <>
                        <Field
                          name="catalogItemCurrency"
                          component={InputField}
                          asElement="select"
                          selectOptions={Object.values(
                            get(pageData, 'enums.enums.CatalogItemCurrency', [])
                          ).map((type) => ({
                            id: type,
                            name: type,
                          }))}
                          defaultSelectOptionText="select currency..."
                          labelWidth={4}
                          inputWidth={4}
                          size="lg"
                        >
                          Currency
                        </Field>
                        <Field
                          name="catalogItemUnitPrice"
                          component={InputField}
                          labelWidth={4}
                          inputWidth={2}
                          size="lg"
                          helpText="The smallest item price"
                        >
                          Unit Price ($0.00)
                        </Field>
                        <Field
                          name="catalogItemPricePerUnitOfMeasure"
                          component={InputField}
                          labelWidth={4}
                          inputWidth={2}
                          size="lg"
                          helpText="The price for the box, bundle, bag etc"
                        >
                          Price Unit of Measure ($0.00)
                        </Field>
                        <Field
                          name="catalogItemImportedMergedPrice"
                          component={InputField}
                          labelWidth={4}
                          inputWidth={2}
                          size="lg"
                        >
                          Price (Imported as Merged)
                        </Field>
                      </>
                    )}
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SupplierCatalogItemForm;
