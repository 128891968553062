import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';

import Field from '../../components/form/rff_field';
import InputField from '../../components/form/input_field';
import SubmitButtonSpinner from '../../components/submit_button_spinner';
import { goodsReceiptFormValidator } from '../../validators';

function ManualReceiptFormModal(props) {
  const {
    show,
    onCancel,
    onComplete,
    siteLocations,
    lastScannedName,
    lastScannedSiteLocationId,
  } = props;

  const onFormSubmit = async (data) => {
    onComplete(data);
  };

  return (
    <Modal
      id="site-location-form"
      show={show}
      onHide={onCancel}
      centered
      size="xl"
      animation={false}
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={{
          name: lastScannedName,
          siteLocationId: lastScannedSiteLocationId || '',
        }}
        validate={goodsReceiptFormValidator}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Update consignment location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Field
                name="siteLocationId"
                component={InputField}
                asElement="select"
                selectOptions={siteLocations}
                defaultSelectOptionText="select site location..."
                labelWidth={4}
                inputWidth={4}
                size="lg"
              >
                Site Location
              </Field>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup className="me-2">
                  <Button variant="danger" onClick={onCancel} disabled={submitting}>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    Update
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default ManualReceiptFormModal;
