import { useMutation } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';

import ReactTable from '../../components/react_table/react_table';

import { supplierCatalogItemDelete as supplierCatalogItemDeleteMutation } from '../../graphql/supplier_catalog_item_queries';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import * as updateFunctions from '../../update_functions';

const SupplierCatalogItemList = (props) => {
  const {
    currentUser,
    supplierCatalogItems,
    settingsTenant,
    settingsOnline,
    onDelete,
    onEdit,
  } = props;

  const dispatch = useDispatch();
  const [supplierCatalogItemDelete] = useMutation(supplierCatalogItemDeleteMutation);

  const supplierCatalogItemEditClicked = (supplierCatalogItemId) => {
    onEdit(supplierCatalogItemId);
  };

  const supplierCatalogItemDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const supplierCatalogItemId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: supplierCatalogItemId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'SupplierCatalogItemType',
        recordId: supplierCatalogItemId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.supplierCatalogItemDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'supplierCatalogItemDelete',
      mutationData
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await supplierCatalogItemDelete(mutationData);
        toastSuccess('Supplier Catalog Item delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Supplier Catalog Item delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onDelete();
      }
    } else {
      supplierCatalogItemDelete(mutationData);
      toastWarning(
        `Supplier Catalog Item delete ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onDelete();
    }
  };

  // id: ID
  // supplierCatalogId: ID!
  // manufacturerId: ID!
  // description: String!
  // modelPartNumber: String!
  // catalogItemUnitOfMeasure: String!
  // catalogItemQuantityPerUnitOfMeasure: Int!
  // catalogItemQuantityPerUnitOfMeasure
  // catalogItemCurrency: String!
  // catalogItemUnitPrice: Float!

  const getManufacturerName = (row) => {
    const {
      manufacturer: { name: manufacturerName },
    } = row;
    return manufacturerName;
  };

  const parentColumns = [
    {
      header: 'Manufacturer',
      id: 'supplier',
      accessorFn: (row) => getManufacturerName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Model/Part #',
      accessorKey: 'modelPartNumber',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Unit of Measure',
      accessorKey: 'catalogItemUnitOfMeasure',
      filterType: 'dropdown',
    },
    {
      header: 'Qty Unit of Measure',
      accessorKey: 'catalogItemQuantityPerUnitOfMeasure',
      enableColumnFilter: false,
    },
    {
      header: 'Currency',
      accessorKey: 'catalogItemCurrency',
      filterType: 'dropdown',
    },
    {
      header: 'Unit Price',
      accessorKey: 'catalogItemUnitPrice',
      enableColumnFilter: false,
    },
    {
      header: 'Price Unit of Measure',
      accessorKey: 'catalogItemPricePerUnitOfMeasure',
      enableColumnFilter: false,
    },
  ];

  return (
    <ReactTable
      rootName="supplierCatalogItem"
      parentColumns={parentColumns}
      data={supplierCatalogItems}
      doShow={false}
      handleEdit={supplierCatalogItemEditClicked}
      doDelete={currentUser?.perms?.administrator}
      handleDelete={supplierCatalogItemDeleteClicked}
    />
  );
};

export default SupplierCatalogItemList;
