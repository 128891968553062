const userFormSchema = {
  type: 'object',
  required: ['email', 'fullName'],
  properties: {
    email: { type: 'string', format: 'email' },
    fullName: { type: 'string', minLength: 6, maxLength: 255 },
    mobilePhone: { type: 'string', minLength: 6, maxLength: 12 },
  },
  allOf: [
    {
      if: {
        anyOf: [
          {
            not: {
              required: ['id'],
            },
          },
          {
            required: ['password'],
          },
          {
            required: ['passwordConfirmation'],
          },
        ],
      },
      then: {
        allOf: [
          {
            required: ['password', 'passwordConfirmation'],
            properties: {
              password: {
                type: 'string',
                minLength: 6,
                maxLength: 255,
              },
              passwordConfirmation: {
                type: 'string',
                const: {
                  $data: '1/password',
                },
                errorMessage: {
                  const: 'should match password',
                },
              },
            },
          },
        ],
      },
    },
  ],
};

export { userFormSchema };
