import { createClient } from 'graphql-ws';
import { getIdToken } from './local_storage';

const subscriptionClient = createClient({
  url: `${process.env.EXPRESS_WS_URL}/subscriptions`,
  connectionParams: () => ({
    token: getIdToken() && `${getIdToken()}`,
  }),
  lazy: false,
  shouldRetry: () => true,
  retryAttempts: Infinity,
  retryWait: async (retries) => {
    const maxRetryDelay = 32000;
    const retryDelay = Math.min(maxRetryDelay, 1000 * 2 ** retries);
    const random = Math.floor(Math.random() * (3000 - 300) + 300);
    await new Promise((resolve) => {
      setTimeout(resolve, retryDelay + random);
    });
  },
  // on: {
  //   connecting: () => console.log('connecting'),
  //   opened: (socket) => console.log('opened', socket),
  //   connected: (socket) => console.log('connected', socket),
  //   closed: (resp) => console.log('closed', resp),
  //   error: (resp) => console.log('error', resp),
  //   message: (resp) => console.log('message', resp),
  // },
});

export default subscriptionClient;
