export const pileIntegers = ['hammeringTime'];
export const pileFloats = ['epsg2105Northing', 'epsg2105Easting', 'lat', 'lng'];
export const pileIsoStrings = ['finishedAt'];
export const pileWhiteList = [
  'id',
  'tableId',
  'pilingMachineId',
  'name',
  'naskuRef',
  'naskuProjectRef',
  'hammeringStatus',
  ...pileIntegers,
  ...pileFloats,
  ...pileIsoStrings,
  'geom',
  'finishedOn',
];
export const pileOptimisticList = [...pileWhiteList, 'table', 'pilingMachine'];
