import {
  supplierCatalogItemQuery,
  supplierCatalogItemListQuery,
} from '../graphql/supplier_catalog_item_queries';
import cleanCache from './clean_cache';

export const supplierCatalogItemList = (client, listData) => {
  listData.map((supplierCatalogItem) => {
    const data = { supplierCatalogItem };
    return client.writeQuery({
      query: supplierCatalogItemQuery,
      variables: { id: supplierCatalogItem.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const supplierCatalogItemCreate = (
  cache,
  { data: { supplierCatalogItemCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: supplierCatalogItemListQuery });
  } catch {
    cacheData = { supplierCatalogItemList: [] };
  }
  if (!cacheData) {
    cacheData = { supplierCatalogItemList: [] };
  }
  cache.writeQuery({
    query: supplierCatalogItemListQuery,
    data: { supplierCatalogItemList: [...cacheData.supplierCatalogItemList, createData] },
  });
  const data = { supplierCatalogItem: createData };
  cache.writeQuery({
    query: supplierCatalogItemQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const supplierCatalogItemUpdate = (
  cache,
  { data: { supplierCatalogItemUpdate: updateData } }
) => {
  const data = { supplierCatalogItem: updateData };
  cache.writeQuery({
    query: supplierCatalogItemQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const supplierCatalogItemDelete = (
  cache,
  { data: { supplierCatalogItemDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: supplierCatalogItemListQuery });
  } catch {
    cacheData = { supplierCatalogItemList: [] };
  }
  if (!cacheData) {
    cacheData = { supplierCatalogItemList: [] };
  }
  const data = {
    supplierCatalogItemList: cacheData.supplierCatalogItemList.filter(
      (supplierCatalogItem) => deleteData.id !== supplierCatalogItem.id
    ),
  };
  cache.writeQuery({ query: supplierCatalogItemListQuery, data });
  cache.evict({
    fieldName: 'supplierCatalogItem',
    args: { id: deleteData.id.toString() },
  });
  cleanCache(cache);
};
