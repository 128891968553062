import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

import { localStorageReduxPersistKey } from '../lib/local_storage';
import authSlice from './auth_slice';
import settingsSlice from './settings_slice';

const rootReducer = combineReducers({
  auth: authSlice,
  settings: settingsSlice,
});

const persistConfig = {
  key: localStorageReduxPersistKey,
  keyPrefix: '',
  storage,
  whitelist: ['settings'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
    if (process.env.NODE_ENV !== 'production') {
      middleware.push(logger);
    }
    return middleware;
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export const reduxPersistor = persistStore(store);
