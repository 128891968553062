import { useState, useMemo, useCallback } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';
import SupplierCatalogItemFormModal from './supplier_catalog_form/supplier_catalog_item_form_modal';
import SupplierCatalogItemList from './supplier_catalog_form/supplier_catalog_item_list';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  supplierCatalogFormPageQuery,
  supplierCatalogCreate as supplierCatalogCreateMutation,
  supplierCatalogUpdate as supplierCatalogUpdateMutation,
} from '../graphql/supplier_catalog_queries';
import * as updateFunctions from '../update_functions';
import { supplierCatalogDefaultValues } from '../defaults';
import { supplierCatalogFormValidator } from '../validators';
import { supplierCatalogWhiteList } from '../white_lists';

const SupplierCatalogForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [showSupplierCatalogItemFormModal, setShowSupplierCatalogItemFormModal] =
    useState(false);
  const [selectedSupplierCatalogItem, setSelectedSupplierCatalogItem] = useState({});
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [supplierCatalogCreate] = useMutation(supplierCatalogCreateMutation);
  const [supplierCatalogUpdate] = useMutation(supplierCatalogUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(supplierCatalogFormPageQuery, {
    variables: {
      hasSupplierCatalogId: !!params.id,
      supplierCatalogId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.supplierCatalog) {
      const values = pickValues(pageData.supplierCatalog, supplierCatalogWhiteList);
      return values;
    }
    return supplierCatalogDefaultValues;
  }, [pageData]);

  const handleSupplierCatalogItemEditClicked = useCallback(
    (supplierCatalogItemId) => {
      const supplierCatalogItems = get(
        pageData,
        'supplierCatalog.supplierCatalogItems',
        []
      );
      const supplierCatalogItem = supplierCatalogItems.find(
        (sci) => sci.id === parseInt(supplierCatalogItemId, 10)
      );
      if (supplierCatalogItem) {
        setSelectedSupplierCatalogItem(supplierCatalogItem);
        setShowSupplierCatalogItemFormModal(true);
      }
      // );
    },
    [pageData]
  );

  const onCancel = () => {
    navigate('/supplier_catalogs');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'SupplierCatalogType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = supplierCatalogUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.supplierCatalogUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'supplierCatalogUpdate',
        mutationData,
        currentData: pageData.supplierCatalog,
      });
    } else {
      mutation = supplierCatalogCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.supplierCatalogCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'supplierCatalogCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Supplier Catalog ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/supplier_catalogs');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Supplier Catalog ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/supplier_catalogs');
    }
    return undefined;
  };

  const handleSupplierCatalogItemFormModalClick = () => {
    setShowSupplierCatalogItemFormModal(true);
  };

  const handleSupplierCatalogItemFormModalCancel = () => {
    setShowSupplierCatalogItemFormModal(false);
    setSelectedSupplierCatalogItem({});
  };

  const handleSupplierCatalogItemFormModalComplete = () => {
    pageRefetch();
    setShowSupplierCatalogItemFormModal(false);
    setSelectedSupplierCatalogItem({});
  };

  const renderContent = () => (
    <>
      {params.id && (
        <SupplierCatalogItemFormModal
          show={showSupplierCatalogItemFormModal}
          enums={get(pageData, ['enums'])}
          manufacturers={get(pageData, ['manufacturerList'])}
          supplierCatalogId={get(pageData, ['supplierCatalog', 'id'])}
          supplierCatalogName={get(pageData, ['supplierCatalog', 'name'])}
          supplierName={get(pageData, ['supplierCatalog', 'supplier', 'name'])}
          onCancel={handleSupplierCatalogItemFormModalCancel}
          onComplete={handleSupplierCatalogItemFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
          supplierCatalogItem={selectedSupplierCatalogItem}
        />
      )}
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">
                {params.id ? 'Edit Supplier Catalog' : 'New Supplier Catalog'}
              </h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/supplier_catalogs">
                <Button className="me-2" variant="primary">
                  All Supplier Catalogs
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={supplierCatalogFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      name="name"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Supplier Catalog Name
                    </Field>
                    <Field
                      name="supplierId"
                      component={InputField}
                      asElement="select"
                      selectOptions={get(pageData, 'supplierList', [])}
                      defaultSelectOptionText="select supplier..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Supplier
                    </Field>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Supplier Catalog Items</Card.Header>
            <Card.Body>
              {params.id ? (
                <>
                  <SupplierCatalogItemList
                    supplierCatalogItems={get(
                      pageData,
                      ['supplierCatalog', 'supplierCatalogItems'],
                      []
                    )}
                    currentUser={currentUser}
                    settingsTenant={settingsTenant}
                    settingsOnline={settingsOnline}
                    onEdit={handleSupplierCatalogItemEditClicked}
                    onDelete={() => pageRefetch()}
                  />

                  <Button
                    className="me-2"
                    type="button"
                    variant="primary"
                    onClick={handleSupplierCatalogItemFormModalClick}
                  >
                    Add Supplier Catalog Item
                  </Button>
                </>
              ) : (
                <p>Create a new Supplier Catalog to begin adding Items</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SupplierCatalogForm;
