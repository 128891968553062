import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { Typeahead } from 'react-bootstrap-typeahead';
import arrify from 'arrify';

import curry from 'lodash.curry';
import defaultTo from 'lodash.defaultto';

import Field from '../../components/form/rff_field';
import OnChangeField from '../../components/form/rff_on_change_field';
import InputField from '../../components/form/input_field';
import InvalidBlock from '../../components/form/invalid_block';
import SubmitButtonSpinner from '../../components/submit_button_spinner';
import { goodsReceiptFormValidator } from '../../validators';

function ManualReceiptFormModal(props) {
  const { show, onCancel, onComplete, siteLocations, consignmentItems } = props;

  const onTypeaheadChange = curry((form, name, value) => {
    let formValue = value[0] || null;
    if (formValue && formValue.id) {
      formValue = formValue.id;
    }
    form.change(name, formValue);
  });

  const onConsignmentItemIdChange = (form, values) => {
    let siteLocationId = null;
    let name = null;
    let description = null;
    const consignmentItemId = defaultTo(parseInt(values.consignmentItemId, 10), null);
    if (consignmentItemId) {
      const consignmentItem = consignmentItems.find((ci) => ci.id === consignmentItemId);
      if (consignmentItem) {
        siteLocationId = consignmentItem.siteLocationId;
        name = consignmentItem.consignmentItemReference;
        description = consignmentItem.description;
      }
    }
    form.change('siteLocationId', siteLocationId);
    form.change('name', name);
    form.change('description', description);
  };

  const onFormSubmit = async (data) => {
    onComplete(data);
  };

  return (
    <Modal
      id="manual-receipt-form"
      show={show}
      onHide={onCancel}
      centered
      size="xl"
      animation={false}
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={{}}
        validate={goodsReceiptFormValidator}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting, pristine, form, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Add Manual Goods Receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <fieldset className="border rounded-3 p-3">
                <legend className="float-none w-auto px-3 fs-6">Receipt details</legend>
                <OnChangeField name="consignmentItemId">
                  {() => onConsignmentItemIdChange(form, values)}
                </OnChangeField>
                <Field name="consignmentItemId">
                  {({ input, meta }) => (
                    <InputField
                      size="lg"
                      labelWidth={4}
                      inputWidth={6}
                      input={{
                        name: input.name,
                      }}
                      innerContent={
                        <>
                          <Typeahead
                            id="consignment-item-id-typeahead-single"
                            labelKey="consignmentItemReference"
                            onBlur={input.onBlur}
                            onChange={onTypeaheadChange(form, input.name)}
                            options={consignmentItems}
                            placeholder="select consignment item..."
                            selected={arrify(
                              consignmentItems.find((ci) => ci.id === input.value)
                            )}
                          />
                          <InvalidBlock meta={meta || {}} force />
                        </>
                      }
                    >
                      Consignment Item
                    </InputField>
                  )}
                </Field>
                <Field
                  name="name"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={8}
                  size="lg"
                  disabled={values.consignmentItemId}
                >
                  Consignment Reference
                </Field>
                <Field
                  name="siteLocationId"
                  component={InputField}
                  asElement="select"
                  selectOptions={siteLocations}
                  defaultSelectOptionText="select site location..."
                  labelWidth={4}
                  inputWidth={4}
                  size="lg"
                >
                  Site Location
                </Field>
                <Field
                  name="description"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={8}
                  size="lg"
                >
                  Consignment Description
                </Field>
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup className="me-2">
                  <Button variant="danger" onClick={onCancel} disabled={submitting}>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    Create
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default ManualReceiptFormModal;
