export const picklistSupplierCatalogItemIntegers = [
  'quantity',
  'quantityUnitOfMeasure',
  'quantityRemainder',
];
export const picklistSupplierCatalogItemFloats = [];
export const picklistSupplierCatalogItemIsoStrings = [];
export const picklistSupplierCatalogItemWhiteList = [
  'id',
  'picklistId',
  'supplierCatalogItemId',
  'modelPartNumber',
  'description',
  'catalogItemUnitOfMeasure',
  'catalogItemQuantityPerUnitOfMeasure',
  'quantity',
  'quantityUnitOfMeasure',
  'quantityRemainder',
  'picklistItemNotes',
  'picked',
  'pickedByUserId',
  'pickChecked',
  'pickCheckedByUserId',
];
export const picklistSupplierCatalogItemOptimisticList = [
  ...picklistSupplierCatalogItemWhiteList,
  'picklist',
  'supplierCatalogItem',
  'pickedByUser',
  'pickCheckedByUser',
];
