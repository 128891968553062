import {
  productProductQuery,
  productProductListQuery,
} from '../graphql/product_product_queries';
import cleanCache from './clean_cache';

export const productProductList = (client, listData) => {
  listData.map((productProduct) => {
    const data = { productProduct };
    return client.writeQuery({
      query: productProductQuery,
      variables: { id: productProduct.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const productProductCreate = (
  cache,
  { data: { productProductCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: productProductListQuery });
  } catch {
    cacheData = { productProductList: [] };
  }
  if (!cacheData) {
    cacheData = { productProductList: [] };
  }
  cache.writeQuery({
    query: productProductListQuery,
    data: { productProductList: [...cacheData.productProductList, createData] },
  });
  const data = { productProduct: createData };
  cache.writeQuery({
    query: productProductQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const productProductUpdate = (
  cache,
  { data: { productProductUpdate: updateData } }
) => {
  const data = { productProduct: updateData };
  cache.writeQuery({
    query: productProductQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const productProductDelete = (
  cache,
  { data: { productProductDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: productProductListQuery });
  } catch {
    cacheData = { productProductList: [] };
  }
  if (!cacheData) {
    cacheData = { productProductList: [] };
  }
  const data = {
    productProductList: cacheData.productProductList.filter(
      (productProduct) => deleteData.id !== productProduct.id
    ),
  };
  cache.writeQuery({ query: productProductListQuery, data });
  cache.evict({ fieldName: 'productProduct', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
