import 'whatwg-fetch';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Rollbar from 'rollbar/dist/rollbar.umd';

import get from 'lodash.get';
import keys from 'lodash.keys';
import pick from 'lodash.pick';
import isEqual from 'lodash.isequal';

import { store, reduxPersistor } from './store';
import {
  getIdToken,
  removeIdToken,
  clearLocalStorage,
  apolloTrackedMutationsKey,
} from './lib/local_storage';

// import { processSubscription } from './lib/process_mutation'; // new one don't use
import { processMutationSubscription } from './lib/process_mutation_subscription'; // old one only use for subscriptions
import mutationSubscription from './subscriptions/mutation_subscription';

import {
  restoreApolloCache,
  restoreTrackedMutations,
  hydrateCache,
  apolloClient,
  queueLink,
} from './lib/apollo_client';

import getTenantConfig from './lib/tenant_config';
import LoaderComponent from './components/loader_component';
import { authSet, authReset } from './store/auth_slice';
import { settingsSet } from './store/settings_slice';

import App from './app';
// const App = () => <p>Hello</p>;

let pubsubSubscription = null;

const subscribeMutation = () => {
  if (pubsubSubscription) {
    pubsubSubscription.unsubscribe();
    pubsubSubscription = null;
  }
  pubsubSubscription = apolloClient
    .subscribe({
      query: mutationSubscription,
      variables: {},
      fetchPolicy: 'no-cache',
    })
    .subscribe({
      next(mutation) {
        processMutationSubscription(mutation);
      },
      error(error) {
        console.log('mutationSubscriptionError: ', error);
      },
    });
};

const setOnline = async () => {
  const deviceOnline = window.navigator.onLine;
  let serverOnline;
  if (deviceOnline) {
    try {
      const pingResp = await fetch(`${process.env.EXPRESS_API_URL}/ping`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      const ping = await pingResp.json();
      if (ping.pong) {
        serverOnline = true;
      } else {
        serverOnline = false;
      }
    } catch (err) {
      console.log(err);
      serverOnline = false;
    }
  } else {
    serverOnline = false;
  }
  const online = deviceOnline && serverOnline;
  const newState = {
    online,
    deviceOnline,
    serverOnline,
  };
  const currentState = pick(get(store.getState(), ['settings']), [
    'online',
    'deviceOnline',
    'serverOnline',
  ]);
  if (!isEqual(newState, currentState)) {
    store.dispatch(settingsSet(newState));
  }
  if (!currentState.online && newState.online) {
    queueLink.open();
    subscribeMutation();
  }
  if (currentState.online && !newState.online) {
    queueLink.close();
  }
  // console.log({ online, deviceOnline, serverOnline });
  setTimeout(setOnline, 15000);
};
await setOnline();

const checkVersion = async () => {
  const currentState = pick(get(store.getState(), ['settings']), [
    'online',
    'versionCurrent',
  ]);
  const { versionCurrent: settingsVersionCurrent } = currentState;
  let versionCurrent = true;
  try {
    const currentVersionResp = await fetch(`${window.location.origin}/version.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    const response = await currentVersionResp.json();
    let versionFound = false;
    let version = null;
    if (response && response.clientVersion) {
      versionFound = true;
      version = response.clientVersion;
    }
    if (versionFound && version && window.$CLIENT_VERSION) {
      versionCurrent = version === window.$CLIENT_VERSION;
    }
  } catch (err) {
    versionCurrent = true;
  }
  if (settingsVersionCurrent !== versionCurrent) {
    store.dispatch(
      settingsSet({
        versionCurrent,
      })
    );
  }
  setTimeout(checkVersion, 60000);
};
await checkVersion();

const setTrackedMutationCount = async () => {
  const trackedMutations =
    JSON.parse(localStorage.getItem(apolloTrackedMutationsKey) || null) || {};
  const trackedMutationCount = keys(trackedMutations).length;
  const newState = {
    trackedMutationCount,
  };
  const currentState = pick(get(store.getState(), ['settings']), [
    'trackedMutationCount',
  ]);
  if (!isEqual(newState, currentState)) {
    store.dispatch(settingsSet(newState));
  }
  setTimeout(setTrackedMutationCount, 5000);
};
await setTrackedMutationCount();

const setTenant = () => {
  const tenantConfig = getTenantConfig(window.$CLIENT_TENANT);
  store.dispatch(settingsSet(tenantConfig));
};
setTenant();

if (process.env.NODE_ENV === 'production') {
  const rollbarConfig = {
    accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN, // eslint-disable-line no-undef
    captureUncaught: true,
    payload: {
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.GIT_REVISION, // eslint-disable-line no-undef
          guess_uncaught_frames: true,
        },
      },
    },
  };
  window.Rollbar = new Rollbar(rollbarConfig);
}

async function clearCaches() {
  console.log('clear caches');
  const cacheKeys = await caches.keys();
  console.log(cacheKeys);
  // for (const key of cacheKeys) {
  //   // await caches.delete(key);
  //   console.log(key);
  // }
}

await clearCaches();

const registerServiceWorker = () => {
  navigator.serviceWorker
    .register('/sw.js')
    .then(() => {
      console.log('SW registered');
    })
    .catch((registrationError) => {
      window.Rollbar.info('Failed SW registration', registrationError);
    });
};

if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    if (document.readyState === 'complete') {
      registerServiceWorker();
    } else {
      window.addEventListener('load', () => {
        registerServiceWorker();
      });
    }
  } else {
    window.Rollbar.info('Missing SW in Navigator');
  }
}

const restoreAuth = async () => {
  const token = getIdToken();
  try {
    const decoded = jwtDecode(token);
    store.dispatch(
      authSet({
        isAuthenticating: false,
        isAuthenticated: true,
        token,
        user: decoded.user,
        userId: decoded.user.id,
        tenant: decoded.tenant,
        connectionKey: decoded.connectionKey,
      })
    );
    return true;
  } catch (err) {
    // console.log(err);
    removeIdToken();
    await clearLocalStorage();
    store.dispatch(authReset());
    return false;
  }
};

const authed = await restoreAuth();

if (authed) {
  await restoreApolloCache();
  await restoreTrackedMutations();
  await hydrateCache();
}

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);

const initApp = async (InitApp) => (
  <Provider store={store}>
    <PersistGate loading={<LoaderComponent />} persistor={reduxPersistor}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <InitApp />
        </BrowserRouter>
      </ApolloProvider>
    </PersistGate>
  </Provider>
);

initApp(App).then((app) => root.render(app));
