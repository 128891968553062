import { picklistQuery, picklistListQuery } from '../graphql/picklist_queries';
import cleanCache from './clean_cache';

export const picklistList = (client, listData) => {
  listData.map((picklist) => {
    const data = { picklist };
    return client.writeQuery({
      query: picklistQuery,
      variables: { id: picklist.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const picklistCreate = (cache, { data: { picklistCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistListQuery });
  } catch {
    cacheData = { picklistList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistList: [] };
  }
  cache.writeQuery({
    query: picklistListQuery,
    data: { picklistList: [...cacheData.picklistList, createData] },
  });
  const data = { picklist: createData };
  cache.writeQuery({
    query: picklistQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistUpdate = (cache, { data: { picklistUpdate: updateData } }) => {
  const data = { picklist: updateData };
  cache.writeQuery({
    query: picklistQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistDelete = (cache, { data: { picklistDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistListQuery });
  } catch {
    cacheData = { picklistList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistList: [] };
  }
  const data = {
    picklistList: cacheData.picklistList.filter(
      (picklist) => deleteData.id !== picklist.id
    ),
  };
  cache.writeQuery({ query: picklistListQuery, data });
  cache.evict({ fieldName: 'picklist', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
