export const supplierCatalogItemIntegers = ['catalogItemQuantityPerUnitOfMeasure'];
export const supplierCatalogItemFloats = [
  'catalogItemUnitPrice',
  'catalogItemPricePerUnitOfMeasure',
  'catalogItemImportedMergedPrice',
];
export const supplierCatalogItemIsoStrings = [];
export const supplierCatalogItemWhiteList = [
  'id',
  'supplierCatalogId',
  'manufacturerId',
  'modelPartNumber',
  'description',
  'catalogItemUnitOfMeasure',
  'catalogItemQuantityPerUnitOfMeasure',
  'catalogItemCurrency',
  'catalogItemUnitPrice',
  'catalogItemPricePerUnitOfMeasure',
  'catalogItemImportedMergedPrice',
  'importHash',
];
export const supplierCatalogItemOptimisticList = [
  ...supplierCatalogItemWhiteList,
  'supplierCatalog',
  'manufacturer',
];
