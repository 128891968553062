import { useMemo, useCallback, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import objectHash from 'object-hash';

import cloneDeep from 'lodash.clonedeep';
import difference from 'lodash.difference';
import get from 'lodash.get';
import pick from 'lodash.pick';
import uniq from 'lodash.uniq';

import { bomSchema } from '../lib/importers/bom_schema';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';

import { bomImportFormPageQuery } from '../graphql/import_queries';
import { productCategoryCreate as productCategoryCreateMutation } from '../graphql/product_category_queries';
import { purchaserCreate as purchaserCreateMutation } from '../graphql/purchaser_queries';
import { manufacturerCreate as manufacturerCreateMutation } from '../graphql/manufacturer_queries';
import { supplierCreate as supplierCreateMutation } from '../graphql/supplier_queries';
import { supplierCatalogCreate as supplierCatalogCreateMutation } from '../graphql/supplier_catalog_queries';
import { supplierCatalogItemCreate as supplierCatalogItemCreateMutation } from '../graphql/supplier_catalog_item_queries';
import * as updateFunctions from '../update_functions';

const BomImportForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [fileError, setFileError] = useState('');
  const [fileRows, setFileRows] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const [projectCode, setProjectCode] = useState('');
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [productCategoryCreate] = useMutation(productCategoryCreateMutation);
  const [purchaserCreate] = useMutation(purchaserCreateMutation);
  const [manufacturerCreate] = useMutation(manufacturerCreateMutation);
  const [supplierCreate] = useMutation(supplierCreateMutation);
  const [supplierCatalogCreate] = useMutation(supplierCatalogCreateMutation);
  const [supplierCatalogItemCreate] = useMutation(supplierCatalogItemCreateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(bomImportFormPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const uomConversion = useMemo(
    () => ({
      pcs: get(pageData, 'enums.enums.CatalogItemUnitOfMeasures.PIECE'),
      m: get(pageData, 'enums.enums.CatalogItemUnitOfMeasures.METER'),
    }),
    [pageData]
  );

  const handleFileChange = useCallback(
    async (name, onChange, e) => {
      const file = get(e, 'target.files.0');
      if (file) {
        const newProjectCode = get(file.name.match(/^(\d+)\s*|-.+/), '1');
        if (newProjectCode) {
          setFileError('');
          setProjectCode(newProjectCode);
          const sheetNames = await readSheetNames(file);
          try {
            if (sheetNames.includes('BOM')) {
              const { rows, errors = [] } = await readXlsxFile(file, {
                schema: bomSchema,
                sheet: 'BOM',
              });
              let sharedCatalogItemImportedMergedPrice = 0;
              let sharedMergedCurrency;
              const newFileRows = rows.map((row, index) => {
                const newRow = cloneDeep(row);
                const rowIndex = index + 2;
                const newCatalogItemUnitOfMeasure =
                  uomConversion[newRow.catalogItemUnitOfMeasure];
                if (!newCatalogItemUnitOfMeasure) {
                  throw new Error(
                    `Spreadsheet row must include a convertable unit of measure. "${newRow.catalogItemUnitOfMeasure}" unknown`
                  );
                }
                // defaults
                newRow.catalogItemUnitOfMeasure = newCatalogItemUnitOfMeasure;
                newRow.productCategory =
                  newRow.productCategory || 'Unknown Product Category';
                newRow.supplier = newRow.supplier || 'Unknown Supplier';
                newRow.supplierCatalog = `${newRow.supplier} / ${newProjectCode}`;
                newRow.manufacturer = newRow.manufacturer || 'Unknown Manufacturer';
                newRow.purchaser = newRow.purchaser || 'Unknown Purchaser';
                newRow.modelPartNumber =
                  newRow.modelPartNumber || 'Unknown Model/PartNumber';

                newRow.quantity = Math.round(newRow.quantity || 0);
                newRow.oandmSpares = Math.round(newRow.oandmSpares || 0);
                newRow.constructionSpares = Math.round(newRow.constructionSpares || 0);
                newRow.contingency = newRow.oandmSpares + newRow.constructionSpares;
                newRow.totalQty = newRow.quantity + newRow.contingency;

                newRow.catalogItemCurrency =
                  newRow.catalogItemCurrency ||
                  get(pageData, 'enums.enums.CatalogItemCurrency.UNKNOWN');

                newRow.catalogItemImportedMergedPrice = 0;
                newRow.catalogItemUnitPrice =
                  Math.round((newRow.catalogItemUnitPrice || 0) * 100) / 100;

                // handle merged cells in the price column
                // Check the price column actually is merged, and its not off in another unimported row
                // todo this is picking up see row index 118 29.07 is being used for non merged
                const nextRowIndex = rowIndex + 1;
                const rowPriceError = errors.find(
                  (error) =>
                    error.row === rowIndex &&
                    error.column === 'Price (Exc GST)' &&
                    error.error === 'required'
                );
                const nextRowPriceError = errors.find(
                  (error) =>
                    error.row === nextRowIndex &&
                    error.column === 'Price (Exc GST)' &&
                    error.error === 'required'
                );
                if (newRow.catalogItemUnitPrice && !rowPriceError && nextRowPriceError) {
                  sharedCatalogItemImportedMergedPrice = newRow.catalogItemUnitPrice;
                  newRow.catalogItemImportedMergedPrice = newRow.catalogItemUnitPrice;
                  newRow.catalogItemUnitPrice = 0;
                  if (newRow.catalogItemCurrency) {
                    sharedMergedCurrency = newRow.catalogItemCurrency;
                  }
                }
                if (
                  sharedCatalogItemImportedMergedPrice &&
                  !newRow.catalogItemUnitPrice &&
                  rowPriceError
                ) {
                  newRow.catalogItemImportedMergedPrice =
                    sharedCatalogItemImportedMergedPrice;
                  newRow.catalogItemUnitPrice = 0;
                  if (sharedMergedCurrency) {
                    newRow.catalogItemCurrency = sharedMergedCurrency;
                  }
                }
                if (
                  sharedCatalogItemImportedMergedPrice &&
                  newRow.catalogItemUnitPrice &&
                  !nextRowPriceError
                ) {
                  sharedCatalogItemImportedMergedPrice = 0;
                  if (sharedMergedCurrency) {
                    sharedMergedCurrency = undefined;
                  }
                }
                const hash = objectHash(newRow);
                newRow.importHash = hash;
                newRow.rowIndex = rowIndex;
                return newRow;
              });
              setFileError('');
              setFileRows(newFileRows);
              setFileErrors(errors);
              onChange(file);
            } else {
              setFileError('Spreadsheet must include a sheet titled "BOM"');
              setFileRows([]);
              setFileErrors([]);
            }
          } catch (err) {
            setFileError(err.message);
            setFileRows([]);
            setFileErrors([]);
          }
        } else {
          setFileError('Spreadsheet name must begin with a project code');
          setProjectCode('');
          setFileRows([]);
          setFileErrors([]);
        }
      }
    },
    [pageData, uomConversion]
  );

  const onCancel = () => {
    navigate('/');
  };

  const onFormSubmit = async () => {
    const existingProductCategoryNames = uniq(
      get(pageData, 'productCategoryList').map((s) => s.name)
    );
    const rowProductCategoryNames = uniq(fileRows.map((r) => r.productCategory));
    const createableProductCategoryNames = difference(
      rowProductCategoryNames,
      existingProductCategoryNames
    );
    const productCategoryPromises = createableProductCategoryNames.map(
      async (createableProductCategoryName) => {
        const uuid = uuidv4();
        const mutationData = {
          variables: { input: { name: createableProductCategoryName } },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'ProductCategoryType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.productCategoryCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'productCategoryCreate',
          mutationData,
        });
        const resp = await productCategoryCreate(mutationData);
        return get(resp, 'data.productCategoryCreate');
      }
    );
    const productCategoryResponses = await Promise.all(productCategoryPromises);
    const productCategories = [
      ...get(pageData, 'productCategoryList'),
      ...productCategoryResponses,
    ];
    // console.log({ productCategories });

    const existingPurchaserNames = uniq(
      get(pageData, 'purchaserList').map((s) => s.name)
    );
    const rowPurchaserNames = uniq(fileRows.map((r) => r.purchaser));
    const createablePurchaserNames = difference(
      rowPurchaserNames,
      existingPurchaserNames
    );
    const purchaserPromises = createablePurchaserNames.map(
      async (createablePurchaserName) => {
        const uuid = uuidv4();
        const mutationData = {
          variables: { input: { name: createablePurchaserName } },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'PurchaserType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.purchaserCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'purchaserCreate',
          mutationData,
        });
        const resp = await purchaserCreate(mutationData);
        return get(resp, 'data.purchaserCreate');
      }
    );
    const purchaserResponses = await Promise.all(purchaserPromises);
    const purchasers = [...get(pageData, 'purchaserList'), ...purchaserResponses];
    // console.log({ purchasers });

    const existingManufacturerNames = uniq(
      get(pageData, 'manufacturerList').map((s) => s.name)
    );
    const rowManufacturerNames = uniq(fileRows.map((r) => r.manufacturer));
    const createableManufacturerNames = difference(
      rowManufacturerNames,
      existingManufacturerNames
    );
    const manufacturerPromises = createableManufacturerNames.map(
      async (createableManufacturerName) => {
        const uuid = uuidv4();
        const mutationData = {
          variables: { input: { name: createableManufacturerName } },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'ManufacturerType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.manufacturerCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'manufacturerCreate',
          mutationData,
        });
        const resp = await manufacturerCreate(mutationData);
        return get(resp, 'data.manufacturerCreate');
      }
    );
    const manufacturerResponses = await Promise.all(manufacturerPromises);
    const manufacturers = [
      ...get(pageData, 'manufacturerList'),
      ...manufacturerResponses,
    ];
    // console.log({ manufacturers });

    const existingSupplierNames = uniq(get(pageData, 'supplierList').map((s) => s.name));
    const rowSupplierNames = uniq(fileRows.map((r) => r.supplier));
    const createableSupplierNames = difference(rowSupplierNames, existingSupplierNames);
    const supplierPromises = createableSupplierNames.map(
      async (createableSupplierName) => {
        const uuid = uuidv4();
        const mutationData = {
          variables: { input: { name: createableSupplierName } },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'SupplierType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.supplierCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'supplierCreate',
          mutationData,
        });
        const resp = await supplierCreate(mutationData);
        return get(resp, 'data.supplierCreate');
      }
    );
    const supplierResponses = await Promise.all(supplierPromises);
    const suppliers = [...get(pageData, 'supplierList'), ...supplierResponses];
    // console.log({ suppliers });

    const existingSupplierCatalogNames = uniq(
      get(pageData, 'supplierCatalogList').map((s) => s.name)
    );
    const rowSupplierCatalogNames = uniq(fileRows.map((r) => r.supplierCatalog));
    const createableSupplierCatalogNames = difference(
      rowSupplierCatalogNames,
      existingSupplierCatalogNames
    );
    const supplierCatalogPromises = createableSupplierCatalogNames.map(
      async (createableSupplierCatalogName) => {
        const [supplierName] = createableSupplierCatalogName.split(' / ');
        const supplier = suppliers.find((s) => s.name === supplierName);
        const supplierId = supplier.id;
        const uuid = uuidv4();
        const mutationData = {
          variables: { input: { name: createableSupplierCatalogName, supplierId } },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'SupplierCatalogType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.supplierCatalogCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'supplierCatalogCreate',
          mutationData,
        });
        const resp = await supplierCatalogCreate(mutationData);
        return get(resp, 'data.supplierCatalogCreate');
      }
    );
    const supplierCatalogResponses = await Promise.all(supplierCatalogPromises);
    const supplierCatalogs = [
      ...get(pageData, 'supplierCatalogList'),
      ...supplierCatalogResponses,
    ];
    // console.log({ supplierCatalogs });

    const supplierCatalogIds = supplierCatalogs.map((sc) => sc.id);
    // console.log({ supplierCatalogIds });

    const existingSupplierCatalogItemImportHashes = uniq(
      get(pageData, 'supplierCatalogItemList')
        .filter(
          (sci) => sci.importHash && supplierCatalogIds.includes(sci.supplierCatalogId)
        )
        .map((sci) => sci.importHash)
    );
    const rowSupplierCatalogItemImportHashes = uniq(fileRows.map((r) => r.importHash));
    const createableSupplierCatalogItemImportHashes = difference(
      rowSupplierCatalogItemImportHashes,
      existingSupplierCatalogItemImportHashes
    );
    const supplierCatalogItemPromises = createableSupplierCatalogItemImportHashes.map(
      async (createableSupplierCatalogItemImportHash) => {
        const sourceRow = fileRows.find(
          (r) => r.importHash === createableSupplierCatalogItemImportHash
        );
        const uuid = uuidv4();
        const input = pick(sourceRow, [
          'description',
          'modelPartNumber',
          'catalogItemUnitOfMeasure',
          'catalogItemUnitPrice',
          'catalogItemCurrency',
          'catalogItemImportedMergedPrice',
          'importHash',
        ]);
        input.catalogItemQuantityPerUnitOfMeasure = 1;
        input.catalogItemPricePerUnitOfMeasure = input.catalogItemUnitPrice;
        const supplierCatalogId = supplierCatalogs.find(
          (sc) => sc.name === sourceRow.supplierCatalog
        ).id;
        input.supplierCatalogId = supplierCatalogId;
        const manufacturerId = manufacturers.find(
          (sc) => sc.name === sourceRow.manufacturer
        ).id;
        input.manufacturerId = manufacturerId;

        const mutationData = {
          variables: { input },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'SupplierCatalogItemType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.supplierCatalogItemCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'supplierCatalogItemCreate',
          mutationData,
        });
        const resp = await supplierCatalogItemCreate(mutationData);
        return get(resp, 'data.supplierCatalogItemCreate');
      }
    );
    const supplierCatalogItemResponses = await Promise.all(supplierCatalogItemPromises);
    const supplierCatalogItems = [
      ...get(pageData, 'supplierCatalogItemList'),
      ...supplierCatalogItemResponses,
    ];
    // console.log({ supplierCatalogItems });
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">
                {params.id ? 'Edit Bom Import' : 'New Bom Import'}
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      {fileError && (
        <Row>
          <Col>
            <Alert variant="danger">{fileError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FinalForm
            onSubmit={(data) => onFormSubmit(data)}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      type="file"
                      name="file"
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                      component={InputField}
                      customOnChange={handleFileChange}
                    />
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      {projectCode && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>{`Project Code: ${projectCode}`}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {fileRows.length > 0 && (
        <Row>
          <Col>
            <Tabs defaultActiveKey="rows" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="rows" title="Rows">
                <pre>{JSON.stringify(fileRows, undefined, 2)}</pre>
              </Tab>
              <Tab eventKey="errors" title="Errors">
                <pre>{JSON.stringify(fileErrors, undefined, 2)}</pre>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default BomImportForm;
