import { useMemo, useState, useCallback } from 'react';
import { Col, Row, Card, Button, ButtonGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, useLazyQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import get from 'lodash.get';

import ManageModal from './site_map_show/manage_modal';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import {
  // tableListPageQuery,
  tableManageListPageQuery,
  tableManageQuery,
  // tableDelete as tableDeleteMutation,
} from '../graphql/table_queries';
import { settingsReset } from '../store/settings_slice';
import * as updateFunctions from '../update_functions';

const ManageTableList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  // const [tableDelete] = useMutation(tableDeleteMutation);
  const [showManageModal, setShowManageModal] = useState(false);
  const [currentTableId, setCurrentTableId] = useState('');
  const [currentTable, setCurrentTable] = useState({});
  const [tableById] = useLazyQuery(tableManageQuery);

  const tableStateKey = 'manageTable';

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(tableManageListPageQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ tableList }) => {
      // write an individual query for every table in the list
      updateFunctions.tableList(pageClient, tableList);
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const handleManageModalCancel = () => {
    setShowManageModal(false);
    setCurrentTableId('');
    setCurrentTable({});
  };

  const handleManageModalComplete = (data) => {
    setShowManageModal(false);
    setCurrentTableId('');
    setCurrentTable({});
    // onFormSubmit({
    //   id: lastScanned.id,
    //   receiptNotes: data.receiptNotes,
    //   auditNotes: makeAuditNote('Delivery notes updated'),
    // });
    console.log('data');
    console.log(data);
    return true;
  };

  const tableManageClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const tableId = parseInt(dataId, 10);
    setCurrentTableId(tableId);
    let newCurrentTable = {};
    if (tableId) {
      const currentTableResp = await tableById({
        variables: {
          tableId,
        },
        fetchPolicy: 'no-cache',
      });
      newCurrentTable = get(currentTableResp, 'data.table');
      if (newCurrentTable?.id) {
        setCurrentTable(newCurrentTable);
      }
      setShowManageModal(true);
    }
  };

  const renderManageTable = ({ row }) => {
    const tableId = get(row, ['original', 'id']);
    return (
      <Button data-id={tableId} variant="primary" onClick={tableManageClicked}>
        Manage
      </Button>
    );
  };

  const getInverterName = (row) => {
    const { inverter } = row;
    const { name } = inverter || {};
    return name || '-';
  };

  const getPileCount = (row) => {
    const { piles } = row;
    return piles?.length || 0;
  };

  const parentColumns = [
    {
      header: 'Name',
      accessorKey: 'name',
      enableColumnFilter: true,
      filterFn: 'includesString',
      sortingFn: 'alphanumeric',
    },
    {
      header: 'Inverter',
      id: 'inverterName',
      accessorFn: (row) => getInverterName(row),
      enableColumnFilter: true,
      filterType: 'dropdown',
    },
    {
      header: 'Status',
      accessorKey: 'buildStatus',
      enableColumnFilter: true,
      filterType: 'dropdown',
    },
    {
      header: 'Table Type',
      accessorKey: 'tableType',
      enableColumnFilter: true,
      filterType: 'dropdown',
    },
    {
      header: 'Pile Count',
      id: 'pileCount',
      accessorFn: (row) => getPileCount(row),
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
    },
    {
      header: 'Manage Table',
      accessorKey: 'id',
      enableColumnFilter: false,
      cell: renderManageTable,
    },
  ];

  const renderContent = () => (
    <>
      <ManageModal
        show={showManageModal}
        onCancel={handleManageModalCancel}
        onComplete={handleManageModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
        table={currentTable}
        tableBuildStatuses={get(pageData, 'enums.enums.TableBuildStatuses', [])}
        hammeringStatuses={get(pageData, 'enums.enums.HammeringStatuses', [])}
      />
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">Manage Tables</h1>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button className="me-2" variant="primary" onClick={tableResetClicked}>
                Reset Table
              </Button>
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh Data
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactTable
            rootName={tableStateKey}
            parentColumns={parentColumns}
            data={pageData.tableList}
            doShow={false}
            doEdit={false}
            doDelete={false}
            hideActions
            hideResetTable
          />
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ManageTableList;
