import { supplierQuery, supplierListQuery } from '../graphql/supplier_queries';
import cleanCache from './clean_cache';

export const supplierList = (client, listData) => {
  listData.map((supplier) => {
    const data = { supplier };
    return client.writeQuery({
      query: supplierQuery,
      variables: { id: supplier.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const supplierCreate = (cache, { data: { supplierCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: supplierListQuery });
  } catch {
    cacheData = { supplierList: [] };
  }
  if (!cacheData) {
    cacheData = { supplierList: [] };
  }
  cache.writeQuery({
    query: supplierListQuery,
    data: { supplierList: [...cacheData.supplierList, createData] },
  });
  const data = { supplier: createData };
  cache.writeQuery({
    query: supplierQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const supplierUpdate = (cache, { data: { supplierUpdate: updateData } }) => {
  const data = { supplier: updateData };
  cache.writeQuery({
    query: supplierQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const supplierDelete = (cache, { data: { supplierDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: supplierListQuery });
  } catch {
    cacheData = { supplierList: [] };
  }
  if (!cacheData) {
    cacheData = { supplierList: [] };
  }
  const data = {
    supplierList: cacheData.supplierList.filter(
      (supplier) => deleteData.id !== supplier.id
    ),
  };
  cache.writeQuery({ query: supplierListQuery, data });
  cache.evict({ fieldName: 'supplier', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
