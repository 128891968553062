export const picklistIntegers = [];
export const picklistFloats = [];
export const picklistIsoStrings = [];
export const picklistWhiteList = [
  'id',
  'picklistTemplateId',
  'fromSiteLocationId',
  'toSiteLocationId',
  'picklistNotes',
  'picklistProducts', // FieldArray
  'picklistSupplierCatalogItems', // FieldArray
];
export const picklistOptimisticList = [
  ...picklistWhiteList,
  // 'picklistProducts',
  'picklistTemplate',
  'fromSiteLocation',
  'toSiteLocation',
  // 'picklistSupplierCatalogItems',
];
