import { wbsCodeQuery, wbsCodeListQuery } from '../graphql/wbs_code_queries';
import cleanCache from './clean_cache';

export const wbsCodeList = (client, listData) => {
  listData.map((wbsCode) => {
    const data = { wbsCode };
    return client.writeQuery({
      query: wbsCodeQuery,
      variables: { id: wbsCode.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const wbsCodeCreate = (cache, { data: { wbsCodeCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: wbsCodeListQuery });
  } catch {
    cacheData = { wbsCodeList: [] };
  }

  cache.writeQuery({
    query: wbsCodeListQuery,
    data: { wbsCodeList: [...cacheData.wbsCodeList, createData] },
  });

  const data = { wbsCode: createData };
  cache.writeQuery({
    query: wbsCodeQuery,
    variables: { id: createData.id.toString() },
    data,
  });

  cleanCache(cache);
};

export const wbsCodeUpdate = (cache, { data: { wbsCodeUpdate: updateData } }) => {
  const data = { wbsCode: updateData };
  cache.writeQuery({
    query: wbsCodeQuery,
    variables: { id: updateData.id.toString() },
    data,
  });

  cleanCache(cache);
};

export const wbsCodeDelete = (cache, { data: { wbsCodeDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: wbsCodeListQuery });
  } catch {
    cacheData = { wbsCodeList: [] };
  }

  const data = {
    wbsCodeList: cacheData.wbsCodeList.filter((wbsCode) => deleteData.id !== wbsCode.id),
  };

  cache.writeQuery({ query: wbsCodeListQuery, data });
  cache.evict({ fieldName: 'wbsCode', args: { id: deleteData.id.toString() } });

  cleanCache(cache);
};
