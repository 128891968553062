import { useMemo, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Tabs,
  Tab,
  ListGroup,
  Table,
} from 'react-bootstrap';
import { kebabCase, sentenceCase } from 'change-case';

import clone from 'lodash.clone';

function ManageModal(props) {
  const { show, onCancel, onComplete, table, tableBuildStatuses } = props;
  const [activeTab, setActiveTab] = useState('status');

  const tableBuildStatusesArray = useMemo(
    () => Object.keys(tableBuildStatuses),
    [tableBuildStatuses]
  );

  const tableBuildStatusIndex = useMemo(
    () => tableBuildStatusesArray.indexOf(table.buildStatus),
    [tableBuildStatusesArray, table]
  );

  const sortedPiles = useMemo(() => {
    if (table?.piles) {
      const pileList = clone(table.piles);
      return pileList.sort((a, b) =>
        a.name.localeCompare(b.name, 'en', { numeric: true })
      );
    }
    return [];
  }, [table]);

  useEffect(() => {
    setActiveTab('status');
  }, [show]);

  // const onFormSubmit = async (data) => {
  //   onComplete(data);
  // };

  const renderStatus = (status, mapIndex) => {
    const key = kebabCase(status);
    const sentenceStatus = sentenceCase(status).replace('itp', 'ITP');
    if (mapIndex < tableBuildStatusIndex) {
      return (
        <ListGroup.Item key={key} variant="success">
          {sentenceStatus}
        </ListGroup.Item>
      );
    }
    if (mapIndex === tableBuildStatusIndex) {
      return (
        <ListGroup.Item key={key} active variant="primary">
          {sentenceStatus}
        </ListGroup.Item>
      );
    }
    return (
      <ListGroup.Item key={key} variant="light">
        {sentenceStatus}
      </ListGroup.Item>
    );
  };

  return (
    <Modal
      id="manage-table"
      show={show}
      onHide={onCancel}
      centered
      size="xl"
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Manage Table ${table.name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: 'calc(100vh - 200px)', // header and footer
        }}
      >
        <div style={{ height: '100%', overflowY: 'scroll' }}>
          <Tabs id="reports" fill activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Tab eventKey="status" title="Status">
              <Row className="mt-4">
                <Col>
                  <ListGroup>
                    {tableBuildStatusesArray.map((status, index) =>
                      renderStatus(status, index)
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="piling" title="Piles">
              <Row className="mt-4">
                <Col>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Pile Name</th>
                        <th>Hammering Status</th>
                        <th>Finished On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedPiles.map((pile) => {
                        const { name, hammeringStatus, finishedOn } = pile;
                        return (
                          <tr key={kebabCase(name)}>
                            <td>{name}</td>
                            <td>{sentenceCase(hammeringStatus)}</td>
                            <td>{finishedOn}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="framing" title="Framing">
              <p>Framing</p>
            </Tab>
            <Tab eventKey="modules" title="Modules">
              <p>Modules</p>
            </Tab>
            <Tab eventKey="data" title="Table Data">
              <pre>{JSON.stringify(table, undefined, 2)}</pre>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="d-flex justify-content-end w-100">
          <ButtonGroup className="me-2">
            <Button variant="danger" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="button" variant="primary" onClick={onComplete}>
              {/* {submitting && <SubmitButtonSpinner />} */}
              Complete
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
}

export default ManageModal;
