import {
  picklistTemplateProductQuery,
  picklistTemplateProductListQuery,
} from '../graphql/picklist_template_product_queries';
import cleanCache from './clean_cache';

export const picklistTemplateProductList = (client, listData) => {
  listData.map((picklistTemplateProduct) => {
    const data = { picklistTemplateProduct };
    return client.writeQuery({
      query: picklistTemplateProductQuery,
      variables: { id: picklistTemplateProduct.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const picklistTemplateProductCreate = (
  cache,
  { data: { picklistTemplateProductCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistTemplateProductListQuery });
  } catch {
    cacheData = { picklistTemplateProductList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistTemplateProductList: [] };
  }
  cache.writeQuery({
    query: picklistTemplateProductListQuery,
    data: {
      picklistTemplateProductList: [...cacheData.picklistTemplateProductList, createData],
    },
  });
  const data = { picklistTemplateProduct: createData };
  cache.writeQuery({
    query: picklistTemplateProductQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistTemplateProductUpdate = (
  cache,
  { data: { picklistTemplateProductUpdate: updateData } }
) => {
  const data = { picklistTemplateProduct: updateData };
  cache.writeQuery({
    query: picklistTemplateProductQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistTemplateProductDelete = (
  cache,
  { data: { picklistTemplateProductDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistTemplateProductListQuery });
  } catch {
    cacheData = { picklistTemplateProductList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistTemplateProductList: [] };
  }
  const data = {
    picklistTemplateProductList: cacheData.picklistTemplateProductList.filter(
      (picklistTemplateProduct) => deleteData.id !== picklistTemplateProduct.id
    ),
  };
  cache.writeQuery({ query: picklistTemplateProductListQuery, data });
  cache.evict({
    fieldName: 'picklistTemplateProduct',
    args: { id: deleteData.id.toString() },
  });
  cleanCache(cache);
};
