import { toast } from 'react-toastify';

const toastArgs = () => {
  const args = {
    position: 'bottom-left',
    autoClose: 6000,
    hideProgressBar: true,
    pauseOnFocusLoss: false,
  };
  return args;
};

export const toastSuccessDev = (message) => {
  if (window.$NODE_ENV === 'development') {
    toast.success(message, toastArgs());
  }
};

export const toastSuccess = (message) => {
  toast.success(message, toastArgs());
};

export const toastWarning = (message) => {
  toast.warning(message, toastArgs());
};

export const toastError = (message) => {
  toast.error(message, toastArgs());
};
