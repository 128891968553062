export const supplierCatalogItemFormSchema = {
  type: 'object',
  required: [
    'supplierCatalogId',
    'manufacturerId',
    // 'name',
    'modelPartNumber',
    'description',
    'catalogItemUnitOfMeasure',
    'catalogItemQuantityPerUnitOfMeasure',
    'catalogItemCurrency',
    'catalogItemUnitPrice',
    'catalogItemPricePerUnitOfMeasure',
    // 'importHash',
  ],
  properties: {
    // name: { type: 'string', minLength: 6, maxLength: 255 },
    modelPartNumber: { type: 'string', minLength: 6, maxLength: 255 },
    description: { type: 'string', minLength: 6, maxLength: 255 },
    catalogItemQuantityPerUnitOfMeasure: { type: 'number', exclusiveMinimum: 0 },
    // catalogItemUnitPrice: { type: 'number' },
    // catalogItemPricePerUnitOfMeasure: { type: 'number' },
    // catalogItemImportedMergedPrice: { type: 'number' },
    // catalogItemUnitOfMeasure // ENUM
    // catalogItemCurrency // ENUM
  },
};
