import {
  picklistSupplierCatalogItemQuery,
  picklistSupplierCatalogItemListQuery,
} from '../graphql/picklist_supplier_catalog_item_queries';
import cleanCache from './clean_cache';

export const picklistSupplierCatalogItemList = (client, listData) => {
  listData.map((picklistSupplierCatalogItem) => {
    const data = { picklistSupplierCatalogItem };
    return client.writeQuery({
      query: picklistSupplierCatalogItemQuery,
      variables: { id: picklistSupplierCatalogItem.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const picklistSupplierCatalogItemCreate = (
  cache,
  { data: { picklistSupplierCatalogItemCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistSupplierCatalogItemListQuery });
  } catch {
    cacheData = { picklistSupplierCatalogItemList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistSupplierCatalogItemList: [] };
  }
  cache.writeQuery({
    query: picklistSupplierCatalogItemListQuery,
    data: {
      picklistSupplierCatalogItemList: [
        ...cacheData.picklistSupplierCatalogItemList,
        createData,
      ],
    },
  });
  const data = { picklistSupplierCatalogItem: createData };
  cache.writeQuery({
    query: picklistSupplierCatalogItemQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistSupplierCatalogItemUpdate = (
  cache,
  { data: { picklistSupplierCatalogItemUpdate: updateData } }
) => {
  const data = { picklistSupplierCatalogItem: updateData };
  cache.writeQuery({
    query: picklistSupplierCatalogItemQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistSupplierCatalogItemDelete = (
  cache,
  { data: { picklistSupplierCatalogItemDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistSupplierCatalogItemListQuery });
  } catch {
    cacheData = { picklistSupplierCatalogItemList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistSupplierCatalogItemList: [] };
  }
  const data = {
    picklistSupplierCatalogItemList: cacheData.picklistSupplierCatalogItemList.filter(
      (picklistSupplierCatalogItem) => deleteData.id !== picklistSupplierCatalogItem.id
    ),
  };
  cache.writeQuery({ query: picklistSupplierCatalogItemListQuery, data });
  cache.evict({
    fieldName: 'picklistSupplierCatalogItem',
    args: { id: deleteData.id.toString() },
  });
  cleanCache(cache);
};
