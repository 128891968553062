import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import get from 'lodash.get';

import {
  renderOverlay,
  renderOffline,
  renderError,
  renderDateString,
} from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import {
  receiptListPageQuery,
  receiptDelete as receiptDeleteMutation,
} from '../graphql/receipt_queries';
import { settingsSet } from '../store/settings_slice';
import * as updateFunctions from '../update_functions';

const ReceiptList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [receiptDelete] = useMutation(receiptDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(receiptListPageQuery, {
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ receiptList }) => {
      // write an individual query for every receipt in the list
      updateFunctions.receiptList(pageClient, receiptList);
    },
  });
  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading && pageNetworkStatus === NetworkStatus.refetch) ||
      (pageLoading && pageNetworkStatus === NetworkStatus.poll),
    [pageLoading, pageNetworkStatus]
  );

  const receiptDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const receiptId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: receiptId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ReceiptType',
        recordId: receiptId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.receiptDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'receiptDelete',
      mutationData
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await receiptDelete(mutationData);
        toastSuccess('Receipt delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Receipt delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      receiptDelete(mutationData);
      toastWarning(`Receipt delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const getConsignmentName = (row) => {
    const { consignmentItem } = row;
    const { consignment } = consignmentItem || {};
    const { name: consignmentName } = consignment || {};
    return consignmentName || '-';
  };

  const getSiteLocationName = (row) => {
    const { siteLocation } = row;
    const { name } = siteLocation || {};
    return name || '-';
  };

  const renderManualCell = (row) => {
    const { consignmentItemId } = row;
    return consignmentItemId ? 'No' : 'Yes';
  };

  const sortIsoString = (rowA, rowB, columnId) => {
    const isoStringA = get(rowA, ['original', columnId]);
    const isoStringB = get(rowB, ['original', columnId]);
    // eslint-disable-next-line no-nested-ternary
    return isoStringA < isoStringB ? -1 : isoStringA > isoStringB ? 1 : 0;
  };

  const parentColumns = [
    {
      header: 'Man',
      accessorKey: 'consignmentItemId',
      accessorFn: renderManualCell,
      enableColumnFilter: true,
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Reference',
      accessorKey: 'name',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Consignment',
      id: 'consignment',
      accessorFn: getConsignmentName,
      enableColumnFilter: true,
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Site Location',
      id: 'siteLocation',
      accessorFn: getSiteLocationName,
      filterType: 'dropdown',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Delivery State',
      accessorKey: 'deliveryState',
      enableColumnFilter: true,
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Delivery Status',
      accessorKey: 'deliveryStatus',
      enableColumnFilter: true,
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'First Scanned',
      accessorKey: 'createdAt',
      enableColumnFilter: false,
      accessorFn: ({ createdAt }) => renderDateString(createdAt),
      sortingFn: sortIsoString,
    },
    {
      header: 'Last Seen',
      accessorKey: 'updatedAt',
      enableColumnFilter: false,
      accessorFn: ({ updatedAt }) => renderDateString(updatedAt),
      sortingFn: sortIsoString,
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">Receipts</h1>
            </div>
            <div className="float-end">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
              <LinkContainer to="/receipts/new">
                <Button variant="primary">New Receipt</Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName="receipt"
                parentColumns={parentColumns}
                data={pageData.receiptList}
                doDelete={currentUser?.perms?.administrator}
                handleDelete={receiptDeleteClicked}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ReceiptList;
