import {
  picklistTemplateQuery,
  picklistTemplateListQuery,
} from '../graphql/picklist_template_queries';
import cleanCache from './clean_cache';

export const picklistTemplateList = (client, listData) => {
  listData.map((picklistTemplate) => {
    const data = { picklistTemplate };
    return client.writeQuery({
      query: picklistTemplateQuery,
      variables: { id: picklistTemplate.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const picklistTemplateCreate = (
  cache,
  { data: { picklistTemplateCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistTemplateListQuery });
  } catch {
    cacheData = { picklistTemplateList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistTemplateList: [] };
  }
  cache.writeQuery({
    query: picklistTemplateListQuery,
    data: { picklistTemplateList: [...cacheData.picklistTemplateList, createData] },
  });
  const data = { picklistTemplate: createData };
  cache.writeQuery({
    query: picklistTemplateQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistTemplateUpdate = (
  cache,
  { data: { picklistTemplateUpdate: updateData } }
) => {
  const data = { picklistTemplate: updateData };
  cache.writeQuery({
    query: picklistTemplateQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const picklistTemplateDelete = (
  cache,
  { data: { picklistTemplateDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: picklistTemplateListQuery });
  } catch {
    cacheData = { picklistTemplateList: [] };
  }
  if (!cacheData) {
    cacheData = { picklistTemplateList: [] };
  }
  const data = {
    picklistTemplateList: cacheData.picklistTemplateList.filter(
      (picklistTemplate) => deleteData.id !== picklistTemplate.id
    ),
  };
  cache.writeQuery({ query: picklistTemplateListQuery, data });
  cache.evict({ fieldName: 'picklistTemplate', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
