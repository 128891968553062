import { consignmentListQuery, consignmentQuery } from '../graphql/consignment_queries';
import cleanCache from './clean_cache';

export const consignmentList = (client, listData) => {
  listData.map((consignment) => {
    const data = { consignment };
    return client.writeQuery({
      query: consignmentQuery,
      variables: { id: consignment.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const consignmentCreate = (cache, { data: { consignmentCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: consignmentListQuery });
  } catch {
    cacheData = { consignmentList: [] };
  }
  cache.writeQuery({
    query: consignmentListQuery,
    data: { consignmentList: [...cacheData.consignmentList, createData] },
  });
  const data = { consignment: createData };
  cache.writeQuery({
    query: consignmentQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const consignmentUpdate = (cache, { data: { consignmentUpdate: updateData } }) => {
  const data = { consignment: updateData };
  cache.writeQuery({
    query: consignmentQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const consignmentDelete = (cache, { data: { consignmentDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: consignmentListQuery });
  } catch {
    cacheData = { consignmentList: [] };
  }
  const data = {
    consignmentList: cacheData.consignmentList.filter(
      (consignment) => deleteData.id !== consignment.id
    ),
  };
  cache.writeQuery({ query: consignmentListQuery, data });
  cache.evict({ fieldName: 'consignment', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
