import { useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  manufacturerFormPageQuery,
  manufacturerCreate as manufacturerCreateMutation,
  manufacturerUpdate as manufacturerUpdateMutation,
} from '../graphql/manufacturer_queries';
import * as updateFunctions from '../update_functions';
import { manufacturerDefaultValues } from '../defaults';
import { manufacturerFormValidator } from '../validators';
import { manufacturerWhiteList } from '../white_lists';

const ManufacturerForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [manufacturerCreate] = useMutation(manufacturerCreateMutation);
  const [manufacturerUpdate] = useMutation(manufacturerUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(manufacturerFormPageQuery, {
    variables: {
      hasManufacturerId: !!params.id,
      manufacturerId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.manufacturer) {
      const values = pickValues(pageData.manufacturer, manufacturerWhiteList);
      return values;
    }
    return manufacturerDefaultValues;
  }, [pageData]);

  const onCancel = () => {
    navigate('/manufacturers');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ManufacturerType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = manufacturerUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.manufacturerUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'manufacturerUpdate',
        mutationData,
        currentData: pageData.manufacturer,
      });
    } else {
      mutation = manufacturerCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.manufacturerCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'manufacturerCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Manufacturer ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/manufacturers');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Manufacturer ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/manufacturers');
    }
    return undefined;
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">
                {params.id ? 'Edit Manufacturer' : 'New Manufacturer'}
              </h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/manufacturers">
                <Button className="me-2" variant="primary">
                  All Manufacturers
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={manufacturerFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      name="name"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Manufacturer Name
                    </Field>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ManufacturerForm;
