import { useMemo } from 'react';
import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';

import Field from '../../components/form/rff_field';
import OnChangeField from '../../components/form/rff_on_change_field';
import InputField from '../../components/form/input_field';
import SubmitButtonSpinner from '../../components/submit_button_spinner';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import {
  supplierCatalogItemCreate as supplierCatalogItemCreateMutation,
  supplierCatalogItemUpdate as supplierCatalogItemUpdateMutation,
} from '../../graphql/supplier_catalog_item_queries';
import * as updateFunctions from '../../update_functions';
import { coerceInput, pickValues, handleSubmitError } from '../../lib/utils';
import { supplierCatalogItemDefaultValues } from '../../defaults';
import { supplierCatalogItemFormValidator } from '../../validators';
import { supplierCatalogItemWhiteList } from '../../white_lists';

function SupplierCatalogItemFormModal(props) {
  const dispatch = useDispatch();
  const [supplierCatalogItemCreate] = useMutation(supplierCatalogItemCreateMutation);
  const [supplierCatalogItemUpdate] = useMutation(supplierCatalogItemUpdateMutation);
  const {
    settingsTenant,
    settingsOnline,
    show,
    supplierName,
    supplierCatalogId,
    supplierCatalogName,
    supplierCatalogItem,
    enums,
    manufacturers,
    onCancel,
    onComplete,
  } = props;

  const initialValues = useMemo(() => {
    if (supplierCatalogItem?.id) {
      const values = pickValues(supplierCatalogItem, supplierCatalogItemWhiteList);
      return { ...values, supplierCatalogId };
    }
    const initialManufacturer = manufacturers.find((m) => m.name === supplierName);
    if (initialManufacturer) {
      return {
        ...supplierCatalogItemDefaultValues,
        supplierCatalogId,
        manufacturerId: initialManufacturer.id,
      };
    }
    return { ...supplierCatalogItemDefaultValues, supplierCatalogId };
  }, [supplierCatalogItem, supplierCatalogId, manufacturers, supplierName]);

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'SupplierCatalogItemType',
        recordId: supplierCatalogItem?.id ? supplierCatalogItem.id : uuid,
      },
    };
    if (supplierCatalogItem?.id) {
      mutation = supplierCatalogItemUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = supplierCatalogItem.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.supplierCatalogItemUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'supplierCatalogItemUpdate',
        mutationData,
        currentData: supplierCatalogItem,
      });
    } else {
      mutation = supplierCatalogItemCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.supplierCatalogItemCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'supplierCatalogItemCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Supplier Catalog Item ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onComplete();
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Supplier Catalog Item ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onComplete();
    }
    return undefined;
  };

  const recalculateCatalogItemPricePerUnitOfMeasure = (form, values) => {
    let newCatalogItemPricePerUnitOfMeasure = '0';
    const { catalogItemQuantityPerUnitOfMeasure, catalogItemUnitPrice } = values;
    if (
      parseFloat(catalogItemQuantityPerUnitOfMeasure) &&
      parseFloat(catalogItemUnitPrice)
    ) {
      newCatalogItemPricePerUnitOfMeasure =
        Math.round(
          parseFloat(catalogItemQuantityPerUnitOfMeasure) *
            parseFloat(catalogItemUnitPrice) *
            100
        ) / 100;
    }
    form.change('catalogItemPricePerUnitOfMeasure', newCatalogItemPricePerUnitOfMeasure);
  };

  return (
    <Modal
      id={`supplier-catalog-${supplierCatalogId}-supplier-catalog-item-form`}
      show={show}
      onHide={onCancel}
      centered
      size="xl"
      animation={false}
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        validate={supplierCatalogItemFormValidator}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting, pristine, form, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{`Add Item for ${supplierCatalogName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <fieldset className="border rounded-3 p-3">
                <legend className="float-none w-auto px-3 fs-6">Item details</legend>
                <OnChangeField name="catalogItemQuantityPerUnitOfMeasure">
                  {() => recalculateCatalogItemPricePerUnitOfMeasure(form, values)}
                </OnChangeField>
                <OnChangeField name="catalogItemUnitPrice">
                  {() => recalculateCatalogItemPricePerUnitOfMeasure(form, values)}
                </OnChangeField>
                <Field
                  name="manufacturerId"
                  component={InputField}
                  asElement="select"
                  selectOptions={manufacturers}
                  defaultSelectOptionText="select manufacturer..."
                  labelWidth={4}
                  inputWidth={4}
                  size="lg"
                >
                  Manufacturer
                </Field>
                <Field
                  name="modelPartNumber"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={8}
                  size="lg"
                >
                  Model/Part Number
                </Field>
                <Field
                  name="description"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={8}
                  size="lg"
                >
                  Description
                </Field>
                <Field
                  name="catalogItemUnitOfMeasure"
                  component={InputField}
                  asElement="select"
                  selectOptions={Object.values(
                    get(enums, 'enums.CatalogItemUnitOfMeasures', [])
                  ).map((type) => ({
                    id: type,
                    name: type,
                  }))}
                  defaultSelectOptionText="select unit of measure..."
                  labelWidth={4}
                  inputWidth={4}
                  size="lg"
                >
                  Unit of Measure
                </Field>
                <Field
                  name="catalogItemQuantityPerUnitOfMeasure"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={2}
                  size="lg"
                >
                  Quantity per Unit of Measure
                </Field>
                <Field
                  name="catalogItemCurrency"
                  component={InputField}
                  asElement="select"
                  selectOptions={Object.values(
                    get(enums, 'enums.CatalogItemCurrency', [])
                  ).map((type) => ({
                    id: type,
                    name: type,
                  }))}
                  defaultSelectOptionText="select currency..."
                  labelWidth={4}
                  inputWidth={4}
                  size="lg"
                >
                  Currency
                </Field>
                <Field
                  name="catalogItemUnitPrice"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={2}
                  size="lg"
                  helpText="The smallest item price"
                >
                  Unit Price ($0.00)
                </Field>
                <Field
                  name="catalogItemPricePerUnitOfMeasure"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={2}
                  size="lg"
                  helpText="The price for the box, bundle, bag etc"
                >
                  Price Unit of Measure ($0.00)
                </Field>
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup className="me-2">
                  <Button variant="danger" onClick={onCancel} disabled={submitting}>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    {supplierCatalogItem?.id ? 'Update' : 'Create'}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default SupplierCatalogItemFormModal;
