export const srackSchema = {
  'Shipment Number': {
    prop: 'shipmentNumber',
    type: String,
    required: true,
  },
  'Container Type': {
    prop: 'containerReference',
    type: String,
    required: true,
  },
  'Packing Item Number': {
    prop: 'consignmentItemReference',
    type: String,
    required: true,
  },
  'Type of Packing Item': {
    prop: 'consignmentItemType',
    type: String,
    required: true,
  },
  'Item No.': {
    prop: 'consignmentItemNumber',
    type: String,
    required: true,
  },
  Description: {
    prop: 'description',
    type: String,
    required: true,
  },
  QTY: {
    prop: 'quantity',
    type: Number,
    required: true,
  },
  Weight: {
    prop: 'weightKg',
    type: Number,
    required: true,
  },
  'Destination Zone': {
    prop: 'siteLocationName',
    type: String,
    required: true,
  },
};
