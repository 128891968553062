export const consignmentItemIntegers = ['quantity', 'weightKg'];
export const consignmentItemFloats = [];
export const consignmentItemIsoStrings = [];
export const consignmentItemWhiteList = [
  'id',
  'consignmentId',
  'description',
  'consignmentItemReference',
  'consignmentItemType',
  'quantity',
  'weightKg',
  'deliveryState',
  'deliveryStatus',
  'deliveryNotes',
  'siteLocationId',
  'consignmentItemNumber',
  'importHash',
];
export const consignmentItemOptimisticList = [
  ...consignmentItemWhiteList,
  'consignment',
  'siteLocation',
  'consignmentItemMembers',
  'consignmentItemMemberReferences',
];
