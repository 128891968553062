import { productQuery, productListQuery } from '../graphql/product_queries';
import cleanCache from './clean_cache';

export const productList = (client, listData) => {
  listData.map((product) => {
    const data = { product };
    return client.writeQuery({
      query: productQuery,
      variables: { id: product.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const productCreate = (cache, { data: { productCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: productListQuery });
  } catch {
    cacheData = { productList: [] };
  }
  if (!cacheData) {
    cacheData = { productList: [] };
  }
  cache.writeQuery({
    query: productListQuery,
    data: { productList: [...cacheData.productList, createData] },
  });
  const data = { product: createData };
  cache.writeQuery({
    query: productQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const productUpdate = (cache, { data: { productUpdate: updateData } }) => {
  const data = { product: updateData };
  cache.writeQuery({
    query: productQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const productDelete = (cache, { data: { productDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: productListQuery });
  } catch {
    cacheData = { productList: [] };
  }
  if (!cacheData) {
    cacheData = { productList: [] };
  }
  const data = {
    productList: cacheData.productList.filter((product) => deleteData.id !== product.id),
  };
  cache.writeQuery({ query: productListQuery, data });
  cache.evict({ fieldName: 'product', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
