/* eslint-disable import/prefer-default-export */
import { getMainDefinition } from '@apollo/client/utilities';

import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import omit from 'lodash.omit';

import { apolloClient } from './apollo_client';

import { userQuery, userListQuery } from '../graphql/user_queries';

const getQueries = (recordType) => {
  switch (recordType) {
    case 'UserType':
      return [userQuery, userListQuery];
    default:
      return [undefined, undefined];
  }
};

const processQueries = ({
  recordId,
  recordType,
  mutationType,
  argGetQuery,
  argListQuery,
  listVariables = [{}],
  forceFetch = false,
}) => {
  const [defaultGetQuery, defaultListQuery] = getQueries(recordType);
  const getQuery = argGetQuery || defaultGetQuery;
  const listQuery = argListQuery || defaultListQuery;
  let recordQuery;
  if (getQuery && mutationType !== 'DELETE') {
    recordQuery = apolloClient.query({
      query: getQuery,
      variables: { id: recordId },
      fetchPolicy: 'network-only',
    });
  } else {
    recordQuery = Promise.resolve({});
  }
  recordQuery.then(() => {
    let collectionQuery = Promise.resolve([]);
    if (listQuery) {
      const {
        name: { value: listQueryName },
      } = getMainDefinition(listQuery);
      if (forceFetch || mutationType !== 'UPDATE') {
        const collectionPromises = listVariables.map((listVariable) => {
          const queryVariables = {
            query: listQuery,
            variables: omitBy(listVariable, isNil),
            fetchPolicy: 'network-only',
          };
          let collection;
          try {
            collection = apolloClient.readQuery(omit(queryVariables, ['fetchPolicy']));
          } catch {
            collection = undefined;
          }
          if (collection) {
            if (forceFetch || mutationType === 'CREATE') {
              return apolloClient.query(queryVariables);
            }
            if (mutationType === 'DELETE') {
              const newCollection = [...collection[listQueryName]];
              const index = newCollection.findIndex((record) => record.id === recordId);
              if (index > -1) {
                return apolloClient.query(queryVariables);
              }
              return Promise.resolve([]);
            }
          }
          return Promise.resolve([]);
        });
        collectionQuery = Promise.all(collectionPromises);
      }
    }
    collectionQuery.then(() => {
      if (window.$NODE_ENV === 'development') {
        console.log(`${mutationType} ${recordType} success: `, listVariables);
      }
    });
  });
};

const processMutationSubscription = (mutation) => {
  if (window.$NODE_ENV === 'development') {
    console.log(mutation);
  }
  // const {
  //   data: {
  //     mutation: { recordId, recordType, mutationType },
  //   },
  // } = mutation;
  // processQueries({
  //   recordId,
  //   recordType,
  //   mutationType,
  // });
};

export { processMutationSubscription };
